import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  DialogActions,
  Dialog,
  LinearProgress,
} from "@mui/material";
import { useStyles } from "./AddAdminDialog.styles";
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  Title as TitleIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useForm, validateForm } from "../../../../utils";
import { useMutation } from "@apollo/client";
import {
  CREATE_ADMIN,
  ICreateAdminData,
  ICreateAdminVars,
} from "../../../../apollo/mutations";
import { initialInputData } from "./AddAdminDialog.inputs";
import { ALL_ADMINS, IAdmin, IAdminsData } from "../../../../apollo/queries";

interface IProps {
  onClose: () => void;
  searchQuery: string;
}

export const AddAdminDialog: FC<IProps> = (props) => {
  const { onClose, searchQuery } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const paperProps = {
    style: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  };

  const { inputFields, setInputFields, inputProps } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [createAdmin, { loading: mutationLoading }] = useMutation<
    ICreateAdminData,
    ICreateAdminVars
  >(CREATE_ADMIN, {
    onCompleted: () => {
      enqueueSnackbar("Successfully added a new administrator!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      // console.log("Error: ", { error });
    },
    variables: {
      data: {
        phone: inputFields.phone.value.unlocalised.value,
        email: inputFields.email.value.unlocalised.value,
        name: inputFields.name.value.unlocalised.value,
      },
    },
    update: (cache, { data }) => {
      const existingAdmins: IAdminsData | null = cache.readQuery({
        query: ALL_ADMINS,
        variables: {
          filter: {
            where: {
              OR: searchQuery
                ? [
                    {
                      email: { contains: searchQuery, mode: "insensitive" },
                    },
                    {
                      name: { contains: searchQuery, mode: "insensitive" },
                    },
                    {
                      phone: { contains: searchQuery, mode: "insensitive" },
                    },
                  ]
                : undefined,
              expired: null,
              role: {
                access: {
                  not: 1,
                },
              },
            },
            orderBy: {
              created: "desc",
            },
          },
        },
      });
      if (data?.createAdmin) {
        const newAdmin: IAdmin = {
          ...data.createAdmin,
        };

        cache.writeQuery({
          query: ALL_ADMINS,
          variables: {
            filter: {
              where: {
                OR: searchQuery
                  ? [
                      {
                        email: { contains: searchQuery, mode: "insensitive" },
                      },
                      {
                        name: { contains: searchQuery, mode: "insensitive" },
                      },
                      {
                        phone: { contains: searchQuery, mode: "insensitive" },
                      },
                    ]
                  : undefined,
                expired: null,
                role: {
                  access: {
                    not: 1,
                  },
                },
              },
              orderBy: {
                created: "desc",
              },
            },
          },
          data: {
            persons: existingAdmins?.persons
              ? [newAdmin, ...existingAdmins.persons]
              : [newAdmin],
          },
        });
      }
    },
  });

  const handleNewAdmin = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      createAdmin();
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  return (
    <Fragment>
      <DialogTitle>Add new administrator</DialogTitle>
      <DialogContent>
        <TextField
          className={styles.inputField}
          {...inputProps("email")}
          fullWidth
          autoFocus
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={styles.inputField}
          {...inputProps("name")}
          fullWidth
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={styles.inputField}
          {...inputProps("phone")}
          fullWidth
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />
        <DialogActions>
          <Button variant="text" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleNewAdmin}>
            Add
          </Button>
        </DialogActions>
      </DialogContent>
      <Dialog open={mutationLoading} fullScreen PaperProps={paperProps}>
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
