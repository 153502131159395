import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  img: {
    height: 64,
    width: "100%",
    objectFit: "cover",
    filter: "brightness(75%)",
    borderRadius: 16,
  },
  unpublished: {
    filter: "grayscale(100%)",
  },
  errorDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    borderColor: theme.palette.error.main,
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 16,
  },
}));
