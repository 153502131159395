import { gql } from "@apollo/client";

export interface ICreateMediaVars {
  data: {
    name: string;
    path: string;
    extension: string;
    type: string;
    size: number;
    blob: string;
  };
}

export interface ICreateMedia {
  id: string;
  name: string;
  path: string;
  extension: string;
  type: string;
  size: number;
  __typename?: string;
}

export interface ICreateMediaData {
  createMedia: ICreateMedia;
}

export const CREATE_MEDIA = gql`
  mutation createMedia($data: MediaCreateInput) {
    createMedia(data: $data) {
      id
      path
      name
      extension
      type
      size
    }
  }
`;
