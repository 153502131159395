import { Button, Typography } from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
} from "@mui/icons-material";
import { FC, Fragment } from "react";
import { useStyles } from "./StationButton.styles";

interface IProps {
  stationId?: string;
  title?: string;
  onClickConnect: () => void;
  onClickAdd: () => void;
  onClickEdit?: () => void;
  onClickDisconnect?: () => void;
}

export const StationButton: FC<IProps> = (props) => {
  const {
    onClickConnect,
    onClickAdd,
    stationId,
    title,
    onClickDisconnect,
    onClickEdit,
  } = props;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {stationId ? (
        <Fragment>
          <Typography>{title}</Typography>
          <div className={styles.buttonDiv}>
            <Button onClick={onClickEdit} startIcon={<EditIcon />}>
              Edit
            </Button>
            <Button onClick={onClickDisconnect} startIcon={<LinkOffIcon />}>
              Disconnect
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Typography>{title}</Typography>
          <div className={styles.buttonDiv}>
            <Button onClick={onClickAdd} startIcon={<AddIcon />}>
              Add new
            </Button>
            <Button onClick={onClickConnect} startIcon={<LinkIcon />}>
              Connect
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};
