import { gql } from "@apollo/client";

export interface IUpdateAdminVars {
  id: number;
  data: {
    phone: string | undefined;
    name: string | undefined;
  };
}

export interface IUpdateAdmin {
  id: string;
  name: string;
  phone: string;
}

export interface IUpdateAdminData {
  updateAdmin: IUpdateAdmin;
}

export const UPDATE_ADMIN = gql`
  mutation updateAdmin($id: ID!, $data: PersonUpdateInput) {
    updateAdmin(id: $id, data: $data) {
      id
      phone
      name
      updated
    }
  }
`;
