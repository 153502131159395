import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  rte: {
    flexBasis: "100%",
    width: "100%",
    minHeight: 300,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 32,
    overflow: "hidden",
    // boxShadow: "8px 8px 8px -5px rgba(0,0,0,0.2)",
  },
  slate: {
    padding: "16px 8px",
    minHeight: "300px !important",
  },
  onError: {
    borderColor: theme.palette.error.main,
  },
  inputLabel: {
    position: "absolute",
    top: -2,
    left: 0,
    backgroundColor: "white",
    padding: 2,
    borderRadius: 8,
  },
  wrapper: {
    position: "relative",
    width: "100%",
  },
  focused: {
    borderColor: theme.palette.primary.main,
  },
}));
