import {
  emptySlateStringValue,
  emptySlateValue,
} from "../../components/RichTextEditor";
import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  content: {
    value: {
      unlocalised: { value: emptySlateStringValue, rteValue: emptySlateValue },
    },
    label: "Content",
    required: true,
    localise: true,
    rules: "slateRte",
  },
});
