import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 16,
    padding: 32,
    flex: 1,
  },
  table: {
    "& .MuiTableCell-root:not(:first-child)": {
      borderLeft: "1px solid #333",
    },
    "& .MuiTableCell-head": {
      borderBottom: "1px solid #333",
    },
  },
  buttonGroup: {
    display: "flex",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchBar: {
    width: 256,
  },
  editButton: {
    alignSelf: "flex-start",
  },
}));
