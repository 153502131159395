import { gql } from "@apollo/client";

export interface ICurrentUser {
  id: string;
  name: string;
  phone: string;
  email: string;
  role: {
    id: string;
    name: string;
    access: number;
  };
}

export interface ICurrentUserData {
  currentUser: ICurrentUser;
}

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      name
      phone
      email
      role {
        id
        name
        access
      }
    }
  }
`;
