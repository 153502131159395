import { FC, Fragment, useState } from "react";
import { ErrorComponent, LoadingComponent, PageLayout } from "../../components";
import { Dialog, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import {
  ALL_NOTIFICATIONS,
  INotificationsData,
  INotificationVars,
} from "../../apollo/queries";
import { Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useStyles } from "./NotificationScreen.styles";
import { AddNotificationDialog, NotificationItem } from "./components";
import { useSnackbar } from "notistack";

export const NotificationScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dialog, setDialog] = useState(false);
  const [localeSelected, setLocaleSelected] = useState("");

  const localeSelectProps = {
    selected: localeSelected,
    setSelected: setLocaleSelected,
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleOpenDialog = () => {
    setDialog(true);
  };

  const { loading, error, data } = useQuery<
    INotificationsData,
    INotificationVars
  >(ALL_NOTIFICATIONS, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
        orderBy: {
          created: "desc",
        },
      },
    },
    onError: () => {
      enqueueSnackbar("Failed to fetch data, try again later!", {
        variant: "error",
      });
    },
  });

  return (
    <PageLayout localeSelect={localeSelectProps} title="Notifications">
      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorComponent error={error} />
      ) : (
        <Fragment>
          <Button
            color="primary"
            className={styles.editButton}
            startIcon={<AddIcon />}
            onClick={handleOpenDialog}
          >
            Add new
          </Button>
          {data?.notifications.length ? (
            data.notifications.map((item) => {
              const getFlagId = item.locale.findIndex(
                (x) => x.localeFlag.isoLanguageCode === localeSelected
              );
              if (getFlagId !== -1) {
                return (
                  <NotificationItem
                    key={item.id}
                    title={item.locale[getFlagId].title}
                    body={item.locale[getFlagId].body}
                    created={item.created}
                  />
                );
              } else return <Typography>Translation error</Typography>;
            })
          ) : (
            <Typography variant="h4" component="p">
              No data found
            </Typography>
          )}
          <Dialog
            open={dialog}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
          >
            <AddNotificationDialog onClose={handleCloseDialog} />
          </Dialog>
        </Fragment>
      )}
    </PageLayout>
  );
};
