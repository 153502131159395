import { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { RouteComponentProps, StaticContext } from "react-router";
import { isLoggedIn } from "../utils";
import { Dashboard } from "../components";

import {
  AdminsScreen,
  CulturalHeritageDetailsScreen,
  CulturalHeritageScreen,
  LoginScreen,
  TermsAndConditionsScreen,
  PrivacyPolicyScreen,
  NotificationScreen,
  AboutScreen,
  StationScreen,
  StationDetailsScreen,
  PrivacyPolicyPublicScreen,
} from ".";

export const PrivateRoute = ({
  component: Component,
  path,
  exact,
  ...rest
}: {
  component: any;
  path: string;
  exact?: boolean;
}) => {
  const renderPage = (
    props: RouteComponentProps<
      {
        [x: string]: string | undefined;
      },
      StaticContext,
      unknown
    >
  ) => {
    return isLoggedIn() ? (
      <Fragment>
        <Dashboard />
        <Component {...props} />
      </Fragment>
    ) : (
      <Redirect to="/login" />
    );
  };

  return <Route exact={exact} {...rest} render={renderPage} />;
};

export const PublicRoute = ({
  component: Component,
  restricted,
  path,
  exact,
  ...rest
}: {
  component: any;
  restricted: boolean;
  path: string;
  exact: boolean;
}) => {
  const renderPage = (
    props: RouteComponentProps<
      {
        [x: string]: string | undefined;
      },
      StaticContext,
      unknown
    >
  ) => {
    return isLoggedIn() && restricted ? (
      <Redirect to="/" />
    ) : (
      <Component {...props} />
    );
  };

  return <Route {...rest} render={renderPage} />;
};

export const MainRouter = () => {
  // const location = useLocation();
  // const transitions = useTransition(location, {
  //   from: { opacity: 0, position: "absolute" },
  //   enter: { opacity: 1, position: "relative" },
  //   leave: { opacity: 0, position: "absolute" },
  //   keys: (item) => item.pathname || 0,
  // });

  // const {data, error, loading} = useQuery(CURRENT_USER)

  // const getTransitions = () => (
  // transitions((props, item) => (
  //   <animated.div style={props as any}>

  // );
  // </animated.div>
  // ));

  return (
    <Switch>
      <PublicRoute
        restricted={false}
        component={PrivacyPolicyPublicScreen}
        path="/privacy-policy-public"
        exact
      />
      <PublicRoute
        restricted={true}
        component={LoginScreen}
        path="/login"
        exact
      />
      <Redirect from="/" to="/cultural-heritage" exact />
      <Route path="/cultural-heritage">
        <Switch>
          <PrivateRoute
            component={CulturalHeritageScreen}
            path="/cultural-heritage"
            exact
          />
          <PrivateRoute
            component={CulturalHeritageDetailsScreen}
            path="/cultural-heritage/new"
          />
          <PrivateRoute
            component={CulturalHeritageDetailsScreen}
            path="/cultural-heritage/:culturalHeritageId"
          />
        </Switch>
      </Route>
      <Route path="/station">
        <Switch>
          <PrivateRoute component={StationScreen} path="/station" exact />
          <PrivateRoute component={StationDetailsScreen} path="/station/new" />
          <PrivateRoute
            component={StationDetailsScreen}
            path="/station/:stationId"
          />
        </Switch>
      </Route>
      <PrivateRoute component={AdminsScreen} path="/administrators" exact />
      <PrivateRoute component={NotificationScreen} path="/notification" exact />
      <PrivateRoute component={AboutScreen} path="/about" exact />
      <PrivateRoute
        component={PrivacyPolicyScreen}
        path="/privacy-policy"
        exact
      />
      <PrivateRoute
        component={TermsAndConditionsScreen}
        path="/terms-and-conditions"
        exact
      />
    </Switch>
  );
};
