import { FC, KeyboardEvent } from "react";
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./LoginScreen.styles";
import { useMutation } from "@apollo/client";
import { ILoginData, ILoginVars, LOGIN } from "../../apollo/mutations";
import { useSnackbar } from "notistack";
import { setAccessToken, useForm, validateForm } from "../../utils";
import { Email as EmailIcon, Lock as LockIcon } from "@mui/icons-material";
import { initialInputData } from "./LoginScreen.inputs";

export const LoginScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const handleEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLoginMutation();
    }
  };

  const { inputFields, setInputFields, inputProps } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [LoginPerson] = useMutation<ILoginData, ILoginVars>(LOGIN, {
    onCompleted: (data) => {
      enqueueSnackbar("Uspješna prijava!", {
        variant: "success",
      });
      setAccessToken(data.login.token);
      window.location.replace("/");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      // console.log("Error: ", { error });
    },
    variables: {
      data: {
        email: inputFields.email.value.unlocalised.value,
        password: inputFields.password.value.unlocalised.value,
      },
    },
  });

  const handleLoginMutation = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      LoginPerson();
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Typography paragraph variant="h2">
          Muzej Prigorja
        </Typography>
        <TextField
          className={styles.inputField}
          {...inputProps("email")}
          autoFocus
          autoComplete="username"
          fullWidth
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={styles.inputField}
          {...inputProps("password")}
          fullWidth
          autoComplete="current-password"
          color="secondary"
          variant="outlined"
          onKeyDown={handleEnterKey}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLoginMutation}
        >
          Login
        </Button>
      </Paper>
    </div>
  );
};
