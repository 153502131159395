import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: { marginRight: "16px" },
  searchButton: {
    borderRadius: 16,
  },
  inputField: {
    borderRadius: 16,
    paddingRight: 8,
  },
}));
