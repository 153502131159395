import { gql } from "@apollo/client";

export interface IAdmin {
  id: string;
  name: string;
  phone: string;
  email: string;
  role: {
    id: string;
    name: string;
    access: number;
  };
  created: Date;
}

export interface IAdminData {
  person: IAdmin;
}

export interface IAdminsData {
  persons: IAdmin[];
}

export interface IAdminsVars {
  filter: any;
}

export interface IAdminVars {
  id: number;
}

export const ONE_ADMIN = gql`
  query admin($id: ID!) {
    person(id: $id) {
      id
      name
      phone
      email
      role {
        id
        name
        access
      }
      created
    }
  }
`;

export const ALL_ADMINS = gql`
  query admins($filter: FindManyPersonArgs) {
    persons(filter: $filter) {
      id
      name
      phone
      email
      role {
        id
        name
        access
      }
      created
    }
  }
`;
