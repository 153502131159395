import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 32,
    width: 680,
    height: 164,
    overflow: "hidden",
    position: "relative",
    marginBottom: 16,
  },
  img: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    objectFit: "cover",
    filter: "brightness(75%)",
  },
  title: {
    position: "absolute",
    top: "25%",
    left: "10%",
    color: "white",
    textShadow: "2px 2px 4px #00000077",
    maxWidth: "55%",
  },
  expandButton: {
    position: "absolute",
    top: "35%",
    right: "10%",
  },
  unpublished: {
    filter: "grayscale(100%)",
  },
  duration: {
    position: "absolute",
    bottom: "10%",
    right: "10%",
    fontSize: 14,
    color: "white",
  },
  icon: {
    fontSize: 14,
    marginRight: 4,
  },
  category: {
    position: "absolute",
    bottom: "10%",
    left: "10%",
    fontSize: 14,
    color: "white",
  },
  deleteButton: {
    position: "absolute",
    top: "35%",
    right: "3%",
  },
  errorDiv: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    borderColor: theme.palette.error.main,
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 32,
  },
}));
