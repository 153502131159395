import { createTypedInputs } from "../../../../utils";

export const initialInputData = createTypedInputs({
  title: {
    value: { unlocalised: { value: "" } },
    label: "Title",
    placeholder: "title",
    localise: true,
    required: true,
  },
  body: {
    value: { unlocalised: { value: "" } },
    label: "Content",
    placeholder: "... write something here",
    localise: true,
    required: true,
  },
});
