import { FC, Fragment, useState } from "react";
import { useStyles } from "./CulturalHeritageScreen.styles";
import { useHistory } from "react-router-dom";
import { ErrorComponent, LoadingComponent, PageLayout } from "../../components";
import { Button, Dialog, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";
import {
  ALL_CULTURAL_HERITAGE_LIST,
  ICulturalHeritageListData,
  ICulturalHeritageListVars,
} from "../../apollo/queries";
import { Add as AddIcon } from "@mui/icons-material";
import {
  CulturalHeritageListItem,
  DeleteCulturalHeritageDialog,
} from "./components";
import { useRouteMatch } from "react-router";

interface IDialog {
  open: boolean;
  id: string | undefined;
}

export const CulturalHeritageScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [dialog, setDialog] = useState<IDialog>({
    open: false,
    id: undefined,
  });

  const handleCloseDialog = () => {
    setDialog({ open: false, id: undefined });
  };

  const { loading, error, data } = useQuery<
    ICulturalHeritageListData,
    ICulturalHeritageListVars
  >(ALL_CULTURAL_HERITAGE_LIST, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
        orderBy: {
          created: "desc",
        },
      },
    },
    onError: () => {
      enqueueSnackbar("Failed to fetch data!", { variant: "error" });
    },
  });

  const handleNavigateToNewCulturalHeritage = () => {
    history.push(`${path}/new`);
  };

  return (
    <Fragment>
      <PageLayout title="Cultural Heritage">
        {loading ? (
          <LoadingComponent />
        ) : error ? (
          <ErrorComponent error={error} />
        ) : (
          <Fragment>
            <Button
              onClick={handleNavigateToNewCulturalHeritage}
              variant="outlined"
              className={styles.button}
              startIcon={<AddIcon className={styles.addButtonIcon} />}
            >
              <Typography color="textSecondary" variant="h4">
                Add new
              </Typography>
            </Button>
            {data?.culturalHeritages.length ? (
              data?.culturalHeritages.map((item) => {
                const handleNavigateToListItem = () => {
                  history.push(`${path}/${item.id}`);
                };
                const handleOpenDialog = () => {
                  setDialog({ open: true, id: item.id });
                };

                return (
                  <CulturalHeritageListItem
                    key={item.id}
                    item={item}
                    onClick={handleNavigateToListItem}
                    onDelete={handleOpenDialog}
                  />
                );
              })
            ) : (
              <Typography variant="h4" component="p">
                No items found!
              </Typography>
            )}
          </Fragment>
        )}
      </PageLayout>
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DeleteCulturalHeritageDialog
          onClose={handleCloseDialog}
          culturalHeritageId={dialog.id}
        />
      </Dialog>
    </Fragment>
  );
};
