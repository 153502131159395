import {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  DialogActions,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  MenuItem,
} from "@mui/material";
import { useStyles } from "./UpsertStationDialog.styles";
import {
  Category as CategoryIcon,
  Description as DescriptionIcon,
  SwapHoriz as SwapHorizIcon,
  SwapVert as SwapVertIcon,
  Title as TitleIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  ContextProvider,
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
  withLinks,
} from "../../../../utils";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { initialInputData } from "./UpsertStationDialog.inputs";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
  LocaleSelect,
  MultipleMediaUpload,
} from "../../../../components";
import { RichTextEditor } from "../../../../components/RichTextEditor";
import { ReactEditor, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor } from "slate";
import {
  CREATE_CULTURAL_HERITAGE_STATION,
  ICreateCulturalHeritageStationData,
  ICreateCulturalHeritageStationVars,
  IUpdateStationData,
  IUpdateStationVars,
  UPDATE_STATION,
} from "../../../../apollo/mutations";
import {
  ALL_CULTURAL_HERITAGE_STATION,
  ALL_STATION_CATEGORIES,
  ICulturalHeritageStation,
  ICulturalHeritageStationsData,
  IStationCategoryData,
  IStationCategoryVars,
  IStationData,
  IStationVars,
  ONE_STATION,
} from "../../../../apollo/queries";

interface IProps {
  id: string | undefined;
  culturalHeritageId: string | undefined;
  onClose: () => void;
  prevLocaleSelected: string;
}

export const UpsertStationDialog: FC<IProps> = (props) => {
  const { onClose, prevLocaleSelected, id, culturalHeritageId } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { localeFlags } = useContext(ContextProvider);

  const [localeSelected, setLocaleSelected] = useState("");

  const editorRef = useRef(null);
  if (!editorRef.current)
    //@ts-ignore
    editorRef.current = withLinks(withHistory(withReact(createEditor())));
  const editor = editorRef.current;

  const { inputFields, setInputFields, setInputField, inputProps, formReady } =
    useForm<keyof typeof initialInputData>(initialInputData, localeSelected);

  const [stationQuery, { loading, error, data, called }] = useLazyQuery<
    IStationData,
    IStationVars
  >(ONE_STATION);

  const {
    loading: loadingStationCategory,
    error: errorStationCategory,
    data: dataStationCategory,
  } = useQuery<IStationCategoryData, IStationCategoryVars>(
    ALL_STATION_CATEGORIES
  );

  const [createCulturalHeritageStation, { loading: loadingCreateStation }] =
    useMutation<
      ICreateCulturalHeritageStationData,
      ICreateCulturalHeritageStationVars
    >(CREATE_CULTURAL_HERITAGE_STATION, {
      onCompleted: (data) => {
        // console.log("Create data: ", data);
        enqueueSnackbar("Successfully created new station!", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        // console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        // console.log("Cache", cache);
        const existingListData: ICulturalHeritageStationsData | null =
          cache.readQuery({
            query: ALL_CULTURAL_HERITAGE_STATION,
            variables: {
              filter: {
                where: {
                  culturalHeritageId: +culturalHeritageId!,
                  station: {
                    expired: null,
                  },
                },
              },
            },
          });
        if (data?.createCulturalHeritageStation) {
          const getFlagId =
            data?.createCulturalHeritageStation.station.locale.findIndex(
              (x) => x.localeFlag.isoLanguageCode === "en-GB"
            );
          const newListData: ICulturalHeritageStation = {
            id: data.createCulturalHeritageStation.id,
            station: {
              locale: [
                data?.createCulturalHeritageStation.station.locale[getFlagId],
              ],
              id: data?.createCulturalHeritageStation.station.id,
            },
          };
          cache.writeQuery({
            query: ALL_CULTURAL_HERITAGE_STATION,
            variables: {
              filter: {
                where: {
                  culturalHeritageId: +culturalHeritageId!,
                  station: {
                    expired: null,
                  },
                },
              },
            },
            data: {
              culturalHeritageStations:
                existingListData?.culturalHeritageStations
                  ? [newListData, ...existingListData.culturalHeritageStations]
                  : [newListData],
            },
          });
        }
      },
    });

  const [updateStation, { loading: loadingUpdateStation }] = useMutation<
    IUpdateStationData,
    IUpdateStationVars
  >(UPDATE_STATION, {
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Successfully updated station!", {
        variant: "success",
      });
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (prevLocaleSelected) {
      setLocaleSelected(prevLocaleSelected);
    }
  }, [prevLocaleSelected]);

  useEffect(() => {
    if (data?.station?.locale?.length && called && formReady) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "locale.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "locale.audioText",
          toFormProperty: "audioText",
        },
        {
          fromDataProperty: "locale.description",
          toFormProperty: "description",
        },
        {
          fromDataProperty: "locale.audio",
          toFormProperty: "audioGuide",
        },
        {
          fromDataProperty: "gallery",
          toFormProperty: "gallery",
        },
        {
          fromDataProperty: "category.id",
          toFormProperty: "category",
        },
        {
          fromDataProperty: "latitude",
          toFormProperty: "latitude",
        },
        {
          fromDataProperty: "longitude",
          toFormProperty: "longitude",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.station, valueToFormOptions, inputFields);
      // console.log("Values to update: ", getUpdatedInputs);

      if (data.station.category?.id) {
        getUpdatedInputs.showOnMap.value.unlocalised.value = "true";
      }
      if (data.station.published === false) {
        getUpdatedInputs.published.value.unlocalised.value = "false";
      }
      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, called, formReady]);

  useEffect(() => {
    if (localeSelected && !called && id)
      stationQuery({
        variables: {
          id: +id,
        },
      });
  }, [localeSelected, id, called, stationQuery]);

  const handleSetCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField(
      "published",
      event.target.value === "true" ? "false" : "true"
    );
  };

  const handleSetMapLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField(
      "showOnMap",
      event.target.value === "true" ? "false" : "true"
    );
  };

  const handleCreateNew = () => {
    const fields =
      inputFields.showOnMap.value.unlocalised.value === "false"
        ? [
            "title",
            "description",
            "published",
            "audioText",
            "audioGuide",
            "gallery",
          ]
        : Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      "allLocales",
      localeSelected
    );
    if (result.formValid && culturalHeritageId) {
      createCulturalHeritageStation({
        variables: {
          data: {
            culturalHeritage: {
              connect: { id: +culturalHeritageId },
            },
            station: {
              create: {
                published:
                  inputFields.published.value.unlocalised.value === "true",
                gallery: inputFields.gallery.value.unlocalised.value.split(","),
                category:
                  inputFields.showOnMap.value.unlocalised.value === "true" &&
                  inputFields.category.value.unlocalised.value
                    ? {
                        connect: {
                          id: +inputFields.category.value.unlocalised.value,
                        },
                      }
                    : undefined,
                latitude:
                  inputFields.showOnMap.value.unlocalised.value === "true"
                    ? inputFields.latitude.value.unlocalised.value
                    : undefined,
                longitude:
                  inputFields.showOnMap.value.unlocalised.value === "true"
                    ? inputFields.longitude.value.unlocalised.value
                    : undefined,
              },
            },
          },
          localeData: localeFlags.map((item) => {
            return {
              audio: inputFields.audioGuide.value[item.isoLanguageCode].media!
                .length
                ? {
                    connect: {
                      path: inputFields.audioGuide.value[item.isoLanguageCode]
                        .media![0].path,
                    },
                  }
                : undefined,

              audioText:
                inputFields.audioText.value[item.isoLanguageCode].value,
              title: inputFields.title.value[item.isoLanguageCode].value,
              description:
                inputFields.description.value[item.isoLanguageCode].value,
              localeFlag: { connect: { id: +item.id } },
            };
          }),
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar(
        "Not all required fields are set. Check other languages!",
        {
          variant: "error",
        }
      );
      setInputFields((prev) => {
        return { ...prev, ...result.outputData };
      });
    }
  };

  const handleUpdate = () => {
    const fields =
      inputFields.showOnMap.value.unlocalised.value === "false"
        ? [
            "title",
            "description",
            "published",
            "audioText",
            "audioGuide",
            "gallery",
          ]
        : Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      localeSelected
    );
    if (result.formValid && data && id) {
      // console.log("+++", data);
      const getLocaleId = data.station.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      const getFlagId = data.station.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      if (getLocaleId && getFlagId) {
        updateStation({
          variables: {
            id: +id,
            localeId: +getLocaleId,
            data: {
              published:
                inputFields.published.value.unlocalised.value === "true",
              gallery: inputFields.gallery.value.unlocalised.value.split(","),
              category:
                inputFields.showOnMap.value.unlocalised.value === "true" &&
                inputFields.category.value.unlocalised.value
                  ? {
                      connect: {
                        id: +inputFields.category.value.unlocalised.value,
                      },
                    }
                  : { disconnect: true },
              latitude:
                inputFields.showOnMap.value.unlocalised.value === "true"
                  ? inputFields.latitude.value.unlocalised.value
                  : "",
              longitude:
                inputFields.showOnMap.value.unlocalised.value === "true"
                  ? inputFields.longitude.value.unlocalised.value
                  : "",
            },
            localeData: {
              audio: inputFields.audioGuide.value[localeSelected].media!.length
                ? {
                    connect: {
                      path: inputFields.audioGuide.value[localeSelected]
                        .media![0].path,
                    },
                  }
                : {
                    disconnect: true,
                  },
              audioText: inputFields.audioText.value[localeSelected].value,
              title: inputFields.title.value[localeSelected].value,
              description: inputFields.description.value[localeSelected].value,
              localeFlag: {
                connect: {
                  id: +getFlagId,
                },
              },
            },
          },
        });
      } else {
        enqueueSnackbar("Something went wrong, try again later!", {
          variant: "error",
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar(
        "Not all required fields are set. Check other languages!",
        {
          variant: "error",
        }
      );
      setInputFields((prev) => {
        return { ...prev, ...result.outputData };
      });
    }
  };

  // const handleUpdateAll = () => {
  //   const fields =
  //     inputFields.showOnMap.value.unlocalised.value === "false"
  //       ? [
  //           "title",
  //           "description",
  //           "published",
  //           "audioText",
  //           "audioGuide",
  //           "gallery",
  //         ]
  //       : Object.keys(initialInputData);
  //   const result = validateForm<keyof typeof initialInputData>(
  //     fields,
  //     inputFields,
  //     "allLocales",
  //     localeSelected
  //   );
  //   if (result.formValid && data && id) {
  //     localeFlags.forEach((item) => {
  //       const getLocaleId = data.station.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       const getFlagId = data.station.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       if (getLocaleId && getFlagId) {
  //         updateStation({
  //           variables: {
  //             id: +id,
  //             localeId: +getLocaleId,
  //             data: {
  //               published: true,
  //               gallery: inputFields.gallery.value.unlocalised.value.split(","),
  //               category:
  //                 inputFields.showOnMap.value.unlocalised.value === "false" &&
  //                 inputFields.category.value.unlocalised.value
  //                   ? {
  //                       connect: {
  //                         id: +inputFields.category.value.unlocalised.value,
  //                       },
  //                     }
  //                   : { disconnect: true },
  //               latitude:
  //                 inputFields.showOnMap.value.unlocalised.value === "false"
  //                   ? inputFields.latitude.value.unlocalised.value
  //                   : "",
  //               longitude:
  //                 inputFields.showOnMap.value.unlocalised.value === "false"
  //                   ? inputFields.longitude.value.unlocalised.value
  //                   : "",
  //             },
  //             localeData: {
  //               audio: inputFields.audioGuide.value[localeSelected].media!
  //                 .length
  //                 ? {
  //                     connect: {
  //                       path: inputFields.audioGuide.value[localeSelected]
  //                         .media![0].path,
  //                     },
  //                   }
  //                 : {
  //                     disconnect: true,
  //                   },
  //               audioText: inputFields.audioText.value[localeSelected].value,
  //               title: inputFields.title.value[localeSelected].value,
  //               description:
  //                 inputFields.description.value[localeSelected].value,
  //               localeFlag: {
  //                 connect: {
  //                   id: +getFlagId,
  //                 },
  //               },
  //             },
  //           },
  //         });
  //       } else {
  //         enqueueSnackbar("Something went wrong, try again later!", {
  //           variant: "error",
  //         });
  //       }
  //     });
  //   } else {
  //     console.log("Form is invalid: ", result);
  //     enqueueSnackbar(
  //       "Not all required fields are set. Check other languages!",
  //       {
  //         variant: "error",
  //       }
  //     );
  //     setInputFields((prev) => {
  //       return { ...prev, ...result.outputData };
  //     });
  //   }
  // };

  return (
    <Fragment>
      <DialogTitle>
        <Typography variant="h6">
          {id ? "Update station" : "Add new station"}
        </Typography>
        <LocaleSelect
          selected={localeSelected}
          onSetSelected={setLocaleSelected}
        />
      </DialogTitle>
      <DialogContent>
        {!formReady || loading ? (
          <LoadingComponent small />
        ) : error ? (
          <ErrorComponent error={error} />
        ) : (
          <Fragment>
            <Typography
              className={styles.subtitle}
              color="primary"
              variant="h6"
            >
              Information
            </Typography>
            <TextField
              className={styles.inputField}
              fullWidth
              variant="outlined"
              {...inputProps("title")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleIcon />
                  </InputAdornment>
                ),
              }}
            />

            <RichTextEditor
              className={styles.rteInputField}
              editor={editor as unknown as ReactEditor}
              // readOnly={!edit}
              {...inputProps("description", editor)}
            />

            <Typography
              className={styles.subtitle}
              color="primary"
              variant="h6"
            >
              Gallery
            </Typography>
            <MultipleMediaUpload
              {...inputProps("gallery")}
              validTypes={["image/jpeg", "image/jpg"]}
              styleType="gallery"
              max={8}
            />
            <Typography
              className={styles.subtitle}
              color="primary"
              variant="h6"
            >
              Audio Guide
            </Typography>
            <MultipleMediaUpload
              {...inputProps("audioGuide")}
              validTypes={["audio/flac", "audio/mpeg"]}
              styleType="audio"
              max={1}
              vanishWhenMax
              className={styles.subtitle}
            />
            <TextField
              variant="outlined"
              {...inputProps("audioText")}
              minRows={4}
              multiline
              maxRows={8}
              className={styles.subtitle}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionIcon />
                  </InputAdornment>
                ),
              }}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      inputFields.published.value.unlocalised.value === "true"
                    }
                    onChange={handleSetCheckbox}
                    value={
                      inputFields.published.value.unlocalised.value === "true"
                    }
                  />
                }
                label={inputFields.published.label}
              />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      inputFields.showOnMap.value.unlocalised.value === "true"
                    }
                    onChange={handleSetMapLocation}
                    value={
                      inputFields.showOnMap.value.unlocalised.value === "true"
                    }
                  />
                }
                label={inputFields.showOnMap.label}
              />
            </FormGroup>

            <Typography
              className={styles.subtitle}
              color="primary"
              variant="h6"
            >
              Map Location
            </Typography>
            <TextField
              className={styles.subtitle}
              fullWidth
              disabled={
                inputFields.showOnMap.value.unlocalised.value === "false"
              }
              variant="outlined"
              select
              {...inputProps("category")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CategoryIcon />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="">-- Select category --</MenuItem>
              {loadingStationCategory ? (
                <LoadingComponent small />
              ) : errorStationCategory ? (
                <ErrorComponent error={errorStationCategory} />
              ) : dataStationCategory?.stationCategories.length ? (
                dataStationCategory.stationCategories.map((item) => {
                  if (item.locale?.length) {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.locale[0].name}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem key={item.id} value="" disabled>
                        Something went wrong!
                      </MenuItem>
                    );
                  }
                })
              ) : (
                <MenuItem disabled value="">
                  No categories found!
                </MenuItem>
              )}
            </TextField>
            <TextField
              variant="outlined"
              {...inputProps("latitude")}
              disabled={
                inputFields.showOnMap.value.unlocalised.value === "false"
              }
              className={styles.subtitle}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SwapVertIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              {...inputProps("longitude")}
              className={styles.subtitle}
              disabled={
                inputFields.showOnMap.value.unlocalised.value === "false"
              }
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SwapHorizIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        {id ? (
          <Fragment>
            <Button variant="contained" onClick={handleUpdate}>
              Update {localeSelected}
            </Button>
            {/* <Button variant="contained" onClick={handleUpdateAll}>
              Update All
            </Button> */}
          </Fragment>
        ) : (
          <Button variant="contained" onClick={handleCreateNew}>
            Add
          </Button>
        )}
      </DialogActions>
      <LoadingBackdrop loading={loadingCreateStation || loadingUpdateStation} />
    </Fragment>
  );
};
