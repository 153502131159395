import { gql } from "@apollo/client";

export interface IOpeningHour {
  id: string;
  weekday: number;
  startHour: string;
  endHour: string;
  created: Date;
  updated: Date;
}

export interface IOpeningHoursData {
  openingHours: IOpeningHour[];
}

export interface IOpeningHoursVars {
  filter: any;
}

export const ALL_OPENING_HOURS = gql`
  query openingHours($filter: FindManyOpeningHourArgs) {
    openingHours(filter: $filter) {
      id
      weekday
      startHour
      endHour
      created
      updated
    }
  }
`;
