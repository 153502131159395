import { createContext, Dispatch, SetStateAction } from "react";
import { ILocaleFlag } from "../../apollo/queries";

export interface IContextProvider {
  // userId?: string;
  // setUserId: Dispatch<SetStateAction<undefined>>;
  localeFlags: ILocaleFlag[];
  setLocaleFlags: Dispatch<SetStateAction<ILocaleFlag[]>>;
}

export const ContextProvider = createContext<IContextProvider>({
  // userId: undefined,
  // setUserId: () => {},
  localeFlags: [],
  setLocaleFlags: () => {},
});
