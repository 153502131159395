import { gql } from "@apollo/client";

export interface ICreateNotificationVars {
  localeData: {
    body: string;
    title: string;
    localeFlag: { connect: { id: number } };
  }[];
}

export interface ICreateNotification {
  id: string;
  created: Date;
  locale: {
    id: string;
    title: string;
    body: string;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface ICreateNotificationData {
  createNotification: ICreateNotification;
}

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($localeData: [NotificationLocaleCreateInput]) {
    createNotification(localeData: $localeData) {
      id
      created
      locale {
        id
        title
        body
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
