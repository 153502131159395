import { gql } from "@apollo/client";

export interface ICreateStationVars {
  data: {
    published: boolean;
    gallery: string[];
    category?: { connect: { id: number } };
    longitude?: string;
    latitude?: string;
  };
  localeData: {
    description: string;
    title: string;
    localeFlag: { connect: { id: number } };
    audio?: { connect: { path: string } };
    audioText?: string;
  }[];
}

export interface ICreateStation {
  id: string;
  published: boolean;
  created: string;
  gallery: {
    id: string;
    path: string;
    extension: string;
    type: string;
    name: string;
    size: string;
  }[];
  category?: {
    id: string;
    locale: {
      name: string;
    }[];
  };
  locale: {
    id: string;
    title: string;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface ICreateStationData {
  createStation: ICreateStation;
}

export const CREATE_STATION = gql`
  mutation createStation(
    $data: StationCreateInput
    $localeData: [StationLocaleCreateInput]
  ) {
    createStation(data: $data, localeData: $localeData) {
      id
      created
      published
      gallery {
        id
        name
        extension
        size
        id
        path
      }
      category {
        id
        locale {
          id
          name
        }
      }
      locale {
        id
        title
        audioText
        audio {
          id
          name
          extension
          size
          id
          path
        }
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
