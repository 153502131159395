import { gql } from "@apollo/client";

export interface IUpsertOpenHourVars {
  id?: number | undefined;
  createData: {
    startHour: string;
    endHour: string;
    weekday: number;
  };
  updateData: {
    startHour: string;
    endHour: string;
    weekday: number;
  };
}

export interface IUpsertOpenHour {
  id: string;
  weekday: number;
  startHour: string;
  endHour: string;
  created: Date;
  updated: Date;
}

export interface IUpsertOpenHourData {
  upsertOpeningHour: IUpsertOpenHour;
}

export const UPSERT_OPENING_HOUR = gql`
  mutation upsertOpeningHour(
    $id: ID
    $createData: OpeningHourCreateInput
    $updateData: OpeningHourUpdateInput
  ) {
    upsertOpeningHour(
      id: $id
      createData: $createData
      updateData: $updateData
    ) {
      id
      weekday
      startHour
      endHour
      created
      updated
    }
  }
`;
