import { createTypedInputs } from "../../../../utils";

export const initialInputData = createTypedInputs({
  password: {
    value: { unlocalised: { value: "" } },
    label: "Current password",
    required: true,
    type: "password",
  },
  passwordReset: {
    value: { unlocalised: { value: "" } },
    label: "New password",
    type: "password",
    required: true,
  },
  passwordResetRepeat: {
    value: { unlocalised: { value: "" } },
    label: "Repeat new password",
    type: "password",
    required: true,
    errorText: "Password does not match!",
  },
});
