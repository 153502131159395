import { FC, Fragment, useContext, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  DialogActions,
  Typography,
} from "@mui/material";
import { useStyles } from "./AddNotificationDialog.styles";
import {
  Description as DescriptionIcon,
  Title as TitleIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { ContextProvider, useForm, validateForm } from "../../../../utils";
import { useMutation } from "@apollo/client";
import {
  CREATE_NOTIFICATION,
  ICreateNotificationData,
  ICreateNotificationVars,
} from "../../../../apollo/mutations";
import { initialInputData } from "./AddNotificationDialog.inputs";
import {
  ALL_NOTIFICATIONS,
  INotification,
  INotificationsData,
} from "../../../../apollo/queries";
import {
  LoadingBackdrop,
  LoadingComponent,
  LocaleSelect,
} from "../../../../components";

interface IProps {
  onClose: () => void;
}

export const AddNotificationDialog: FC<IProps> = (props) => {
  const { onClose } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { localeFlags } = useContext(ContextProvider);
  const [localeSelected, setLocaleSelected] = useState("");

  const { inputFields, setInputFields, inputProps, formReady } = useForm<
    keyof typeof initialInputData
  >(initialInputData, localeSelected);

  const [createNotification, { loading: mutationLoading }] = useMutation<
    ICreateNotificationData,
    ICreateNotificationVars
  >(CREATE_NOTIFICATION, {
    onCompleted: () => {
      enqueueSnackbar("Successfully added a new notification!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar("Failed to create notification, try again later!", {
        variant: "error",
      });
      // console.log("Error: ", { error });
    },

    update: (cache, { data }) => {
      const existingData: INotificationsData | null = cache.readQuery({
        query: ALL_NOTIFICATIONS,
        variables: {
          filter: {
            where: {
              expired: null,
            },
            orderBy: {
              created: "desc",
            },
          },
        },
      });
      if (data?.createNotification) {
        const newData: INotification = {
          ...data.createNotification,
        };
        cache.writeQuery({
          query: ALL_NOTIFICATIONS,
          variables: {
            filter: {
              where: {
                expired: null,
              },
              orderBy: {
                created: "desc",
              },
            },
          },
          data: {
            notifications: existingData?.notifications
              ? [newData, ...existingData.notifications]
              : [newData],
          },
        });
      }
    },
  });

  const handleCreateNotification = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields, "allLocales");
    if (result.formValid && formReady) {
      createNotification({
        variables: {
          localeData: localeFlags.map((item) => {
            return {
              title: inputFields.title.value[item.isoLanguageCode].value,
              body: inputFields.body.value[item.isoLanguageCode].value,
              localeFlag: { connect: { id: +item.id } },
            };
          }),
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  return (
    <Fragment>
      <DialogTitle>
        <Typography variant="h6">Add new notification</Typography>
        <LocaleSelect
          selected={localeSelected}
          onSetSelected={setLocaleSelected}
        />
      </DialogTitle>
      <DialogContent>
        {!formReady ? (
          <LoadingComponent />
        ) : (
          <Fragment>
            <TextField
              className={styles.inputField}
              {...inputProps("title")}
              fullWidth
              autoFocus
              color="secondary"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={styles.inputField}
              {...inputProps("body")}
              fullWidth
              maxRows={6}
              minRows={3}
              multiline
              color="secondary"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateNotification}
        >
          Add
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={mutationLoading} />
    </Fragment>
  );
};
