import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { Reference, useMutation } from "@apollo/client";
import {
  IDeleteStationData,
  IDeleteStationVars,
  DELETE_STATION,
} from "../../../../apollo/mutations";
import { LoadingBackdrop } from "../../../../components";

interface IProps {
  onClose: () => void;
  stationId: string | undefined;
}
export const DeleteStationDialog: FC<IProps> = (props) => {
  const { onClose, stationId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [deleteStation, { loading: mutationLoading }] = useMutation<
    IDeleteStationData,
    IDeleteStationVars
  >(DELETE_STATION, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted station!", { variant: "success" });
      onClose();
    },
    onError: () => {
      // console.log("Error: ", { error });
      enqueueSnackbar("Failed to delete station, try again later!", {
        variant: "error",
      });
    },
    update(cache, { data }) {
      // console.log(data);
      cache.modify({
        fields: {
          stations(existingData: Array<Reference>, { readField }) {
            if (data) {
              return existingData.filter(
                (taskRef) => data.deleteStation.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const onDeleteStation = (): void => {
    if (stationId) {
      deleteStation({
        variables: { id: +stationId },
      });
    } else {
      enqueueSnackbar("Cannot find ID, try again later!", { variant: "error" });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Delete station</DialogTitle>
      <DialogContent>This action cannot be undone!</DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onDeleteStation}>
          Delete
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={mutationLoading} />
    </Fragment>
  );
};
