import { gql } from "@apollo/client";

export interface IDeleteCulturalHeritageStationVars {
  id: number;
}

export interface IDeleteCulturalHeritageStation {
  id: string;
}

export interface IDeleteCulturalHeritageStationData {
  deleteCulturalHeritageStation: IDeleteCulturalHeritageStation;
}

export const DELETE_CULTURAL_HERITAGE_STATION = gql`
  mutation deleteCulturalHeritageStation($id: ID!) {
    deleteCulturalHeritageStation(id: $id) {
      id
    }
  }
`;
