import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  sideNavigation: {
    position: "fixed",
    // paddingTop: "64px",
    backgroundColor: "white",
    height: "100vh",
    width: "256px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: 0,
    zIndex: 2,
    boxShadow: "15px 0px 95px -10px rgba(0,0,0,0.3)",
  },
  button: { borderRadius: 0 },
  topTitle: {
    color: "#fff",
    marginLeft: "64px",
    fontSize: "26px",
  },
  title: {
    padding: "32px 16px 32px 16px",
  },
  navLink: {
    textDecoration: "none",
    textTransform: "uppercase",
    width: "256px",
    display: "block",
    padding: "8px 16px",
    color: theme.palette.text.secondary,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(162,105,77,1) 0%, rgba(196,135,105,1) 100%)",
      color: theme.palette.common.white,
    },
  },
  navLinkActive: {
    textDecoration: "none",
    textTransform: "uppercase",
    width: "268px",
    display: "block",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    padding: "8px 16px",
    // background: "rgb(255,255,255)",
    background:
      "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(162,105,77,1) 0%, #BB8D7C 100%)",
    color: theme.palette.common.white,
    transition: "all 0.2s ease-in-out",
    boxShadow: "5px 5px 15px 0 rgba(0,0,0,0.4)",

    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(162,105,77,1) 0%, #BB8D7C 100%)",
    },
  },
  logoDiv: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  navigation: {
    width: "100%",
  },
  divider: {
    width: 56,
    margin: "4px 0 4px 16px",
  },
  logoutButton: {
    borderRadius: 0,
    justifyContent: "space-between",
    textDecoration: "none",
    textTransform: "uppercase",
    width: "256px",
    padding: "8px 16px",
    color: theme.palette.text.secondary,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(162,105,77,1) 0%, rgba(196,135,105,1) 100%)",
      color: theme.palette.common.white,
    },
  },
  profileButton: {
    position: "fixed",
    top: 16,
    right: 32,
  },
}));
