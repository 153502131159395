import { gql } from "@apollo/client";

export interface ICreateCulturalHeritageVars {
  data: {
    duration: number;
    category: { connect: { id: number } };
    published: boolean;
    media: { connect: { path: string } };
    gallery: string[];
  };
  localeData: {
    description: string;
    title: string;
    localeFlag: { connect: { id: number } };
    audio?: { connect: { path: string } };
    audioText?: string;
  }[];
}

export interface ICreateCulturalHeritage {
  id: string;
  published: boolean;
  created: Date;
  media?: {
    path: string;
  };
  duration: number;
  category: {
    id: string;
    locale: {
      name: string;
    }[];
  };
  locale: {
    title: string;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface ICreateCulturalHeritageData {
  createCulturalHeritage: ICreateCulturalHeritage;
}

export const CREATE_CULTURAL_HERITAGE = gql`
  mutation createCulturalHeritage(
    $data: CulturalHeritageCreateInput
    $localeData: [CulturalHeritageLocaleCreateInput]
  ) {
    createCulturalHeritage(data: $data, localeData: $localeData) {
      id
      created
      published
      duration
      media {
        id
        path
      }
      category {
        id
        locale {
          id
          name
        }
      }
      locale {
        id
        title
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
