import { gql } from "@apollo/client";

export interface IUpdateStationVars {
  id: number;
  localeId: number;
  data: {
    published: boolean;
    gallery: string[];
    category?: { connect: { id: number } } | { disconnect: true };
    longitude?: string;
    latitude?: string;
  };
  localeData: {
    description: string;
    title: string;
    localeFlag: { connect: { id: number } };
    audio?: { connect: { path: string } } | { disconnect: boolean };
    audioText?: string;
  };
}

export interface IUpdateStation {
  id: string;
  published: boolean;
  longitude: string;
  latitude: string;
  created: Date;
  gallery: {
    id: string;
    path: string;
    name: string;
    stize: number;
    extension: string;
    type: string;
  }[];
  category?: {
    id: string;
    locale: {
      name: string;
    }[];
  };
  locale: {
    id: string;
    title: string;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface IUpdateStationData {
  updateStation: IUpdateStation;
}

export const UPDATE_STATION = gql`
  mutation updateStation(
    $id: ID!
    $localeId: ID!
    $data: StationUpdateInput!
    $localeData: StationLocaleUpdateInput!
  ) {
    updateStation(
      id: $id
      localeId: $localeId
      data: $data
      localeData: $localeData
    ) {
      id
      created
      published
      longitude
      latitude
      gallery {
        id
        path
        extension
        size
        type
        name
      }
      category {
        id
        locale {
          id
          name
        }
      }
      locale {
        id
        title
        audioText
        audio {
          id
          path
          extension
          size
          type
          name
        }
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
