import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  email: {
    value: { unlocalised: { value: "" } },
    label: "Email",
    required: true,
    rules: "email",
    type: "email",
  },
  password: {
    value: { unlocalised: { value: "" } },
    label: "Lozinka",
    required: true,
    type: "password",
  },
});
