import { emptySlateValue } from "../../components/RichTextEditor";
import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  title: {
    value: { unlocalised: { value: "" } },
    label: "Title",
    placeholder: "Title",
    required: true,
    localise: true,
  },
  description: {
    value: { unlocalised: { value: "", rteValue: emptySlateValue } },
    label: "Description",
    placeholder: "Description...",
    required: true,
    localise: true,
    rules: "slateRte",
  },
  published: {
    value: { unlocalised: { value: "true" } },
    label: "Published",
    required: true,
  },
  audioText: {
    value: { unlocalised: { value: "" } },
    label: "Audio Text",
    placeholder: "Audio text...",
    localise: true,
  },
  audioGuide: {
    value: { unlocalised: { value: "", media: [] } },
    label: "Audio Guide",
    localise: true,
    rules: "mediaUpload",
  },
  gallery: {
    value: { unlocalised: { value: "", media: [] } },
    label: "Gallery",
    required: true,
    rules: "mediaUpload",
  },
  showOnMap: {
    value: { unlocalised: { value: "false" } },
    label: "Show station on map",
  },
  category: {
    value: {
      unlocalised: { value: "" },
    },
    label: "Category",
    required: true,
  },
  longitude: {
    value: { unlocalised: { value: "" } },
    label: "Longitude",
    required: true,
  },
  latitude: {
    value: { unlocalised: { value: "" } },
    label: "Latitude",
    required: true,
  },
});
