import { FC, Fragment, useEffect, useState } from "react";
import { useStyles } from "./AboutScreen.styles";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
  PageLayout,
} from "../../components";
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  TableBody,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { initialInputData } from "./AboutScreen.input";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../utils";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_OPENING_HOURS,
  IAboutUsGalleryData,
  IAboutUsGalleryVars,
  IOpeningHour,
  IOpeningHoursData,
  IOpeningHoursVars,
  ONE_ABOUT_US_GALLERY,
} from "../../apollo/queries";
import { MultipleMediaUpload } from "../../components/MultipleMediaUpload/MultipleMediaUpload";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import {
  DeleteOpeningHourDialog,
  UpsertOpeningHoursDialog,
} from "./components";
import {
  IUpdateAboutUsGalleryData,
  IUpdateAboutUsGalleryVars,
  UPDATE_ABOUT_US_GALLERY,
} from "../../apollo/mutations/aboutUsGallery.update";

export const AboutScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, setInputFields, inputProps, formReady } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [modal, setModal] = useState<{
    item: IOpeningHour | undefined;
    type: undefined | "Delete" | "Upsert";
  }>({
    item: undefined,
    type: undefined,
  });

  const handleCloseDialog = () => {
    setModal({ item: undefined, type: undefined });
  };

  const handleSetAddModal = () => {
    setModal({ item: undefined, type: "Upsert" });
  };

  const {
    loading: loadingAboutUsGallery,
    error: errorAboutUsGallery,
    data: dataAboutUsGallery,
  } = useQuery<IAboutUsGalleryData, IAboutUsGalleryVars>(ONE_ABOUT_US_GALLERY, {
    variables: {
      id: 1,
    },
    onError: () => {
      enqueueSnackbar(
        "An error occurred while fetching data. Please try again later!"
      );
    },
  });

  const { loading, error, data } = useQuery<
    IOpeningHoursData,
    IOpeningHoursVars
  >(ALL_OPENING_HOURS, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
        orderBy: {
          weekday: "asc",
        },
      },
    },
    onError: () => {
      enqueueSnackbar(
        "An error occurred while fetching data. Please try again later!",
        { variant: "error" }
      );
    },
  });

  const [updateAboutUsGallery, { loading: loadingUpdateAboutUsGallery }] =
    useMutation<IUpdateAboutUsGalleryData, IUpdateAboutUsGalleryVars>(
      UPDATE_ABOUT_US_GALLERY,
      {
        onCompleted: (data) => {
          // console.log("Create data: ", data);
          enqueueSnackbar("Successfully updated gallery", {
            variant: "success",
          });
        },
        onError: (err) => {
          // console.error({ err });
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        },
      }
    );

  const handleUpdate = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields
    );
    if (result.formValid) {
      updateAboutUsGallery({
        variables: {
          id: 1,
          data: {
            gallery:
              inputFields.aboutUsGallery.value.unlocalised.value.split(","),
          },
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar(
        "Not all required fields are set. Check other languages!",
        {
          variant: "error",
        }
      );
      setInputFields(result.outputData);
    }
  };

  useEffect(
    () => {
      if (dataAboutUsGallery?.aboutUsGallery.gallery?.length && formReady) {
        const valueToFormOptions: TValueToFormOptions = [
          {
            fromDataProperty: "gallery",
            toFormProperty: "aboutUsGallery",
          },
        ];
        const getUpdatedInputs = getFormValuesFromFetchedData<
          keyof typeof initialInputData
        >(dataAboutUsGallery.aboutUsGallery, valueToFormOptions, inputFields);
        // console.log("Values to update: ", getUpdatedInputs);
        setInputFields(getUpdatedInputs);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataAboutUsGallery, formReady]
  );

  const renderCell = () => {
    if (data?.openingHours.length) {
      return data.openingHours.map((item) => {
        const handleSetDeleteModal = () => {
          setModal({ item, type: "Delete" });
        };
        const handleSetEditModal = () => {
          setModal({ item, type: "Upsert" });
        };
        let day = "";
        switch (item.weekday) {
          case 1: {
            day = "Monday";
            break;
          }
          case 2: {
            day = "Tuesday";
            break;
          }
          case 3: {
            day = "Wednesday";
            break;
          }
          case 4: {
            day = "Thursday";
            break;
          }
          case 5: {
            day = "Friday";
            break;
          }
          case 6: {
            day = "Saturday";
            break;
          }
          case 7: {
            day = "Sunday";
            break;
          }
          default: {
            day = "Unknown day";
            break;
          }
        }

        return (
          <TableRow className={styles.tableRow} key={item.id}>
            <TableCell component="th" scope="row">
              {day}
            </TableCell>
            <TableCell>{item.startHour}</TableCell>
            <TableCell>{item.endHour}</TableCell>
            <TableCell>
              <IconButton onClick={handleSetEditModal}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleSetDeleteModal}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });
    } else {
      return (
        <TableRow>
          <TableCell>No data found</TableCell>
        </TableRow>
      );
    }
  };

  // console.log("IF: ", inputFields, dataAboutUsGallery);

  return (
    <Fragment>
      <PageLayout title="About Museum">
        {loading || loadingAboutUsGallery || !formReady ? (
          <LoadingComponent />
        ) : error || errorAboutUsGallery ? (
          <ErrorComponent error={error || errorAboutUsGallery} />
        ) : (
          <Fragment>
            <Paper className={styles.galleryPaper}>
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Gallery
              </Typography>
              <MultipleMediaUpload
                {...inputProps("aboutUsGallery")}
                validTypes={["image/jpeg", "image/jpg"]}
                styleType="gallery"
                max={8}
              />
              <Button
                onClick={handleUpdate}
                color="primary"
                variant="contained"
              >
                Update Gallery
              </Button>
            </Paper>
            <Paper className={styles.paper}>
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Opening Hours
                <Tooltip title="For multiple shifts in one day, create two days with different time. Example: Monday - 8:00h to 12:00h, Monday 16:00h to 20:00h">
                  <HelpIcon fontSize="small" className={styles.helpIcon} />
                </Tooltip>
              </Typography>
              <TableContainer component={Paper} className={styles.paper}>
                <Table size="small" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>WEEK DAY</TableCell>
                      <TableCell>START HOUR</TableCell>
                      <TableCell>END HOUR</TableCell>
                      <TableCell width={160}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderCell()}</TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="contained"
                className={styles.button}
                onClick={handleSetAddModal}
              >
                Add new
              </Button>
            </Paper>
          </Fragment>
        )}
      </PageLayout>
      <Dialog
        open={modal.type === "Upsert"}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
      >
        <UpsertOpeningHoursDialog
          onClose={handleCloseDialog}
          item={modal.item}
        />
      </Dialog>
      <Dialog
        open={modal.type === "Delete"}
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
      >
        <DeleteOpeningHourDialog
          id={modal.item?.id}
          onClose={handleCloseDialog}
        />
      </Dialog>
      <LoadingBackdrop loading={loadingUpdateAboutUsGallery} />
    </Fragment>
  );
};
