import { createTypedInputs } from "../../../../utils";

export const initialInputData = createTypedInputs({
  phone: {
    value: { unlocalised: { value: "" } },
    label: "Contact number",
    placeholder: "",
    required: false,
  },
  name: {
    value: { unlocalised: { value: "" } },
    label: "Name",
    placeholder: "John Doe",
    required: true,
  },
});
