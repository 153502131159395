import { gql } from "@apollo/client";

export interface ICulturalHeritageCategory {
  id: string;
  locale: {
    name: string;
  }[];
}

export interface ICulturalHeritageCategoryData {
  culturalHeritageCategories: ICulturalHeritageCategory[];
}

export interface ICulturalHeritageCategoryVars {
  filter: any;
}

export const ALL_CULTURAL_HERITAGE_CATEGOREIS = gql`
  query culturalHeritageCategories(
    $filter: FindManyCulturalHeritageCategoryArgs
  ) {
    culturalHeritageCategories(filter: $filter) {
      id
      locale(lang: "en-GB") {
        name
      }
    }
  }
`;
