import { gql } from "@apollo/client";

export interface IAboutUsGallery {
  gallery: {
    id: string;
    path: string;
    extension: string;
    size: number;
    type: string;
    name: string;
  }[];
}

export interface IAboutUsGalleryData {
  aboutUsGallery: IAboutUsGallery;
}

export interface IAboutUsGalleryVars {
  id: number;
}

export const ONE_ABOUT_US_GALLERY = gql`
  query aboutUsGallery($id: ID!) {
    aboutUsGallery(id: $id) {
      id
      gallery {
        id
        path
        extension
        size
        type
        name
      }
    }
  }
`;
