import { FC, Fragment, useState } from "react";
import { useStyles } from "./PrivacyPolicyPublicScreen.styles";
import { ErrorComponent, LoadingComponent, PageLayout } from "../../components";
import { Paper } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";
import {
  ITermsOfServiceData,
  ITermsOfServiceVars,
  ONE_TERMS_OF_SERVICE,
} from "../../apollo/queries";
import { sanitize } from "dompurify";

export const PrivacyPolicyPublicScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [localeSelected, setLocaleSelected] = useState("");

  const localeSelectProps = {
    selected: localeSelected,
    setSelected: setLocaleSelected,
  };

  const { loading, error, data } = useQuery<
    ITermsOfServiceData,
    ITermsOfServiceVars
  >(ONE_TERMS_OF_SERVICE, {
    variables: {
      type: "PrivacyPolicy",
      lang: localeSelected,
    },
    onError: () => {
      enqueueSnackbar("Failed to fetch data, try again later!", {
        variant: "error",
      });
    },
  });

  return (
    <PageLayout title="Privacy Policy" localeSelect={localeSelectProps}>
      <Fragment>
        {loading ? (
          <LoadingComponent />
        ) : error || !data?.termsOfService ? (
          <ErrorComponent />
        ) : (
          <Paper className={styles.paper}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(data.termsOfService.locale[0].content),
              }}
            />
          </Paper>
        )}
      </Fragment>
    </PageLayout>
  );
};
