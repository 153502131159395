import {
  emptySlateStringValue,
  emptySlateValue,
} from "../../components/RichTextEditor";
import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  description: {
    value: {
      unlocalised: { value: emptySlateStringValue, rteValue: emptySlateValue },
    },
    label: "Description",
    required: true,
    localise: true,
    rules: "slateRte",
  },
  duration: {
    value: {
      unlocalised: { value: "" },
    },
    label: "Duration",
    required: true,
    type: "number",
  },
  title: {
    value: {
      unlocalised: { value: "" },
    },
    label: "Title",
    localise: true,
    required: true,
  },
  published: {
    value: {
      unlocalised: { value: "true" },
    },
    label: "Published",
  },
  headerImage: {
    value: {
      unlocalised: { value: "", media: [] },
    },
    label: "Header Image",
    required: true,
    rules: "mediaUpload",
  },
  gallery: {
    value: {
      unlocalised: { value: "", media: [] },
    },
    label: "Media Gallery",
    required: true,
    rules: "mediaUpload",
  },
  audioGuide: {
    value: {
      unlocalised: { value: "", media: [] },
    },
    label: "Audio Guide",
    localise: true,
    rules: "mediaUpload",
  },
  audioText: {
    value: {
      unlocalised: { value: "" },
    },
    label: "Audio Text",
    localise: true,
  },
  category: {
    value: {
      unlocalised: { value: "" },
    },
    label: "Category",
    required: true,
  },
});
