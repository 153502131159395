import { FC, Fragment } from "react";
import { useMutation } from "@apollo/client";
import { initialInputData } from "./ResetPasswordDialog.inputs";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
} from "@mui/icons-material";
import { useForm, validateForm } from "../../../../utils";
import {
  IUpdateAdminPassword,
  IUpdatePersonPasswordVars,
  UPDATE_ADMIN_PASSWORD,
} from "../../../../apollo/mutations";

interface IProps {
  onClose: () => void;
  adminId: string | undefined;
  adminEmail: string | undefined;
}

export const ResetPasswordDialog: FC<IProps> = (props) => {
  const { onClose, adminId, adminEmail } = props;
  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, setInputFields, inputProps } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [updatePersonMutation, { loading: loadingUpdateMutation }] =
    useMutation<IUpdateAdminPassword, IUpdatePersonPasswordVars>(
      UPDATE_ADMIN_PASSWORD,
      {
        onCompleted: (data) => {
          enqueueSnackbar("Lozinka je uspješno ažurirana!", {
            variant: "success",
          });
          onClose();
        },
        onError: (err) => {
          // console.error({ err });
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        },
      }
    );

  const handleUpdatePassword = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields
    );
    if (result.formValid) {
      if (
        inputFields.passwordReset.value.unlocalised.value ===
        inputFields.passwordResetRepeat.value.unlocalised.value
      ) {
        if (adminId && adminEmail) {
          updatePersonMutation({
            variables: {
              id: +adminId,
              data: {
                password: inputFields.password.value.unlocalised.value,
                passwordReset:
                  inputFields.passwordReset.value.unlocalised.value,
              },
            },
          });
        } else {
          enqueueSnackbar("Something went wrong, try again later!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Password does not match!", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  // console.log({ ...inputProps("passwordResetRepeat") });

  return (
    <Fragment>
      <DialogTitle>Promijena lozinke</DialogTitle>
      <DialogContent>
        <form>
          <input
            autoComplete="username"
            type="hidden"
            defaultValue={adminEmail}
          />
          <TextField
            {...inputProps("password")}
            fullWidth
            color="secondary"
            variant="outlined"
            margin="normal"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...inputProps("passwordReset")}
            fullWidth
            autoComplete="new-password"
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...inputProps("passwordResetRepeat")}
            fullWidth
            margin="normal"
            autoComplete="new-password"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePassword}
        >
          Potvrdi
        </Button>
      </DialogActions>
      <Dialog
        open={loadingUpdateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
