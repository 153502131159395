import { gql } from "@apollo/client";

export interface ICulturalHeritageList {
  id: string;
  created: Date;
  duration: number;
  category: {
    locale: {
      name: string;
    }[];
  };
  published: boolean;
  media?: {
    path: string;
  };
  locale: {
    title: string;
  }[];
}

export interface ICulturalHeritageListData {
  culturalHeritages: ICulturalHeritageList[];
}

export interface ICulturalHeritageListVars {
  filter: any;
}

export interface ICulturalHeritageVars {
  id: number;
}

export const ALL_CULTURAL_HERITAGE_LIST = gql`
  query culturalHeritages($filter: FindManyCulturalHeritageArgs) {
    culturalHeritages(filter: $filter) {
      id
      duration
      published
      media {
        path
      }
      category {
        locale {
          name
        }
      }
      locale(lang: "en-GB") {
        title
      }
      created
    }
  }
`;

export interface ICulturalHeritage {
  id: string;
  duration: number;
  published: boolean;
  category: {
    id: string;
  };
  media?: {
    id: string;
    path: string;
    extension: string;
    type: string;
    name: string;
    size: number;
  };
  gallery: {
    id: string;
    path: string;
    extension: string;
    type: string;
    name: string;
    size: number;
  }[];
  locale: {
    id: string;
    title: string;
    description: string;
    audioText: string;
    audio?: {
      id: string;
      path: string;
      extension: string;
      type: string;
      name: string;
      size: number;
    };
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface ICulturalHeritageData {
  culturalHeritage: ICulturalHeritage;
}

export interface ICulturalHeritageVars {
  id: number;
}

export const ONE_CULTURAL_HERITAGE = gql`
  query culturalHeritage($id: ID!) {
    culturalHeritage(id: $id) {
      id
      duration
      published
      media {
        id
        path
        type
        extension
        name
        size
      }
      gallery {
        id
        path
        type
        extension
        name
        size
      }
      category {
        id
      }
      locale {
        id
        title
        description
        audio {
          id
          path
          type
          extension
          name
          size
        }
        audioText
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
