import { gql } from "@apollo/client";

export interface IDeleteMedia {
  id: string;
  name: string;
}

export interface IDeleteMediaData {
  deleteMedia: IDeleteMedia;
}

export interface IDeleteMediaVars {
  path: string;
}

export const DELETE_MEDIA = gql`
  mutation deleteMedia($path: String!) {
    deleteMedia(path: $path) {
      id
      name
    }
  }
`;
