import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
  },
  image: {
    transform: "scale(1)",
    transition: "all 0.3s ease-in-out",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  uploadAreaIcon: {
    fontSize: 64,
    zIndex: 2,
    opacity: 0.5,
  },
  hidden: {
    visibility: "hidden",
  },
  uploadButton: {
    padding: 8,
    width: "100%",
    minHeight: "160px",
    position: "relative",
    transition: "all 0.3s ease-in-out",
    border: "2px dashed",
    borderColor: theme.palette.grey[400],
    borderRadius: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  galleryButton: {
    padding: 8,
    width: 164,
    height: 164,
    position: "relative",
    transition: "all 0.3s ease-in-out",
    border: "2px dashed",
    borderColor: theme.palette.grey[400],
    borderRadius: 32,
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  uploadButtonActive: {
    transition: "all 0.3s ease-in-out",
    borderColor: theme.palette.secondary.main,
  },
  uploadButtonStartActive: {
    border: "2px dashed",
    transition: "all 0.3s ease-in-out",
    borderColor: theme.palette.secondary.main,
  },
  inputLabel: {
    position: "absolute",
    top: -4,
    left: 0,
    backgroundColor: "white",
    padding: "2px 4px",
    borderRadius: 8,
  },
  error: {
    borderColor: theme.palette.error.main,
  },
}));
