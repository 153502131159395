import { disconnect } from "process";
import { gql } from "@apollo/client";
import { IStation } from "../queries";

export interface IConnectCulturalHeritageStationVars {
  data: {
    station: { connect: { id: number } };
    culturalHeritage: { connect: { id: number } };
  };
}

export interface IConnectCulturalHeritageStationData {
  connectCulturalHeritageStation: ICreateCulturalHeritageStation;
}

export const CONNECT_CULTURAL_HERITAGE_STATION = gql`
  mutation connectCulturalHeritageStation(
    $data: CulturalHeritageStationCreateInput!
  ) {
    connectCulturalHeritageStation(data: $data) {
      id
      station {
        locale(lang: "en-GB") {
          title
        }
      }
    }
  }
`;
export interface ICreateCulturalHeritageStation {
  id: string;
  station: {
    id: string;
    locale: {
      // id: string;
      title: string;
      localeFlag: {
        isoLanguageCode: string;
      };
    }[];
  };
}

export interface ICreateCulturalHeritageStationVars {
  data: {
    culturalHeritage: { connect: { id: number } };
    station: {
      create: {
        published: boolean;
        gallery: string[];
        category?: { connect: { id: number } } | { disconnect: true };
        longitude?: string;
        latitude?: string;
      };
    };
  };
  localeData: {
    description: string;
    title: string;
    localeFlag: { connect: { id: number } };
    audio?: { connect: { path: string } } | { disconnect: true };
    audioText?: string;
  }[];
}

export interface ICreateCulturalHeritageStationData {
  createCulturalHeritageStation: ICreateCulturalHeritageStation;
}

export const CREATE_CULTURAL_HERITAGE_STATION = gql`
  mutation createCulturalHeritageStation(
    $data: CulturalHeritageStationCreateInput!
    $localeData: [StationLocaleCreateInput]!
  ) {
    createCulturalHeritageStation(data: $data, localeData: $localeData) {
      id
      station {
        locale(lang: "en-GB") {
          title
          localeFlag {
            isoLanguageCode
          }
        }
      }
    }
  }
`;
