import "./fonts/fonts.css";
import ReactDOM from "react-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo";
import { theme } from "./theme";
import App from "./App";
import { StrictMode } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

ReactDOM.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <SnackbarProvider maxSnack={6}>
              <App />
            </SnackbarProvider>
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById("root")
);
