import { FC } from "react";
import { useSlate } from "slate-react";
import { Editor } from "slate";
import { Button } from "@mui/material";
import { TFormat } from "../../../utils";

interface IProps {
  format: TFormat;
  icon: any;
}

export const MarkButton: FC<IProps> = ({ format, icon }) => {
  const editor = useSlate();

  const toggleMark = (editor: Editor, format: TFormat) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isMarkActive = (editor: Editor, format: TFormat) => {
    const marks = Editor.marks(editor);
    //@ts-ignore
    return marks ? marks[format] === true : false;
  };

  return (
    <Button
      color={isMarkActive(editor, format) ? "primary" : "inherit"}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};
