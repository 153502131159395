import { useEffect, useState } from "react";
import { MainRouter } from "./routes/Router";
import { LoadingComponent } from "./components";
import { ContextProvider, setAccessToken } from "./utils";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ALL_LOCALE_FLAGS,
  ILocaleFlag,
  ILocaleFlagsData,
  ILocaleFlagsVars,
} from "./apollo/queries";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

//*ENABLE FOR ACCESSABILITY CHECKING

// import ReactDOM from "react-dom";
// if (process.env.NODE_ENV !== "production") {
//   var axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

const App = () => {
  const [loadingToken, setLoadingToken] = useState(true);
  const [localeFlags, setLocaleFlags] = useState<ILocaleFlag[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [getFlagsQuery, { data, loading, error, called }] = useLazyQuery<
    ILocaleFlagsData,
    ILocaleFlagsVars
  >(ALL_LOCALE_FLAGS, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
        orderBy: {
          default: "desc",
        },
      },
    },
    onError: (err) => {
      // console.error(err);
    },
  });

  useEffect(() => {
    if (!loadingToken && !called) {
      getFlagsQuery();
    }
  }, [loadingToken, getFlagsQuery, called]);

  useEffect(() => {
    if (data?.localeFlags.length) {
      setLocaleFlags(data.localeFlags);
    }
  }, [data]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT_REFRESH}`, {
      credentials: "include",
      method: "POST",
    })
      .then(async (x) => {
        // console.log("test");
        const { accessToken } = await x.json();
        setAccessToken(accessToken);
        setLoadingToken(false);
      })
      .catch((err) => {
        if (err instanceof Error) {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingToken || loading || !called) {
    return <LoadingComponent />;
  }

  const contextValue = {
    localeFlags,
    setLocaleFlags,
  };

  return (
    <ContextProvider.Provider value={contextValue}>
      <Router>
        <MainRouter />
      </Router>
    </ContextProvider.Provider>
  );
};

export default App;
