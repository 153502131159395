import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../../../components";
import {
  DELETE_STATION,
  IDeleteStationData,
  IDeleteStationVars,
} from "../../../../apollo/mutations";

interface IProps {
  onClose: () => void;
  id: string | undefined;
}

export const DeleteStationDialog: FC<IProps> = (props) => {
  const { onClose, id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [deleteStation, { loading: loadingMutation }] = useMutation<
    IDeleteStationData,
    IDeleteStationVars
  >(DELETE_STATION, {
    onCompleted: () => {
      enqueueSnackbar("Stavka uspješno obrisana!", { variant: "success" });
      onClose();
    },
    onError: (error) => {
      // console.log("Error: ", { error });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      // console.log(data);
      // cache.modify({
      //   fields: {
      //     persons(existingAdmins: Array<Reference>, { readField }) {
      //       if (data) {
      //         return existingAdmins.filter(
      //           (taskRef) => data.deleteAdmin.id !== readField("id", taskRef)
      //         );
      //       }
      //     },
      //   },
      // });
    },
  });

  const onDeleteStation = () => {
    if (id) {
      deleteStation({
        variables: { id: +id },
      });
    } else {
      enqueueSnackbar("No ID found!", { variant: "error" });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Delete station</DialogTitle>
      <DialogContent>
        <Typography>This action cannot be undone!</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDeleteStation}>
          Delete
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingMutation} />
    </Fragment>
  );
};
