import {
  Dispatch,
  FC,
  Fragment,
  ReactChild,
  ReactChildren,
  SetStateAction,
} from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import { useStyles } from "./PageLayout.styles";
import { LocaleSelect } from "..";
import { Undo as UndoIcon } from "@mui/icons-material";
import { useHistory } from "react-router";

interface IProps {
  title: string;
  children: ReactChildren | ReactChild | JSX.Element[] | JSX.Element;
  localeSelect?: {
    selected: string;
    setSelected: Dispatch<SetStateAction<string>>;
  };
  subRoute?: string[];
}

export const PageLayout: FC<IProps> = (props) => {
  const { title, children, localeSelect, subRoute } = props;
  const history = useHistory();
  const styles = useStyles();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <main className={styles.root}>
      <Typography variant="h3" color="primary">
        {title}
      </Typography>
      <Divider className={styles.divider} />

      {subRoute ? (
        <div className={styles.subRouteDiv}>
          <IconButton
            size="small"
            className={styles.subRoute}
            onClick={handleGoBack}
          >
            <UndoIcon color="primary" fontSize="large" />
          </IconButton>
          {subRoute.map((item, i, array) => {
            if (array.length === i + 1) {
              return (
                <Typography
                  key={i}
                  color="primary"
                  variant="h4"
                  display="inline"
                >
                  {item}
                </Typography>
              );
            } else
              return (
                <Fragment key={i}>
                  <Typography color="primary" display="inline" variant="h4">
                    {item}
                  </Typography>
                  <Typography
                    className={styles.subRoute}
                    color="primary"
                    display="inline"
                    variant="h4"
                  >
                    {">"}
                  </Typography>
                </Fragment>
              );
          })}
        </div>
      ) : null}
      {localeSelect && (
        <LocaleSelect
          selected={localeSelect.selected}
          onSetSelected={localeSelect.setSelected}
        />
      )}
      <div
        className={`${styles.container} ${
          localeSelect ? styles.bottomMarginLarge : styles.bottomMarginSmall
        }`}
      >
        {children}
      </div>
    </main>
  );
};
