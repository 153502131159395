import { gql } from "@apollo/client";

export interface INotification {
  id: string;
  created: Date;
  locale: {
    id: string;
    title: string;
    body: string;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface INotificationsData {
  notifications: INotification[];
}

export interface INotificationVars {
  filter: any;
}

export const ALL_NOTIFICATIONS = gql`
  query notifications($filter: FindManyNotificationArgs) {
    notifications(filter: $filter) {
      id
      created
      locale {
        id
        title
        body
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
