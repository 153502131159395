import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  paper: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px 64px",
  },
  image: {
    width: "250px",
    marginBottom: "32px",
  },
  inputField: {
    marginBottom: "16px",
  },
}));
