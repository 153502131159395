import { gql } from "@apollo/client";

export interface IDeleteCulturalHeritageVars {
  id: number;
}

export interface IDeleteCulturalHeritage {
  id: string;
}

export interface IDeleteCulturalHeritageData {
  deleteCulturalHeritage: IDeleteCulturalHeritage;
}

export const DELETE_CULTURAL_HERITAGE = gql`
  mutation deleteCulturalHeritage($id: ID!) {
    deleteCulturalHeritage(id: $id) {
      id
    }
  }
`;
