import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    borderColor: theme.palette.grey[500],
    borderStyle: "dashed",
    borderWidth: 2,
    width: 600,
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  buttonDiv: {},
}));
