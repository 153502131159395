import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { Reference, useMutation } from "@apollo/client";
import {
  IDeleteCulturalHeritageData,
  IDeleteCulturalHeritageVars,
  DELETE_CULTURAL_HERITAGE,
} from "../../../../apollo/mutations";
import { LoadingBackdrop } from "../../../../components";

interface IProps {
  onClose: () => void;
  culturalHeritageId: string | undefined;
}
export const DeleteCulturalHeritageDialog: FC<IProps> = (props) => {
  const { onClose, culturalHeritageId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCulturalHeritage, { loading: mutationLoading }] = useMutation<
    IDeleteCulturalHeritageData,
    IDeleteCulturalHeritageVars
  >(DELETE_CULTURAL_HERITAGE, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted cultural heritage!", {
        variant: "success",
      });
      onClose();
    },
    onError: () => {
      // console.log("Error: ", { error });
      enqueueSnackbar("Failed to delete cultural heritage, try again later!", {
        variant: "error",
      });
    },
    update(cache, { data }) {
      // console.log(data);
      cache.modify({
        fields: {
          culturalHeritages(existingData: Array<Reference>, { readField }) {
            if (data) {
              return existingData.filter(
                (taskRef) =>
                  data.deleteCulturalHeritage.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const onDeleteCulturalHeritage = (): void => {
    if (culturalHeritageId) {
      deleteCulturalHeritage({
        variables: { id: +culturalHeritageId },
      });
    } else {
      enqueueSnackbar("Cannot find ID, try again later!", { variant: "error" });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Delete cultural heritage</DialogTitle>
      <DialogContent>This action cannot be undone!</DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onDeleteCulturalHeritage}
        >
          Delete
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={mutationLoading} />
    </Fragment>
  );
};
