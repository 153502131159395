import { FC, Fragment, useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  DialogActions,
  Dialog,
  MenuItem,
  Stack,
} from "@mui/material";
import { useStyles } from "./UpsertOpeningHourDialog.styles";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../utils";
import { useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertOpeningHourDialog.inputs";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
} from "../../../../components";
import {
  IUpsertOpenHourData,
  IUpsertOpenHourVars,
  UPSERT_OPENING_HOUR,
} from "../../../../apollo/mutations";
import { TimePicker } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { differenceInMilliseconds, format, isDate } from "date-fns";
import {
  ALL_OPENING_HOURS,
  IOpeningHour,
  IOpeningHoursData,
} from "../../../../apollo/queries";

interface IProps {
  item: undefined | IOpeningHour;
  onClose: () => void;
}

export const UpsertOpeningHoursDialog: FC<IProps> = (props) => {
  const { onClose, item } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { setInputFields, inputFields, inputProps, setInputField, formReady } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [startHourValue, setStartHourValue] = useState<Date | null>(null);
  const [endHourValue, setEndHourValue] = useState<Date | null>(null);

  const handleChangeStart = (value: Date | null) => {
    setStartHourValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = format(value, "HH:mm");
        if (formatedTime) {
          setInputField("startHour", formatedTime);
        } else {
          setInputField("startHour", "");
        }
      } else setInputField("startHour", "");
    } catch (err) {
      // console.log(err);
    }
  };

  const handleChangeEnd = (value: Date | null) => {
    setEndHourValue(value);
    try {
      if (value && isDate(value)) {
        const formatedTime = format(value, "HH:mm");
        if (formatedTime) {
          setInputField("endHour", formatedTime);
        } else {
          setInputField("endHour", "");
        }
      } else setInputField("endHour", "");
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (item && formReady) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "weekday",
          toFormProperty: "weekday",
        },
        {
          fromDataProperty: "startHour",
          toFormProperty: "startHour",
        },
        {
          fromDataProperty: "endHour",
          toFormProperty: "endHour",
        },
      ];

      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(item, valueToFormOptions, inputFields);
      // console.log("Values to update: ", getUpdatedInputs);
      setInputFields(getUpdatedInputs);
      setStartHourValue(new Date(`01/01/1970 ${item.startHour}`));
      setEndHourValue(new Date(`01/01/1970 ${item.endHour}`));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, formReady]);

  const [upsertOpeningHour, { loading: loadingUpsertOpeningHour }] =
    useMutation<IUpsertOpenHourData, IUpsertOpenHourVars>(UPSERT_OPENING_HOUR, {
      onCompleted: (data) => {
        // console.log("Create data: ", data);
        enqueueSnackbar("Successfully added opening hour!", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        // console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        if (
          data &&
          differenceInMilliseconds(
            new Date(data.upsertOpeningHour.updated),
            new Date(data.upsertOpeningHour.created)
          ) > 50
        ) {
          return null;
        }

        const existingOpeningHours: IOpeningHoursData | null = cache.readQuery({
          query: ALL_OPENING_HOURS,
          variables: {
            filter: {
              where: {
                expired: null,
              },
              orderBy: {
                weekday: "asc",
              },
            },
          },
        });
        if (data?.upsertOpeningHour) {
          const newOpeningHour: IOpeningHour = {
            ...data.upsertOpeningHour,
          };

          cache.writeQuery({
            query: ALL_OPENING_HOURS,
            variables: {
              filter: {
                where: {
                  expired: null,
                },
                orderBy: {
                  weekday: "asc",
                },
              },
            },
            data: {
              openingHours: existingOpeningHours?.openingHours
                ? [...existingOpeningHours.openingHours, newOpeningHour].sort(
                    (a, b) => a.weekday - b.weekday
                  )
                : [newOpeningHour],
            },
          });
        }
      },
    });

  const handleUpsertOpeningHour = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      upsertOpeningHour({
        variables: {
          id: item?.id ? +item.id : undefined,
          createData: {
            weekday: +inputFields.weekday.value.unlocalised.value,
            startHour: inputFields.startHour.value.unlocalised.value,
            endHour: inputFields.endHour.value.unlocalised.value,
          },
          updateData: {
            weekday: +inputFields.weekday.value.unlocalised.value,
            startHour: inputFields.startHour.value.unlocalised.value,
            endHour: inputFields.endHour.value.unlocalised.value,
          },
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  // console.log("IF: ", inputFields);

  return (
    <Fragment>
      <DialogTitle color="primary">
        {item?.id ? "Update" : "Add"} opening hour
      </DialogTitle>
      {false || !formReady ? (
        <LoadingComponent small />
      ) : false ? (
        <ErrorComponent small />
      ) : (
        <DialogContent>
          <Stack spacing={2} className={styles.stack}>
            <TextField
              variant="outlined"
              select
              {...inputProps("weekday")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
            >
              [<MenuItem value={""}>-- Select Weekday --</MenuItem>
              <MenuItem value={"1"}>Monday</MenuItem>
              <MenuItem value={"2"}>Tuesday</MenuItem>
              <MenuItem value={"3"}>Wednesday</MenuItem>
              <MenuItem value={"4"}>Thursday</MenuItem>
              <MenuItem value={"5"}>Friday</MenuItem>
              <MenuItem value={"6"}>Saturday</MenuItem>
              <MenuItem value={"7"}>Sunday</MenuItem>]
            </TextField>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Start Time"
                value={startHourValue}
                onChange={handleChangeStart}
                clearable
                minutesStep={15}
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputFields.startHour.error}
                    fullWidth
                    variant="outlined"
                    required
                  />
                )}
              />
              <TimePicker
                label="End Time"
                value={endHourValue}
                onChange={handleChangeEnd}
                clearable
                minutesStep={15}
                ampm={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputFields.endHour.error}
                    fullWidth
                    variant="outlined"
                    required
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>

          <DialogActions>
            <Button variant="text" color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpsertOpeningHour}
            >
              {item?.id ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </DialogContent>
      )}
      <LoadingBackdrop loading={loadingUpsertOpeningHour} />
    </Fragment>
  );
};
