import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Reference, useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../../../components";
import {
  DELETE_CULTURAL_HERITAGE_STATION,
  IDeleteCulturalHeritageStationData,
  IDeleteCulturalHeritageStationVars,
} from "../../../../apollo/mutations";

interface IProps {
  onClose: () => void;
  id: string | undefined;
}

export const DisconnectStationDialog: FC<IProps> = (props) => {
  const { onClose, id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCulturalHeritageStation, { loading: loadingMutation }] =
    useMutation<
      IDeleteCulturalHeritageStationData,
      IDeleteCulturalHeritageStationVars
    >(DELETE_CULTURAL_HERITAGE_STATION, {
      onCompleted: () => {
        enqueueSnackbar("Stavka uspješno obrisana!", { variant: "success" });
        onClose();
      },
      onError: (error) => {
        // console.log("Error: ", { error });
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      update(cache, { data }) {
        // console.log(data);
        cache.modify({
          fields: {
            culturalHeritageStations(
              existingData: Array<Reference>,
              { readField }
            ) {
              if (data) {
                return existingData.filter(
                  (taskRef) =>
                    data.deleteCulturalHeritageStation.id !==
                    readField("id", taskRef)
                );
              }
            },
          },
        });
      },
    });

  const onDisconnectStation = () => {
    // console.log(id);
    if (id) {
      deleteCulturalHeritageStation({
        variables: { id: +id },
      });
    } else {
      enqueueSnackbar("No ID found!", { variant: "error" });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Disconnect station</DialogTitle>
      <DialogContent>
        <Typography>This action cannot be undone!</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDisconnectStation}>
          Disconnect
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingMutation} />
    </Fragment>
  );
};
