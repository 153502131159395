import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useStyles } from "./PrivacyPolicyScreen.styles";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
  PageLayout,
} from "../../components";
import { Button, Paper } from "@mui/material";
import { RichTextEditor } from "../../components/RichTextEditor";
import { useSnackbar } from "notistack";
import { initialInputData } from "./PrivacyPolicyScreen.input";
import {
  useForm,
  validateForm,
  withLinks,
  TValueToFormOptions,
  getFormValuesFromFetchedData,
} from "../../utils";
import { ReactEditor, withReact } from "slate-react";
import { createEditor } from "slate";
import { useMutation, useQuery } from "@apollo/client";
import { withHistory } from "slate-history";
import {
  ITermsOfServiceData,
  ITermsOfServiceVars,
  ONE_TERMS_OF_SERVICE,
} from "../../apollo/queries";
import {
  IUpdateTermsOfServiceData,
  IUpdateTermsOfServiceVars,
  UPDATE_TERMS_OF_SERVICE,
} from "../../apollo/mutations";
// import { ContextProvider } from "../../utils";

export const PrivacyPolicyScreen: FC = () => {
  const styles = useStyles();
  // const { localeFlags } = useContext(ContextProvider);
  const { enqueueSnackbar } = useSnackbar();

  const [localeSelected, setLocaleSelected] = useState("");

  const localeSelectProps = {
    selected: localeSelected,
    setSelected: setLocaleSelected,
  };

  const { inputFields, setInputFields, inputProps, formReady } = useForm<
    keyof typeof initialInputData
  >(initialInputData, localeSelected);

  const editorRef = useRef(null);
  if (!editorRef.current)
    //@ts-ignore
    editorRef.current = withLinks(withHistory(withReact(createEditor())));
  const editor = editorRef.current;

  const { loading, error, data } = useQuery<
    ITermsOfServiceData,
    ITermsOfServiceVars
  >(ONE_TERMS_OF_SERVICE, {
    variables: {
      type: "PrivacyPolicy",
    },
    onError: () => {
      enqueueSnackbar("Failed to fetch data, try again later!", {
        variant: "error",
      });
    },
  });

  const [updateTermsOfService, { loading: loadingUpdateMutation }] =
    useMutation<IUpdateTermsOfServiceData, IUpdateTermsOfServiceVars>(
      UPDATE_TERMS_OF_SERVICE,
      {
        onCompleted: (data) => {
          // console.log("Update data: ", data);
          enqueueSnackbar(`Privacy policy have been updated.`, {
            variant: "success",
          });
        },
        onError: () => {
          // console.error({ err });
          enqueueSnackbar("Failed to update, try again later!", {
            variant: "error",
          });
        },
      }
    );

  useEffect(
    () => {
      if (data?.termsOfService?.locale?.length && formReady) {
        const valueToFormOptions: TValueToFormOptions = [
          {
            fromDataProperty: "locale.content",
            toFormProperty: "content",
          },
        ];
        const getUpdatedInputs = getFormValuesFromFetchedData<
          keyof typeof initialInputData
        >(data.termsOfService, valueToFormOptions, inputFields);
        // console.log("Values to update: ", getUpdatedInputs);
        setInputFields(getUpdatedInputs);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, formReady]
  );

  // const handleEditAllInformation = () => {
  //   const fields = ["content"];
  //   const result = validateForm<keyof typeof initialInputData>(
  //     fields,
  //     inputFields,
  //     "allLocales",
  //     localeSelected
  //   );
  //   if (result.formValid && data) {
  //     localeFlags.forEach((item) => {
  //       const getLocaleId = data.termsOfService.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       const getFlagId = data.termsOfService.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       if (getLocaleId && getFlagId) {
  //         updateTermsOfService({
  //           variables: {
  //             id: +data.termsOfService.id,
  //             localeId: +getLocaleId,
  //             data: {
  //               content: inputFields.content.value[item.isoLanguageCode].value,
  //               localeFlag: {
  //                 connect: {
  //                   id: +getFlagId,
  //                 },
  //               },
  //             },
  //             lang: item.isoLanguageCode,
  //           },
  //         });
  //       } else {
  //         enqueueSnackbar("Something went wrong, try again later!", {
  //           variant: "error",
  //         });
  //       }
  //     });
  //   } else {
  //     setInputFields(result.outputData);
  //   }
  // };

  const handleEditInformation = () => {
    const fields = ["content"];
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      localeSelected
    );
    if (result.formValid && data) {
      const getLocaleId = data.termsOfService.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      const getFlagId = data.termsOfService.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      if (getLocaleId && getFlagId) {
        updateTermsOfService({
          variables: {
            id: +data.termsOfService.id,
            localeId: +getLocaleId,
            data: {
              content: inputFields.content.value[localeSelected].value,
              localeFlag: {
                connect: {
                  id: +getFlagId,
                },
              },
            },
            lang: localeSelected,
          },
        });
      } else {
        enqueueSnackbar("Something went wrong, try again later!", {
          variant: "error",
        });
      }
    } else {
      setInputFields(result.outputData);
    }
  };

  return (
    <PageLayout title="Privacy Policy" localeSelect={localeSelectProps}>
      <Fragment>
        {loading || !formReady ? (
          <LoadingComponent />
        ) : error ? (
          <ErrorComponent />
        ) : (
          <Paper className={styles.paper}>
            <RichTextEditor
              editor={editor as unknown as ReactEditor}
              {...inputProps("content", editor)}
            />
            <div>
              <Button
                onClick={handleEditInformation}
                className={styles.button}
                variant="contained"
              >
                Save {localeSelected}
              </Button>
              {/* <Button
                onClick={handleEditAllInformation}
                className={styles.button}
                variant="contained"
              >
                Save All
              </Button> */}
            </div>
          </Paper>
        )}
        <LoadingBackdrop loading={loadingUpdateMutation} />
      </Fragment>
    </PageLayout>
  );
};
