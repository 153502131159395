import { FC, Fragment, useState } from "react";
import { useStyles } from "./AdminsScreen.styles";
import {
  ErrorComponent,
  LoadingComponent,
  PageLayout,
  SearchBar,
} from "../../components";
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { ALL_ADMINS, IAdminsData, IAdminsVars } from "../../apollo/queries";
import {
  AddAdminDialog,
  DeleteAdminDialog,
  EditAdminDialog,
} from "./components";
import { useSnackbar } from "notistack";

export const AdminsScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [searchQuery, setSearchQuery] = useState("");
  const [modal, setModal] = useState<{
    id: string | null;
    type: null | "Delete" | "Add" | "Edit";
  }>({
    id: null,
    type: null,
  });

  const handleCloseDialog = () => {
    setModal({ id: null, type: null });
  };

  const handleSearchQuery = (value: string): void => {
    setSearchQuery(value);
  };

  const handleSetAddModal = () => {
    setModal({ id: null, type: "Add" });
  };

  const { loading, error, data } = useQuery<IAdminsData, IAdminsVars>(
    ALL_ADMINS,
    {
      variables: {
        filter: {
          where: {
            OR: searchQuery
              ? [
                  {
                    email: { contains: searchQuery, mode: "insensitive" },
                  },
                  {
                    name: { contains: searchQuery, mode: "insensitive" },
                  },
                  {
                    phone: { contains: searchQuery, mode: "insensitive" },
                  },
                ]
              : undefined,
            expired: null,
            role: {
              access: {
                not: 1,
              },
            },
          },
          orderBy: {
            created: "desc",
          },
        },
      },
      onError: () => {
        enqueueSnackbar("Failed to fetch data, try again later!", {
          variant: "error",
        });
      },
    }
  );

  return (
    <PageLayout title="Administrators">
      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorComponent />
      ) : (
        <Fragment>
          <div className={styles.actionButtons}>
            <Button
              color="primary"
              className={styles.editButton}
              startIcon={<AddIcon />}
              onClick={handleSetAddModal}
            >
              Add new
            </Button>
            <SearchBar
              className={styles.searchBar}
              activeSearch={searchQuery}
              onSearch={handleSearchQuery}
            />
          </div>
          <TableContainer component={Paper} className={styles.paper}>
            <Table size="small" className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>EMAIL</TableCell>
                  <TableCell>CONTACT</TableCell>
                  <TableCell>TITLE</TableCell>
                  <TableCell width={160}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.persons.length ? (
                  data.persons.map((item) => {
                    const handleSetDeleteModal = () => {
                      setModal({ id: item.id, type: "Delete" });
                    };
                    const handleSetEditModal = () => {
                      setModal({ id: item.id, type: "Edit" });
                    };

                    return (
                      <TableRow
                        key={item.id}
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {item.name}
                        </TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.phone}</TableCell>
                        <TableCell>{item.role.name}</TableCell>
                        <TableCell>
                          <IconButton onClick={handleSetEditModal}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={handleSetDeleteModal}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>Trenutno nema administratora</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={modal.type === "Add"} onClose={handleCloseDialog}>
            <AddAdminDialog
              searchQuery={searchQuery}
              onClose={handleCloseDialog}
            />
          </Dialog>

          <Dialog
            open={Boolean(modal.type === "Edit" && modal.id)}
            onClose={handleCloseDialog}
          >
            <EditAdminDialog onClose={handleCloseDialog} adminId={modal.id!} />
          </Dialog>
          <Dialog
            open={Boolean(modal.type === "Delete" && modal.id)}
            onClose={handleCloseDialog}
          >
            <DeleteAdminDialog
              onClose={handleCloseDialog}
              adminId={modal.id!}
            />
          </Dialog>
        </Fragment>
      )}
    </PageLayout>
  );
};
