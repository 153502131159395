import { gql } from "@apollo/client";

export interface ILoginData {
  login: {
    token: string;
  };
}

export interface ILoginVars {
  data: {
    email: string;
    password: string;
  };
}

export const LOGIN = gql`
  mutation login($data: LoginInput) {
    login(data: $data) {
      token
    }
  }
`;
