import { gql } from "@apollo/client";

export interface IUpdateTermsOfServiceVars {
  id: number;
  localeId: number;
  data: {
    content: string;
    localeFlag: { connect: { id: number } };
  };
  lang: string;
}

export interface IUpdateTermsOfService {
  id: string;
  locale: {
    id: string;
    content: string;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
  created: Date;
}

export interface IUpdateTermsOfServiceData {
  updateTermsOfService: IUpdateTermsOfService;
}

export const UPDATE_TERMS_OF_SERVICE = gql`
  mutation updateTermsOfService(
    $id: ID!
    $localeId: ID!
    $data: TermsOfServiceLocaleUpdateInput
    $lang: String
  ) {
    updateTermsOfService(id: $id, localeId: $localeId, data: $data) {
      id
      locale(lang: $lang) {
        content
        id
        localeFlag {
          isoLanguageCode
        }
      }
      created
    }
  }
`;
