import { Button, Tooltip } from "@mui/material";
import { useStyles } from "./UploadedMediaFile.styles";
import { FC, Fragment } from "react";
import {
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import bytes from "bytes";
import fileDownload from "js-file-download";
import { IUploadedFile } from "../../";

interface IProps {
  data: IUploadedFile;
  style: any;
  onRemove: (item: IUploadedFile) => void;
  onPreview: (item: IUploadedFile) => void;
  download: boolean;
}

export const UploadedMediaFile: FC<IProps> = (props) => {
  const { name, path, blob, type, size } = props.data;
  const { data, style, download, onPreview, onRemove } = props;

  const styles = useStyles();

  const handleRemove = () => {
    if (path) {
      // console.log("Remove: ", data);
      onRemove(data);
    } else {
      // console.error("Cannot delete removed image!");
    }
  };

  const handlePreview = () => {
    onPreview(data!);
  };

  const handleDownload = async () => {
    const fetchedBlob = await fetch(
      `${process.env.REACT_APP_API_BUCKET}${path}`,
      {
        method: "GET",
      }
    );

    const resolvedBlob = await fetchedBlob.blob();

    fileDownload(resolvedBlob, name);
  };

  return (
    <Tooltip title={name ? `${name} - ${bytes(size)}` : "Image removed"}>
      <div style={style} className={styles.root}>
        <Fragment>
          {type === "audio/flac" || type === "audio/mpeg" ? (
            <audio controls className={styles.audio}>
              <source
                src={blob ? blob : `${process.env.REACT_APP_API_BUCKET}${path}`}
                type={type}
              />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <img
              src={blob ? blob : `${process.env.REACT_APP_API_BUCKET}${path}`}
              alt=""
              className={styles.image}
            />
          )}
          <div className={styles.overlay}>
            <Button
              onClick={handlePreview}
              className={styles.button}
              color="primary"
            >
              <VisibilityIcon />
            </Button>
            {!download ? (
              <Button
                onClick={handleRemove}
                className={styles.button}
                color="primary"
              >
                <DeleteIcon />
              </Button>
            ) : (
              <Button
                onClick={handleDownload}
                className={styles.button}
                color="primary"
              >
                <CloudDownloadIcon />
              </Button>
            )}
          </div>
        </Fragment>
      </div>
    </Tooltip>
  );
};
