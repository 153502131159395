import dcopy from "deep-copy";
import { useContext, useEffect, useState } from "react";
import {
  ContextProvider,
  DynamicFormObject,
  localizeInputValues,
  rteSerialize,
} from "..";
import { IUploadedFile } from "../../components";
interface IInputProps {
  value: any;
  label: string;
  type?: "number" | "text" | "password" | "email" | "hidden";
  required?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (event: any) => void;
  onBlur?: () => void;
}

export const useForm = <T extends string | number | symbol>(
  initialInputData: Record<T, DynamicFormObject>,
  locale?: string
) => {
  const { localeFlags } = useContext(ContextProvider);
  const [inputFields, setInputFields] = useState(initialInputData);
  const [formReady, setFormReady] = useState(false);

  useEffect(() => {
    if (localeFlags.length) {
      const localisedInput = localizeInputValues<keyof typeof initialInputData>(
        initialInputData,
        localeFlags
      );
      if (localisedInput) {
        setInputFields(localisedInput);
      }
      setFormReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeFlags]);

  const resetFields = () => {
    if (localeFlags.length) {
      const localisedInput = localizeInputValues<keyof typeof initialInputData>(
        initialInputData,
        localeFlags
      );
      if (localisedInput) {
        setInputFields(localisedInput);
      }
    } else {
      setInputFields(initialInputData);
    }
  };

  const setInputField = (inputKey: T, value: any, locale?: string): void => {
    console.log(value);
    setInputFields((prevState) => {
      return {
        ...prevState,
        [inputKey]: {
          ...prevState[inputKey],
          error: false,
          value: {
            ...prevState[inputKey].value,
            //Locale or unlocalised
            [locale && inputFields[inputKey].localise ? locale : "unlocalised"]:
              {
                ...prevState[inputKey].value[
                  locale && inputFields[inputKey].localise
                    ? locale
                    : "unlocalised"
                ],
                //Slate
                [inputFields[inputKey].rules === "slateRte"
                  ? "rteValue"
                  : //Media
                  inputFields[inputKey].rules === "mediaUpload"
                  ? "media"
                  : //Other
                    "value"]: value,
              },
          },
        } as DynamicFormObject,
      };
    });
  };

  const inputProps = (inputKey: T, editor?: any): IInputProps => {
    // console.log(
    //   "InputProps: ",
    //   inputKey,
    //   locale,
    //   inputFields[inputKey].value[
    //     locale && inputFields[inputKey].localise ? locale : "unlocalised"
    //   ][inputFields[inputKey].rules === "slateRte" ? "rteValue" : "value"]
    // );
    const inputPropsObject: IInputProps = {
      value:
        inputFields[inputKey].value[
          locale && inputFields[inputKey].localise ? locale : "unlocalised"
        ][
          inputFields[inputKey].rules === "slateRte"
            ? "rteValue"
            : inputFields[inputKey].rules === "mediaUpload"
            ? "media"
            : "value"
        ],
      label: inputFields[inputKey].label,
      type: inputFields[inputKey].type,
      required: inputFields[inputKey].required ? true : false,
      error: inputFields[inputKey].error,
      helperText:
        inputFields[inputKey].error && inputFields[inputKey].value
          ? inputFields[inputKey].errorText
          : inputFields[inputKey].helperText,

      onChange: (event: any) => {
        const localeOrNot =
          locale && inputFields[inputKey].localise ? locale : "unlocalised";

        setInputFields((prevState) => {
          const inputFieldCopy = dcopy(prevState[inputKey]);
          inputFieldCopy.error = false;

          if (inputFields[inputKey].rules === "slateRte") {
            inputFieldCopy.value[localeOrNot].rteValue = event;
          } else if (inputFields[inputKey].rules === "mediaUpload") {
            if (
              !inputFieldCopy.value[localeOrNot].media ||
              !Array.isArray(inputFieldCopy.value[localeOrNot].media)
            ) {
              throw new Error("Tried to change Media but media is undefined!");
            }

            inputFieldCopy.value[localeOrNot].media = event;
            inputFieldCopy.value[localeOrNot].value = event.length
              ? event.map((item: IUploadedFile) => item.path).join(",")
              : "";
          } else {
            inputFieldCopy.value[localeOrNot].value = event.target.value;
          }

          return {
            ...prevState,
            [inputKey]: { ...inputFieldCopy },
          };
        });
      },
      onBlur:
        inputFields[inputKey].rules === "slateRte" && editor
          ? () => {
              // console.log("BLUR!");
              setInputFields((prevState) => {
                return {
                  ...prevState,
                  [inputKey]: {
                    ...prevState[inputKey],
                    error: false,
                    value: {
                      ...prevState[inputKey].value,
                      [locale && inputFields[inputKey].localise
                        ? locale
                        : "unlocalised"]: {
                        ...prevState[inputKey].value[
                          locale && inputFields[inputKey].localise
                            ? locale
                            : "unlocalised"
                        ],
                        value: rteSerialize(editor),
                      },
                    },
                  } as DynamicFormObject,
                };
              });
            }
          : undefined,
    };
    return inputPropsObject;
  };

  return {
    inputFields,
    setInputFields,
    inputProps,
    resetFields,
    setInputField,
    formReady,
  } as const;
};
// return {
//   ...prevState,
//   [inputKey]: {
//     ...prevState[inputKey],
//     error: false,
//     value: {
//       ...prevState[inputKey].value,
//       [localeOrNot]: {
//         ...prevState[inputKey].value[localeOrNot],
//         [inputFields[inputKey].rules === "slateRte"
//           ? "rteValue"
//           : inputFields[inputKey].rules === "mediaUpload"
//           ? "media"
//           : "value"]:
//           inputFields[inputKey].rules === "slateRte" ||
//           inputFields[inputKey].rules === "mediaUpload"
//             ? prevState[inputKey].value[localeOrNot].media?.length
//               ? [
//                   ...prevState[inputKey].value[localeOrNot].media!,
//                   event,
//                 ]
//               : [event]
//             : event.target.value,
//       },
//     },
//   } as DynamicFormObject,
// };
