import { FC, useEffect } from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./ErrorComponent.styles";
import { ApolloError } from "@apollo/client";

interface IProps {
  small?: boolean;
  error?: ApolloError;
}
export const ErrorComponent: FC<IProps> = (props) => {
  const { small, error } = props;
  const styles = useStyles();

  useEffect(() => {
    if (error) {
      // console.error({ error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={small ? styles.rootSmall : styles.root}>
      <Typography variant={small ? "h5" : "h3"}>Došlo je do greške!</Typography>
    </div>
  );
};
