import { gql } from "@apollo/client";

export interface IUpdatePersonPasswordVars {
  id: number;
  data: {
    password: string;
    passwordReset: string;
  };
}

export interface IUpdateAdminPassword {
  id: string;
}

export interface IUpdateAdminPasswordData {
  updateAdminPassword: IUpdateAdminPassword;
}

export const UPDATE_ADMIN_PASSWORD = gql`
  mutation updateAdminPassword($id: ID!, $data: PersonUpdatePasswordInput) {
    updateAdminPassword(id: $id, data: $data) {
      id
    }
  }
`;
