import { FC } from "react";
import { useStyles } from "./NotificationItem.styles";
import { Paper, Typography } from "@mui/material";
import { format } from "date-fns";

interface IProps {
  title: string;
  body: string;
  created: Date;
}

export const NotificationItem: FC<IProps> = (props) => {
  const { title, body, created } = props;
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <Typography variant="h6">{title}</Typography>
      <Typography>{body}</Typography>
      <Typography variant="caption">
        {format(new Date(created), "dd.MM.yyyy. HH:mm")}h
      </Typography>
    </Paper>
  );
};
