import { gql } from "@apollo/client";

export interface ILocaleFlag {
  id: string;
  isoLanguageCode: string;
  isoCountryCode: string;
  default: boolean;
}

export interface ILocaleFlagsData {
  localeFlags: ILocaleFlag[];
}

export interface ILocaleFlagsVars {
  filter: any;
}

export const ALL_LOCALE_FLAGS = gql`
  query localeFlags($filter: FindManyLocaleFlagArgs!) {
    localeFlags(filter: $filter) {
      id
      isoLanguageCode
      isoCountryCode
      default
    }
  }
`;
