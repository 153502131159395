import { gql } from "@apollo/client";

export interface IMedia {
  id: string;
  path: string;
  extension: string;
  size: number;
  type: string;
  name: string;
}

export interface IMediasData {
  medias: IMedia[];
}

export interface IMediasVars {
  filter: any;
}
export const ALL_MEDIAS = gql`
  query medias($filter: FindManyMediaArgs) {
    medias(filter: $filter) {
      id
      path
      extension
      size
      type
      name
    }
  }
`;
