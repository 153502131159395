import { gql } from "@apollo/client";

export interface IUpdateAboutUsGalleryVars {
  id: number;
  data: {
    gallery: string[];
  };
}

export interface IUpdateAboutUsGallery {
  id: string;
}

export interface IUpdateAboutUsGalleryData {
  updateAboutUsGallery: IUpdateAboutUsGallery;
}

export const UPDATE_ABOUT_US_GALLERY = gql`
  mutation updateAboutUsGallery($id: ID!, $data: OtherGalleryUpdateInput!) {
    updateAboutUsGallery(id: $id, data: $data) {
      id
      gallery {
        id
      }
    }
  }
`;
