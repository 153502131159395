import { Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useContext, useEffect } from "react";
import { ContextProvider } from "../../utils";
import { useStyles } from "./LocaleSelect.styles";

interface IProps {
  onSetSelected: Dispatch<SetStateAction<string>>;
  selected: Readonly<string>;
}

export const LocaleSelect: FC<IProps> = (props) => {
  const { localeFlags } = useContext(ContextProvider);
  const styles = useStyles();
  const { selected, onSetSelected } = props;

  useEffect(() => {
    if (localeFlags.length) {
      localeFlags.forEach((item) => {
        if (!selected && item.default) {
          onSetSelected(item.isoLanguageCode);
          return null;
        }
      });
    }
  });

  return (
    <div className={styles.root}>
      {localeFlags.length ? (
        localeFlags.map((item) => {
          const handleSetSelected = () => {
            onSetSelected(item.isoLanguageCode);
          };

          return (
            <img
              key={item.id}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.isoCountryCode}.svg`}
              alt={item.isoCountryCode}
              width={32}
              // height={24}
              className={`${styles.flag} ${
                selected === item.isoLanguageCode ? styles.selectedFlag : null
              }`}
              onClick={handleSetSelected}
            />
          );
        })
      ) : (
        <Typography>No languages found!</Typography>
      )}
    </div>
  );
};
