import { gql } from "@apollo/client";

export interface IUpdateCulturalHeritageVars {
  id: number;
  localeId: number;
  data: {
    duration: number;
    category: { connect: { id: number } };
    published: boolean;
    media?: { connect: { path: string } };
    gallery: string[];
  };
  localeData: {
    description: string;
    title: string;
    localeFlag: { connect: { id: number } };
    audio?: { connect: { path: string } } | { disconnect: boolean };
    audioText?: string;
  };
}

export interface IUpdateCulturalHeritage {
  id: string;
}

export interface IUpdateCulturalHeritageData {
  updateCulturalHeritage: IUpdateCulturalHeritage;
}

export const UPDATE_CULTURAL_HERITAGE = gql`
  mutation updateCulturalHeritage(
    $id: ID!
    $localeId: ID!
    $data: CulturalHeritageUpdateInput!
    $localeData: CulturalHeritageLocaleUpdateInput!
  ) {
    updateCulturalHeritage(
      id: $id
      localeId: $localeId
      data: $data
      localeData: $localeData
    ) {
      id
      published
      gallery {
        id
      }
      category {
        id
      }
      locale {
        id
        title
        audio {
          id
        }
      }
      media {
        id
      }
    }
  }
`;
