import { FC, Fragment, useState } from "react";
import { useStyles } from "./StationScreen.styles";
import { useHistory } from "react-router-dom";
import { ErrorComponent, LoadingComponent, PageLayout } from "../../components";
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";
import {
  ALL_STATIONS_LIST,
  IStationsListData,
  IStationsVars,
} from "../../apollo/queries";
import { Add as AddIcon } from "@mui/icons-material";
import { DeleteStationDialog, StationListItem } from "./components";
import { useRouteMatch } from "react-router";

interface IDialog {
  open: boolean;
  id: string | undefined;
}

export const StationScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [dialog, setDialog] = useState<IDialog>({
    open: false,
    id: undefined,
  });

  const handleCloseDialog = () => {
    setDialog({ open: false, id: undefined });
  };

  const { loading, error, data } = useQuery<IStationsListData, IStationsVars>(
    ALL_STATIONS_LIST,
    {
      variables: {
        filter: {
          where: {
            expired: null,
          },
          orderBy: {
            created: "desc",
          },
        },
      },
      onError: () => {
        enqueueSnackbar("Failed to fetch data!", { variant: "error" });
      },
    }
  );

  const handleNavigateToNewStation = () => {
    history.push(`${path}/new`);
  };

  return (
    <PageLayout title="Stations">
      {loading ? (
        <LoadingComponent />
      ) : error ? (
        <ErrorComponent error={error} />
      ) : (
        <Fragment>
          <div className={styles.actionButtons}>
            <Button
              color="primary"
              className={styles.editButton}
              startIcon={<AddIcon />}
              onClick={handleNavigateToNewStation}
            >
              Add new
            </Button>
          </div>
          <TableContainer component={Paper} className={styles.paper}>
            <Table size="small" className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>TITLE</TableCell>
                  <TableCell>CATEGORY</TableCell>
                  <TableCell width={160}>CREATED</TableCell>
                  <TableCell width={160}>IMAGE</TableCell>
                  <TableCell width={160}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.stations.length ? (
                  data.stations.map((item) => {
                    const handleNavigateToListItem = () => {
                      history.push(`${path}/${item.id}`);
                    };

                    const handleOpenDialog = () => {
                      setDialog({ open: true, id: item.id });
                    };

                    return (
                      <StationListItem
                        key={item.id}
                        item={item}
                        onClick={handleNavigateToListItem}
                        onDelete={handleOpenDialog}
                      />
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>No stations found!</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={dialog.open} onClose={handleCloseDialog}>
            <DeleteStationDialog
              onClose={handleCloseDialog}
              stationId={dialog.id}
            />
          </Dialog>
        </Fragment>
      )}
    </PageLayout>
  );
};
