import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    margin: "64px 96px 64px 364px",
    flexDirection: "column",
  },
  divider: {
    width: 256,
  },
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  bottomMarginSmall: {
    marginTop: 32,
  },
  bottomMarginLarge: {
    marginTop: 64,
  },
  subRoute: {
    margin: "0 4px 0 4px",
  },
  subRouteDiv: {
    display: "flex",
    marginTop: 4,
    // alignItems: "center",
  },
}));
