import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

interface IProps {
  onClose: () => void;
  id: string | undefined;
  onDelete: any;
}
export const DeleteMediaDialog: FC<IProps> = (props) => {
  const { onClose, id, onDelete } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleRemove = () => {
    if (id) {
      onDelete({
        variables: {
          path: id,
        },
      });
    } else {
      enqueueSnackbar("Missing ID!", { variant: "error" });
    }
  };

  // console.log(id);

  return (
    <Fragment>
      <DialogTitle>Delete media</DialogTitle>
      <DialogContent>
        <Typography>
          This action cannot be undone. Make sure you press update or add
          afterwards to prevent issues with images.
        </Typography>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleRemove}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Fragment>
  );
};
