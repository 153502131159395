import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useStyles } from "./MediaPreviewDialog.styles";
import { FC, Fragment } from "react";
import { IUploadedFile } from "../MultipleMediaUpload";

interface IProps {
  item: IUploadedFile | undefined;
  onClose: () => void;
}

export const MediaPreviewDialog: FC<IProps> = (props) => {
  const { item, onClose } = props;

  const styles = useStyles();

  const renderBlob = () => {
    switch (item?.extension) {
      case "jpeg":
      case "png":
      case "jpg": {
        return (
          <img
            src={
              item.blob
                ? item.blob
                : `${process.env.REACT_APP_API_BUCKET}${item.path}`
            }
            alt=""
            className={styles.previewImage}
          />
        );
      }
      case "ogg": {
        return (
          <audio controls>
            <source
              src={
                item.blob
                  ? item.blob
                  : `${process.env.REACT_APP_API_BUCKET}${item.path}`
              }
              type="audio/flac"
            />
            Your browser does not support the audio element.
          </audio>
        );
      }
      case "mp3": {
        return (
          <audio controls>
            <source
              src={
                item.blob
                  ? item.blob
                  : `${process.env.REACT_APP_API_BUCKET}${item.path}`
              }
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
        );
      }
    }
  };

  return (
    <Fragment>
      <DialogTitle>{item?.name}</DialogTitle>
      <DialogContent>{renderBlob()}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Fragment>
  );
};
