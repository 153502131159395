import { createTypedInputs } from "../../../../utils";

export const initialInputData = createTypedInputs({
  startHour: {
    value: { unlocalised: { value: "" } },
    label: "Start Hour",
    required: true,
  },
  endHour: {
    value: { unlocalised: { value: "" } },
    label: "End Hour",
    required: true,
  },
  weekday: {
    value: { unlocalised: { value: "" } },
    label: "Weekday",
    required: true,
  },
});
