import { FC } from "react";
import { CircularProgress } from "@mui/material";
import { useStyles } from "./LoadingComponent.styles";

interface IProps {
  small?: boolean;
}

export const LoadingComponent: FC<IProps> = (props) => {
  const { small } = props;
  const styles = useStyles();
  return (
    <div className={small ? styles.rootSmall : styles.root}>
      <CircularProgress color="secondary" />
    </div>
  );
};
