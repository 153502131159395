import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  rteInputField: {
    margin: "16px 0 16px 0",
  },
  inputField: {
    flexBasis: "25%",
  },

  subtitle: {
    marginBottom: 16,
  },
}));
