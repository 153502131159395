import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  DialogActions,
  Dialog,
  LinearProgress,
} from "@mui/material";
import { useStyles } from "./EditAdminDialog.styles";
import { Phone as PhoneIcon, Title as TitleIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../utils";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_ADMIN,
  IUpdateAdminData,
  IUpdateAdminVars,
} from "../../../../apollo/mutations";
import { initialInputData } from "./EditAdminDialog.inputs";
import { IAdminData, IAdminVars, ONE_ADMIN } from "../../../../apollo/queries";
import { useEffect } from "react";
import { ErrorComponent, LoadingComponent } from "../../../../components";

interface IProps {
  onClose: () => void;
  adminId: string;
}

export const EditAdminDialog: FC<IProps> = (props) => {
  const { onClose, adminId } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const paperProps = {
    style: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  };

  const { inputFields, setInputFields, inputProps, formReady } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [updateAdmin, { loading: mutationLoading }] = useMutation<
    IUpdateAdminData,
    IUpdateAdminVars
  >(UPDATE_ADMIN, {
    onCompleted: () => {
      enqueueSnackbar("Successfully updated administrator", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      // console.log("Error: ", { error });
      onClose();
    },
  });

  const { loading, error, data } = useQuery<IAdminData, IAdminVars>(ONE_ADMIN, {
    variables: {
      id: +adminId,
    },
  });

  useEffect(() => {
    // console.log(data?.person, formReady);
    if (data?.person && formReady) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "phone",
          toFormProperty: "phone",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.person, valueToFormOptions, inputFields);
      // console.log(getUpdatedInputs);
      setInputFields(getUpdatedInputs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setInputFields, formReady]);

  const handleNewAdmin = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      updateAdmin({
        variables: {
          id: +adminId,
          data: {
            phone: inputFields.phone.value.unlocalised.value || undefined,
            name: inputFields.name.value.unlocalised.value || undefined,
          },
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  if (loading) {
    return <LoadingComponent small />;
  }
  if (error) {
    // console.log({ error });
    return <ErrorComponent small />;
  }

  return (
    <Fragment>
      <DialogTitle>Update administrator</DialogTitle>
      <DialogContent>
        <TextField
          className={styles.inputField}
          {...inputProps("name")}
          fullWidth
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className={styles.inputField}
          {...inputProps("phone")}
          fullWidth
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleNewAdmin}>
          Update
        </Button>
      </DialogActions>
      <Dialog open={mutationLoading} fullScreen PaperProps={paperProps}>
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
