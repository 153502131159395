import { createTypedInputs } from "../../../../utils";

export const initialInputData = createTypedInputs({
  email: {
    value: { unlocalised: { value: "" } },
    label: "Email",
    placeholder: "example@gmail.com",
    required: true,
    rules: "email",
  },
  phone: {
    value: { unlocalised: { value: "" } },
    label: "Contact number",
    placeholder: "",
    required: false,
  },
  name: {
    value: { unlocalised: { value: "" } },
    label: "Name",
    placeholder: "John Doe",
    required: true,
  },
});
