import { FC, useCallback, useState } from "react";
import { Descendant, Editor, Transforms } from "slate";
import { Editable, ReactEditor, Slate } from "slate-react";
import { useStyles } from "./RichTextEditor.styles";
import { SlateRteElement, Leaf, ToolbarRte } from "./";
import { FormHelperText, InputLabel } from "@mui/material";

interface IProps {
  className?: string;
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  placeholder?: string;
  error?: boolean;
  onBlur?: () => void;
  editor: ReactEditor & Editor;
  readOnly?: boolean;
  required?: boolean;
  helperText?: string;
  label: string;
}

export const emptySlateValue: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "", italic: false }],
  },
];

export const emptySlateStringValue = "<p></p>";

export const RichTextEditor: FC<IProps> = (props) => {
  const {
    className,
    onChange,
    value,
    placeholder,
    error,
    onBlur,
    editor,
    readOnly,
    required,
    label,
    helperText,
  } = props;
  const styles = useStyles();

  const [focused, setFocused] = useState(false);

  const handleOnBlur = () => {
    // console.log("BLUR");
    Transforms.select(editor, Editor.start(editor, []));
    setFocused(false);
    onBlur?.();
  };

  const handleOnFocus = () => {
    setFocused(true);
  };

  const renderElement = useCallback(
    (props) => <SlateRteElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <div className={`${styles.wrapper} ${className || ""}`}>
      <InputLabel
        variant="outlined"
        shrink
        required={required}
        className={styles.inputLabel}
        focused={focused}
      >
        {label}
      </InputLabel>
      <div
        className={`${styles.rte} ${focused ? styles.focused : ""} ${
          error && styles.onError
        }`}
      >
        {editor && (
          <Slate editor={editor} value={value} onChange={onChange}>
            {!readOnly && <ToolbarRte />}
            <Editable
              onFocus={handleOnFocus}
              readOnly={readOnly}
              className={`${styles.slate}`}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder ?? "Write something here..."}
              // autoFocus
              onBlur={handleOnBlur}
              required={required}
            />
          </Slate>
        )}
      </div>
      <FormHelperText
        variant="outlined"
        required={required}
        focused={focused}
        error={error}
      >
        {helperText}
      </FormHelperText>
    </div>
  );
};
