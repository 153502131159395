import { gql } from "@apollo/client";

export interface ICreateAdminVars {
  data: {
    name: string;
    phone: string;
    email: string;
  };
}

export interface ICreateAdmin {
  id: string;
  name: string;
  phone: string;
  email: string;
  role: {
    id: string;
    name: string;
    access: number;
  };
  created: Date;
}

export interface ICreateAdminData {
  createAdmin: ICreateAdmin;
}

export const CREATE_ADMIN = gql`
  mutation createAdmin($data: PersonCreateInput) {
    createAdmin(data: $data) {
      id
      name
      phone
      email
      role {
        id
        name
        access
      }
      created
    }
  }
`;
