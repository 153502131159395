import { gql } from "@apollo/client";

export interface IStationCategory {
  id: string;
  locale: {
    name: string;
  }[];
}

export interface IStationCategoryData {
  stationCategories: IStationCategory[];
}

export interface IStationCategoryVars {
  filter: any;
}

export const ALL_STATION_CATEGORIES = gql`
  query stationCategories($filter: FindManyStationCategoryArgs) {
    stationCategories(filter: $filter) {
      id
      locale(lang: "en-GB") {
        name
      }
    }
  }
`;
