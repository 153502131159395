import { Button, Typography, IconButton } from "@mui/material";
import {
  Alarm as AlarmIcon,
  Category as CategoryIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { FC } from "react";
import { ICulturalHeritageList } from "../../../../apollo/queries";
import { useStyles } from "./CulturalHeritageListItem.styles";

interface IProps {
  item: ICulturalHeritageList;
  onClick: () => void;
  onDelete: () => void;
}

export const CulturalHeritageListItem: FC<IProps> = (props) => {
  const { item, onClick, onDelete } = props;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {item.media?.path ? (
        <img
          src={`${process.env.REACT_APP_API_BUCKET}${item.media.path}`}
          alt=""
          className={`${styles.img} ${
            item.published ? "" : styles.unpublished
          }`}
        />
      ) : (
        <div className={styles.errorDiv}>
          <ErrorIcon color="error" />
          <Typography>No image found!</Typography>
        </div>
      )}
      <Typography variant="h4" className={styles.title}>
        {item.locale[0].title.length > 40
          ? item.locale[0].title.substr(0, 40 - 1) + "..."
          : item.locale[0].title}
      </Typography>
      <Button
        onClick={onClick}
        variant="contained"
        className={styles.expandButton}
      >
        <Typography variant="h5">Expand</Typography>
      </Button>
      <IconButton
        color="primary"
        onClick={onDelete}
        className={styles.deleteButton}
      >
        <DeleteIcon color="primary" />
      </IconButton>

      <Typography className={styles.duration}>
        <AlarmIcon className={styles.icon} />
        {item.duration
          ? `${
              Math.floor(item.duration / 60)
                ? Math.floor(item.duration / 60) + "h "
                : ""
            }${item.duration % 60 ? (item.duration % 60) + "m" : ""}`
          : "- No duration -"}
      </Typography>
      <Typography className={styles.category}>
        <CategoryIcon className={styles.icon} />
        {item.category.locale[0].name}
      </Typography>
    </div>
  );
};
