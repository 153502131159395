import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Reference, useMutation } from "@apollo/client";
import {
  DELETE_OPENING_HOUR,
  IDeleteOpeningHourData,
  IDeleteOpeningHourVars,
} from "../../../../apollo/mutations";
import { LoadingBackdrop } from "../../../../components";

interface IProps {
  onClose: () => void;
  id: string | undefined;
}
export const DeleteOpeningHourDialog: FC<IProps> = (props) => {
  const { onClose, id } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [deleteOpeningHour, { loading }] = useMutation<
    IDeleteOpeningHourData,
    IDeleteOpeningHourVars
  >(DELETE_OPENING_HOUR, {
    onCompleted: () => {
      enqueueSnackbar("Opening Hour was successfuly deleted!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      // console.log("Error: ", { error });
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          openingHours(existingData: Array<Reference>, { readField }) {
            if (data) {
              return existingData.filter(
                (taskRef) =>
                  data.deleteOpeningHour.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const onDeleteOpeningHour = (): void => {
    if (id) {
      deleteOpeningHour({
        variables: { id: +id },
      });
    } else {
      enqueueSnackbar("No ID found when deleting opening hour!", {
        variant: "error",
      });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Delete opening hour</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onDeleteOpeningHour}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
      <LoadingBackdrop loading={loading} />
    </Fragment>
  );
};
