import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 32,
    alignItems: "center",
  },
  button: {
    marginTop: 32,
    width: 256,
  },
  buttonRight: {
    marginTop: 32,
    width: 128,
    marginLeft: 32,
  },
  editButton: {
    alignSelf: "flex-start",
    marginBottom: 16,
  },
}));
