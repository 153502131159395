import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  LinearProgress,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { Reference, useMutation, useQuery } from "@apollo/client";
import {
  DELETE_ADMIN,
  IDeleteAdminVars,
  IDeleteAdminData,
} from "../../../../apollo/mutations";
import { ICurrentUserData, CURRENT_USER } from "../../../../apollo/queries";
import { LoadingComponent, ErrorComponent } from "../../../../components";

interface IProps {
  onClose: () => void;
  adminId: string;
}
export const DeleteAdminDialog: FC<IProps> = (props) => {
  const { onClose, adminId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const paperProps = {
    style: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  };

  const { loading, error, data } = useQuery<ICurrentUserData>(CURRENT_USER);

  const [deleteOneAdmin, { loading: mutationLoading }] = useMutation<
    IDeleteAdminData,
    IDeleteAdminVars
  >(DELETE_ADMIN, {
    onCompleted: () => {
      enqueueSnackbar("Successfully deleted admin!", { variant: "success" });
      onClose();
    },
    onError: () => {
      // console.log("Error: ", { error });
      enqueueSnackbar("Failed to delete admin, try again later!", {
        variant: "error",
      });
    },
    update(cache, { data }) {
      // console.log(data);
      cache.modify({
        fields: {
          persons(existingAdmins: Array<Reference>, { readField }) {
            if (data) {
              return existingAdmins.filter(
                (taskRef) => data.deleteAdmin.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const onDeleteAdmin = (): void => {
    deleteOneAdmin({
      variables: { id: +adminId },
    });
  };

  if (loading) {
    return <LoadingComponent small />;
  }
  if (error) {
    // console.log("Error: ", { error });
    return <ErrorComponent small />;
  }

  return (
    <Fragment>
      <DialogTitle>Delete admin</DialogTitle>
      <DialogContent>This action cannot be undone!</DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={data?.currentUser?.id === adminId}
          color="primary"
          onClick={onDeleteAdmin}
        >
          Delete
        </Button>
      </DialogActions>
      <Dialog open={mutationLoading} fullScreen PaperProps={paperProps}>
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
