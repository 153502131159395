import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    borderRadius: 32,
  },
  galleryPaper: {
    padding: 32,
    borderRadius: 32,
    marginBottom: 32,
  },
  rteInputField: {
    margin: "16px 0 16px 0",
  },
  inputField: {
    flexBasis: "25%",
  },
  inputDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  subtitle: {
    marginBottom: 16,
  },
  table: {
    "& .MuiTableCell-root:not(:first-child)": {
      borderLeft: "1px solid #333",
    },
    "& .MuiTableCell-head": {
      borderBottom: "1px solid #333",
    },
  },
  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
  },
  button: {
    marginTop: 16,
  },
  helpIcon: {
    marginLeft: 8,
  },
}));
