import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  divider: {
    margin: "16px 0",
  },
  inputField: {
    margin: "8px 0",
  },
}));
