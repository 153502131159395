import { gql } from "@apollo/client";

export interface IStationList {
  id: string;
  published: boolean;
  category?: {
    id: string;
    locale: {
      name: string;
    }[];
  };
  gallery: {
    id: string;
    path: string;
    extension: string;
    type: string;
    name: string;
  }[];
  locale: {
    id: string;
    title: string;
  }[];
  created: string;
}

export interface IStationsListData {
  stations: IStationList[];
}

export const ALL_STATIONS_LIST = gql`
  query stations($filter: FindManyStationArgs) {
    stations(filter: $filter) {
      id
      published
      category {
        locale(lang: "en-GB") {
          name
        }
      }
      gallery {
        id
        path
        type
        name
        extension
      }
      locale(lang: "en-GB") {
        id
        title
      }
      created
    }
  }
`;

export interface IStation {
  id: string;
  published: boolean;
  latitude: string;
  longitude: string;
  category: {
    id: string;
    locale: {
      name: string;
    }[];
  };
  gallery: {
    id: string;
    path: string;
    extension: string;
    name: string;
    type: string;
    size: number;
  }[];
  locale: {
    id: string;
    title: string;
    description: string;
    audioText: string;
    audio: {
      id: string;
      path: string;
      extension: string;
      type: string;
    };
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
}

export interface IStationsData {
  stations: IStation[];
}

export interface IStationsVars {
  filter: any;
}

export const ALL_STATIONS = gql`
  query stations($filter: FindManyStationArgs) {
    stations(filter: $filter) {
      id
      published

      gallery {
        id
        path
        type
        size
        name
        extension
      }
      locale {
        id
        title
        description
        audio {
          id
          path
          type
          extension
        }
        audioText
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;

export interface IStationData {
  station: IStation;
}

export interface IStationVars {
  id: number;
}

export const ONE_STATION = gql`
  query station($id: ID) {
    station(id: $id) {
      id
      published
      longitude
      latitude
      category {
        id
      }
      gallery {
        id
        path
        name
        size
        type
        extension
      }
      locale {
        id
        title
        description
        audio {
          id
          path
          type
          name
          size
          extension
        }
        audioText
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
