import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  button: {
    width: 680,
    height: 164,
    marginBottom: 16,
    borderStyle: "dashed",
    borderWidth: 3,
  },
  addButtonIcon: {
    backgroundColor: theme.palette.primary.main,
    width: 48,
    height: 48,
    borderRadius: 24,
    color: "white ",
  },
}));
