import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.grey[100],
    display: "flex",
    flexWrap: "wrap",
    "& > button": {
      // flex: 1,
    },
    "& > hr": {
      width: "100%",
    },
  },
}));
