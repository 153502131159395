import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: 32,
    "&:hover $overlay": {
      transition: "all 0.3s ease-in-out",
      opacity: 1,
    },
    "&:hover $image": {
      transition: "all 0.3s ease-in-out",
      transform: "scale(1.1)",
    },
  },

  image: {
    width: "100%",
    height: "100%",
    transition: "all 0.3s ease-in-out",
    transform: "scale(1)",
    objectFit: "cover",
  },

  audio: {
    width: "100%",
  },

  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#00000033",
    borderRadius: "4px 16px 4px 4px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  button: {
    color: theme.palette.primary.main,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));
