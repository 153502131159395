import { useState, FC, ChangeEvent, KeyboardEvent } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  OutlinedInputProps,
} from "@mui/material";
import {
  SearchOutlined as SearchOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
} from "@mui/icons-material";
import { useStyles } from "./SearchBar.styles";

interface IProps {
  onSearch: (value: string) => void;
  activeSearch: string;
  className?: string;
}

export const SearchBar: FC<IProps> = (props) => {
  const { onSearch, activeSearch, className } = props;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const styles = useStyles();

  const handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value);
  };

  const handleSearchQueryKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(searchQuery);
    }
  };
  const handleSearchQueryPress = () => {
    onSearch(searchQuery);
  };

  const handleResetSearchQuery = () => {
    setSearchQuery("");
    onSearch("");
  };

  const inputProps: Partial<OutlinedInputProps> = {
    className: styles.inputField,
    endAdornment: (
      <InputAdornment position="end">
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSearchQueryPress}
          title="Pretraži"
          className={styles.searchButton}
        >
          <SearchOutlinedIcon />
        </Button>
      </InputAdornment>
    ),
    startAdornment:
      searchQuery || activeSearch ? (
        <InputAdornment position="start">
          <IconButton
            size="small"
            onClick={handleResetSearchQuery}
            title="Obriši tražilicu"
          >
            <CancelOutlinedIcon />
          </IconButton>
        </InputAdornment>
      ) : null,
  };

  return (
    <TextField
      placeholder="Search..."
      value={searchQuery}
      label="Search"
      variant="outlined"
      fullWidth
      onChange={handleSearchQuery}
      onKeyDown={handleSearchQueryKey}
      size="small"
      className={`${styles.root} ${className}`}
      InputProps={inputProps}
    />
  );
};
