import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "36px",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "60vh",
    color: "#999",
  },
  rootSmall: {
    display: "flex",
    margin: "16px",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "#999",
  },
}));
