import {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useStyles } from "./StationDetailsScreen.styles";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
  PageLayout,
} from "../../components";
import {
  Button,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { initialInputData } from "./StationDetailsScreen.input";
import {
  ContextProvider,
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
  withLinks,
} from "../../utils";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ALL_STATIONS_LIST,
  ALL_STATION_CATEGORIES,
  IStationCategoryData,
  IStationCategoryVars,
  IStationData,
  IStationList,
  IStationsListData,
  IStationVars,
  ONE_STATION,
} from "../../apollo/queries";
import { useHistory, useParams } from "react-router";
import { RichTextEditor } from "../../components/RichTextEditor";
import { withReact } from "slate-react";
import { createEditor, Editor } from "slate";
import {
  Category as CategoryIcon,
  Description as DescriptionIcon,
  QrCode as QrCodeIcon,
  SwapHoriz as SwapHorizIcon,
  SwapVert as SwapVertIcon,
  Title as TitleIcon,
} from "@mui/icons-material";
import { MultipleMediaUpload } from "../../components/MultipleMediaUpload/MultipleMediaUpload";
import {
  CREATE_STATION,
  ICreateStationData,
  ICreateStationVars,
  IUpdateStationData,
  IUpdateStationVars,
  UPDATE_STATION,
} from "../../apollo/mutations";
import { withHistory } from "slate-history";
import QRCode from "qrcode.react";
interface IParams {
  stationId: string;
}

export const StationDetailsScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { localeFlags } = useContext(ContextProvider);
  const history = useHistory();

  const { stationId } = useParams<IParams>();
  const [localeSelected, setLocaleSelected] = useState("");

  const { loading, error, data } = useQuery<
    IStationCategoryData,
    IStationCategoryVars
  >(ALL_STATION_CATEGORIES, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
      },
    },
  });

  const [
    stationQuery,
    {
      loading: loadingStation,
      error: errorStation,
      data: dataStation,
      called: calledStation,
    },
  ] = useLazyQuery<IStationData, IStationVars>(ONE_STATION);

  const editorRef = useRef<Editor>(null);
  if (!editorRef.current)
    //@ts-ignore
    editorRef.current = withLinks(withHistory(withReact(createEditor())));
  const editor = editorRef.current;

  const localeSelectProps = {
    selected: localeSelected,
    setSelected: setLocaleSelected,
  };
  const { inputFields, setInputFields, inputProps, setInputField, formReady } =
    useForm<keyof typeof initialInputData>(initialInputData, localeSelected);

  const handleSetCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField(
      "published",
      event.target.value === "true" ? "false" : "true"
    );
  };

  const handleSetMapLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField(
      "showOnMap",
      event.target.value === "true" ? "false" : "true"
    );
  };

  const [createStation, { loading: loadingCreateStation }] = useMutation<
    ICreateStationData,
    ICreateStationVars
  >(CREATE_STATION, {
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Sucessfully created new station!", {
        variant: "success",
      });
      history.replace(`/station`);
    },
    onError: (err) => {
      enqueueSnackbar("Failed to create new station, please try again!", {
        variant: "error",
      });
    },
    update: (cache, { data }) => {
      // console.log("Cache", cache);
      const existingListData: IStationsListData | null = cache.readQuery({
        query: ALL_STATIONS_LIST,
        variables: {
          filter: {
            where: {
              expired: null,
            },
            orderBy: {
              created: "desc",
            },
          },
        },
      });
      if (data?.createStation) {
        const getFlagId = data?.createStation.locale.findIndex(
          (x) => x.localeFlag.isoLanguageCode === "en-GB"
        );
        const newListData: IStationList = {
          gallery: data.createStation.gallery,
          category: data.createStation.category,
          published: data.createStation.published,
          id: data.createStation.id,
          locale: [data?.createStation.locale[getFlagId]],
          created: data.createStation.created,
        };
        cache.writeQuery({
          query: ALL_STATIONS_LIST,
          variables: {
            filter: {
              where: {
                expired: null,
              },
              orderBy: {
                created: "desc",
              },
            },
          },
          data: {
            stations: existingListData?.stations
              ? [newListData, ...existingListData.stations]
              : [newListData],
          },
        });
      }
    },
  });

  const [updateStation, { loading: loadingUpdateStation }] = useMutation<
    IUpdateStationData,
    IUpdateStationVars
  >(UPDATE_STATION, {
    onCompleted: (data) => {
      // console.log("Update data: ", data);
      enqueueSnackbar(`Successfully updated station!`, {
        variant: "success",
      });
    },
    onError: (err) => {
      enqueueSnackbar("Failed to update station, please try again!", {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (localeSelected && !calledStation && stationId)
      stationQuery({
        variables: {
          id: +stationId,
        },
      });
  }, [localeSelected, stationId, calledStation, stationQuery]);

  useEffect(() => {
    if (dataStation?.station?.locale && calledStation && formReady) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "locale.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "locale.audioText",
          toFormProperty: "audioText",
        },
        {
          fromDataProperty: "category.id",
          toFormProperty: "category",
        },
        {
          fromDataProperty: "locale.description",
          toFormProperty: "description",
        },
        {
          fromDataProperty: "locale.audio",
          toFormProperty: "audioGuide",
        },
        {
          fromDataProperty: "gallery",
          toFormProperty: "gallery",
        },
        {
          fromDataProperty: "latitude",
          toFormProperty: "latitude",
        },
        {
          fromDataProperty: "longitude",
          toFormProperty: "longitude",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(dataStation.station, valueToFormOptions, inputFields);
      // console.log("Values to update: ", dataStation);
      if (dataStation.station?.category?.id) {
        getUpdatedInputs.showOnMap.value.unlocalised.value = "true";
      }
      if (dataStation.station?.published === false) {
        getUpdatedInputs.published.value.unlocalised.value = "false";
      }
      // console.log(getUpdatedInputs);
      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStation, calledStation, formReady]);

  // const handleUpdateAll = () => {
  //   const fields =
  //     inputFields.showOnMap.value.unlocalised.value === "false"
  //       ? [
  //           "title",
  //           "description",
  //           "published",
  //           "audioText",
  //           "audioGuide",
  //           "gallery",
  //         ]
  //       : Object.keys(initialInputData);
  //   const result = validateForm<keyof typeof initialInputData>(
  //     fields,
  //     inputFields,
  //     "allLocales",
  //     localeSelected
  //   );
  //   if (result.formValid && dataStation) {
  //     localeFlags.forEach((item) => {
  //       const getLocaleId = dataStation.station.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       const getFlagId = dataStation.station.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       if (getLocaleId && getFlagId) {
  //         updateStation({
  //           variables: {
  //             id: +stationId,
  //             localeId: +getLocaleId,
  //             data: {
  //               published: true,
  //               gallery: inputFields.gallery.value.unlocalised.value.split(","),
  //               category:
  //                 inputFields.showOnMap.value.unlocalised.value === "false" &&
  //                 inputFields.category.value.unlocalised.value
  //                   ? {
  //                       connect: {
  //                         id: +inputFields.category.value.unlocalised.value,
  //                       },
  //                     }
  //                   : { disconnect: true },
  //               latitude:
  //                 inputFields.showOnMap.value.unlocalised.value === "false"
  //                   ? inputFields.latitude.value.unlocalised.value
  //                   : "",
  //               longitude:
  //                 inputFields.showOnMap.value.unlocalised.value === "false"
  //                   ? inputFields.longitude.value.unlocalised.value
  //                   : "",
  //             },
  //             localeData: {
  //               audio: inputFields.audioGuide.value[localeSelected].media!
  //                 .length
  //                 ? {
  //                     connect: {
  //                       path: inputFields.audioGuide.value[localeSelected]
  //                         .media![0].path,
  //                     },
  //                   }
  //                 : {
  //                     disconnect: true,
  //                   },
  //               audioText: inputFields.audioText.value[localeSelected].value,
  //               title: inputFields.title.value[localeSelected].value,
  //               description:
  //                 inputFields.description.value[localeSelected].value,
  //               localeFlag: {
  //                 connect: {
  //                   id: +getFlagId,
  //                 },
  //               },
  //             },
  //           },
  //         });
  //       } else {
  //         enqueueSnackbar("Something went wrong, try again later!", {
  //           variant: "error",
  //         });
  //       }
  //     });
  //   } else {
  //     console.log("Form is invalid: ", result);
  //     enqueueSnackbar(
  //       "Not all required fields are set. Check other languages!",
  //       {
  //         variant: "error",
  //       }
  //     );
  //     setInputFields(result.outputData);
  //   }
  // };

  const handleUpdate = () => {
    const fields =
      inputFields.showOnMap.value.unlocalised.value === "false"
        ? [
            "title",
            "description",
            "published",
            "audioText",
            "audioGuide",
            "gallery",
          ]
        : Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      localeSelected
    );
    if (result.formValid && dataStation) {
      const getLocaleId = dataStation.station.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      const getFlagId = dataStation.station.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      if (getLocaleId && getFlagId) {
        updateStation({
          variables: {
            id: +stationId,
            localeId: +getLocaleId,
            data: {
              published:
                inputFields.published.value.unlocalised.value === "true",
              gallery: inputFields.gallery.value.unlocalised.value.split(","),
              category:
                inputFields.showOnMap.value.unlocalised.value === "true" &&
                inputFields.category.value.unlocalised.value
                  ? {
                      connect: {
                        id: +inputFields.category.value.unlocalised.value,
                      },
                    }
                  : { disconnect: true },
              latitude:
                inputFields.showOnMap.value.unlocalised.value === "true"
                  ? inputFields.latitude.value.unlocalised.value
                  : "",
              longitude:
                inputFields.showOnMap.value.unlocalised.value === "true"
                  ? inputFields.longitude.value.unlocalised.value
                  : "",
            },
            localeData: {
              audio: inputFields.audioGuide.value[localeSelected].media!.length
                ? {
                    connect: {
                      path: inputFields.audioGuide.value[localeSelected]
                        .media![0].path,
                    },
                  }
                : {
                    disconnect: true,
                  },
              audioText: inputFields.audioText.value[localeSelected].value,
              title: inputFields.title.value[localeSelected].value,
              description: inputFields.description.value[localeSelected].value,
              localeFlag: {
                connect: {
                  id: +getFlagId,
                },
              },
            },
          },
        });
      } else {
        enqueueSnackbar("Something went wrong, try again later!", {
          variant: "error",
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  const handleCreateNew = () => {
    console.log(inputFields);
    const fields =
      inputFields.showOnMap.value.unlocalised.value === "false"
        ? [
            "title",
            "description",
            "published",
            "audioText",
            "audioGuide",
            "gallery",
          ]
        : Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      "allLocales",
      localeSelected
    );
    if (result.formValid) {
      createStation({
        variables: {
          data: {
            published: inputFields.published.value.unlocalised.value === "true",
            gallery: inputFields.gallery.value.unlocalised.value.split(","),
            category:
              inputFields.showOnMap.value.unlocalised.value === "true" &&
              inputFields.category.value.unlocalised.value
                ? {
                    connect: {
                      id: +inputFields.category.value.unlocalised.value,
                    },
                  }
                : undefined,
            latitude:
              inputFields.showOnMap.value.unlocalised.value === "true"
                ? inputFields.latitude.value.unlocalised.value
                : undefined,
            longitude:
              inputFields.showOnMap.value.unlocalised.value === "true"
                ? inputFields.longitude.value.unlocalised.value
                : undefined,
          },
          localeData: localeFlags.map((item) => {
            return {
              audio: inputFields.audioGuide.value[item.isoLanguageCode]
                .media?.[0]
                ? {
                    connect: {
                      path: inputFields.audioGuide.value[item.isoLanguageCode]
                        .media![0].path,
                    },
                  }
                : undefined,
              audioText:
                inputFields.audioText.value[item.isoLanguageCode].value,
              title: inputFields.title.value[item.isoLanguageCode].value,
              description:
                inputFields.description.value[item.isoLanguageCode].value,
              localeFlag: { connect: { id: +item.id } },
            };
          }),
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar(
        "Not all required fields are set. Check other languages!",
        {
          variant: "error",
        }
      );
      setInputFields((prev) => {
        return { ...prev, ...result.outputData };
      });
    }
  };

  useEffect(() => {
    // console.log("IF: ", inputFields);
  }, [inputFields]);

  const downloadQR = () => {
    if (stationId) {
      const canvas = document.getElementById("qrCode") as HTMLCanvasElement;
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `qrCode-${stationId}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Fragment>
      <PageLayout
        title="Station Details"
        localeSelect={localeSelectProps}
        subRoute={[
          stationId
            ? dataStation?.station?.locale?.find(
                (x) => x.localeFlag?.isoLanguageCode === localeSelected
              )!.title
              ? dataStation?.station?.locale?.find(
                  (x) => x.localeFlag?.isoLanguageCode === localeSelected
                )!.title
              : "Loading"
            : "New",
        ]}
      >
        {loading || !formReady || loadingStation ? (
          <LoadingComponent />
        ) : error || errorStation ? (
          <ErrorComponent error={error || errorStation} />
        ) : (
          <Fragment>
            <Paper className={styles.paper}>
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Information
              </Typography>
              <TextField
                className={styles.inputField}
                variant="outlined"
                fullWidth
                {...inputProps("title")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TitleIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <RichTextEditor
                className={styles.rteInputField}
                editor={editor}
                // readOnly={!edit}
                {...inputProps("description", editor)}
              />

              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Gallery
              </Typography>
              <MultipleMediaUpload
                {...inputProps("gallery")}
                validTypes={["image/jpeg", "image/jpg"]}
                styleType="gallery"
                max={8}
              />
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Audio Guide
              </Typography>
              <MultipleMediaUpload
                {...inputProps("audioGuide")}
                validTypes={["audio/flac", "audio/mpeg"]}
                styleType="audio"
                max={1}
                vanishWhenMax
                className={styles.subtitle}
              />
              <TextField
                variant="outlined"
                {...inputProps("audioText")}
                minRows={4}
                multiline
                maxRows={8}
                className={styles.subtitle}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        inputFields.published.value.unlocalised.value === "true"
                      }
                      onChange={handleSetCheckbox}
                      value={
                        inputFields.published.value.unlocalised.value === "true"
                      }
                    />
                  }
                  label={inputFields.published.label}
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        inputFields.showOnMap.value.unlocalised.value === "true"
                      }
                      onChange={handleSetMapLocation}
                      value={
                        inputFields.showOnMap.value.unlocalised.value === "true"
                      }
                    />
                  }
                  label={inputFields.showOnMap.label}
                />
              </FormGroup>

              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Map Location
              </Typography>
              <TextField
                className={styles.subtitle}
                fullWidth
                disabled={
                  inputFields.showOnMap.value.unlocalised.value === "false"
                }
                variant="outlined"
                select
                {...inputProps("category")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CategoryIcon />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="">-- Select category --</MenuItem>
                {loading ? (
                  <LoadingComponent small />
                ) : error ? (
                  <ErrorComponent error={error} />
                ) : data?.stationCategories.length ? (
                  data.stationCategories.map((item) => {
                    if (item.locale?.length) {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.locale[0].name}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={item.id} value="" disabled>
                          Something went wrong!
                        </MenuItem>
                      );
                    }
                  })
                ) : (
                  <MenuItem disabled value="">
                    No categories found!
                  </MenuItem>
                )}
              </TextField>
              <TextField
                variant="outlined"
                {...inputProps("latitude")}
                disabled={
                  inputFields.showOnMap.value.unlocalised.value === "false"
                }
                className={styles.subtitle}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SwapVertIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                {...inputProps("longitude")}
                className={styles.subtitle}
                disabled={
                  inputFields.showOnMap.value.unlocalised.value === "false"
                }
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SwapHorizIcon />
                    </InputAdornment>
                  ),
                }}
              />

              {stationId ? (
                <Fragment>
                  <Button
                    onClick={handleUpdate}
                    color="primary"
                    variant="contained"
                  >
                    Update {localeSelected}
                  </Button>
                  {/* <Button
                    onClick={handleUpdateAll}
                    color="primary"
                    variant="contained"
                  >
                    Update All
                  </Button> */}
                </Fragment>
              ) : (
                <Button
                  onClick={handleCreateNew}
                  color="primary"
                  variant="contained"
                >
                  Add new
                </Button>
              )}
            </Paper>
            {stationId ? (
              <Paper className={styles.paperStation}>
                <Typography
                  className={styles.subtitle}
                  color="primary"
                  variant="h6"
                >
                  QR Code
                </Typography>

                <QRCode
                  id="qrCode"
                  value={`https://museum-prigorje.app.link/?id=${stationId}`}
                  size={290}
                  level={"H"}
                  includeMargin={true}
                />

                <TextField
                  value={stationId}
                  fullWidth
                  className={styles.subtitle}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QrCodeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button onClick={downloadQR} variant="contained">
                  Download
                </Button>
              </Paper>
            ) : null}
          </Fragment>
        )}
      </PageLayout>

      <LoadingBackdrop loading={loadingCreateStation || loadingUpdateStation} />
    </Fragment>
  );
};
