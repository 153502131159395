import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    borderRadius: 32,
  },
  rteInputField: {
    margin: "16px 0 16px 0",
  },
  inputField: {
    flexBasis: "25%",
  },
  inputDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  subtitle: {
    marginBottom: 16,
  },
  paperStation: {
    padding: 32,
    borderRadius: 32,
    marginTop: 32,
  },
}));
