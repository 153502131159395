import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    marginTop: 8,
  },
  flag: {
    marginRight: 8,
    filter: "grayscale(1.00)",
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    borderRadius: 4,
  },
  selectedFlag: {
    filter: "grayscale(0)",
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
  },
}));
