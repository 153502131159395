import { gql } from "@apollo/client";

export interface IDeleteStationVars {
  id: number;
}

export interface IDeleteStation {
  id: string;
}

export interface IDeleteStationData {
  deleteStation: IDeleteStation;
}

export const DELETE_STATION = gql`
  mutation deleteStation($id: ID!) {
    deleteStation(id: $id) {
      id
    }
  }
`;
