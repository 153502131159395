import { gql } from "@apollo/client";

export interface ICulturalHeritageStation {
  id: string;
  station: {
    id: string;
    locale: {
      title: string;
    }[];
  };
}

export interface ICulturalHeritageStationsData {
  culturalHeritageStations: ICulturalHeritageStation[];
}

export interface ICulturalHeritageStationsVars {
  filter: any;
}

export const ALL_CULTURAL_HERITAGE_STATION = gql`
  query culturalHeritageStations($filter: FindManyCulturalHeritageStationArgs) {
    culturalHeritageStations(filter: $filter) {
      id
      station {
        id
        locale(lang: "en-GB") {
          title
        }
      }
    }
  }
`;
