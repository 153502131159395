import {
  Typography,
  Divider,
  IconButton,
  Popper,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Dialog,
} from "@mui/material";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useStyles } from "./Dashboard.styles";
import { NavLink } from "react-router-dom";
import { setAccessToken } from "../../utils";
import { PersonRounded as PersonRoundedIcon } from "@mui/icons-material";
import { useQuery, useMutation } from "@apollo/client";
import { CURRENT_USER, ICurrentUserData } from "../../apollo/queries";
import { ErrorComponent, LoadingComponent } from "..";
import { LOGOUT } from "../../apollo/mutations/logout.mutation";
import { ResetPasswordDialog } from "./components/ResetPasswordDialog/ResetPasswordDialog";

export const Dashboard: FC = () => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const { loading, error, data } = useQuery<ICurrentUserData>(CURRENT_USER);

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: () => {
      setAccessToken("");
      window.location.replace("/login");
    },
    onError: (err) => {
      // console.error(err);
    },
  });

  const handleSetOpenPasswordDialog = () => {
    handleToggle();
    setPasswordDialog(true);
  };

  const handleSetClosePasswordDialog = () => {
    setPasswordDialog(false);
  };

  const onLogOut = () => {
    logoutMutation();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    // @ts-ignore
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-ignore
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (error) {
    // console.log("Error: ", { error });
    return <ErrorComponent />;
  }
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Fragment>
      <div className={styles.sideNavigation}>
        <div className={styles.logoDiv}>
          <Typography className={styles.title} variant="h4">
            MUZEJ PRIGORJA
          </Typography>
        </div>
        <nav className={styles.navigation}>
          <NavLink
            to="/cultural-heritage"
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">Cultural Heritage</Typography>
          </NavLink>
          <Divider className={styles.divider} />
          <NavLink
            to="/station"
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">Station</Typography>
          </NavLink>
          <Divider className={styles.divider} />
          <NavLink
            to="/notification"
            exact
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">Notifications</Typography>
          </NavLink>
          <Divider className={styles.divider} />
          <NavLink
            to="/about"
            exact
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">About Us</Typography>
          </NavLink>
          <Divider className={styles.divider} />
          <NavLink
            to="/privacy-policy"
            exact
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">Privacy Policy</Typography>
          </NavLink>
          <Divider className={styles.divider} />
          <NavLink
            to="/terms-and-conditions"
            exact
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">Terms and Conditions</Typography>
          </NavLink>
          <Divider className={styles.divider} />
          <NavLink
            to="/administrators"
            exact
            className={styles.navLink}
            activeClassName={styles.navLinkActive}
          >
            <Typography component="span">Administrators</Typography>
          </NavLink>
        </nav>
      </div>
      <IconButton
        title="Profile"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={styles.profileButton}
      >
        <PersonRoundedIcon
          color={open ? "secondary" : "action"}
          fontSize="large"
        />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem disabled>
                    {data && data.currentUser && data.currentUser.name}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleSetOpenPasswordDialog}>
                    Change password
                  </MenuItem>
                  <MenuItem onClick={onLogOut}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        open={passwordDialog}
        onClose={handleSetClosePasswordDialog}
        maxWidth="xs"
        fullWidth
      >
        <ResetPasswordDialog
          adminId={data?.currentUser?.id}
          adminEmail={data?.currentUser?.email}
          onClose={handleSetClosePasswordDialog}
        />
      </Dialog>
    </Fragment>
  );
};
