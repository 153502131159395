import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  InputAdornment,
  DialogActions,
  MenuItem,
} from "@mui/material";
import { useStyles } from "./ConnectStationDialog.styles";
import { ArtTrack as ArtTrackIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useForm, validateForm } from "../../../../utils";
import { useMutation, useQuery } from "@apollo/client";
import { initialInputData } from "./ConnectStationDialog.inputs";
import {
  ALL_CULTURAL_HERITAGE_STATION,
  ALL_STATIONS,
  ICulturalHeritageStation,
  ICulturalHeritageStationsData,
  IStationsData,
  IStationsVars,
} from "../../../../apollo/queries";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
} from "../../../../components";
import {
  IConnectCulturalHeritageStationVars,
  IConnectCulturalHeritageStationData,
  CONNECT_CULTURAL_HERITAGE_STATION,
} from "../../../../apollo/mutations";

interface IProps {
  culturalHeritageId: string;
  onClose: () => void;
}
export const ConnectStationDialog: FC<IProps> = (props) => {
  const { onClose, culturalHeritageId } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, setInputFields, inputProps, formReady } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const { data, loading, error } = useQuery<IStationsData, IStationsVars>(
    ALL_STATIONS,
    {
      variables: {
        filter: {
          where: {
            expired: null,
          },
        },
      },
      onError: () => {
        enqueueSnackbar("An error occured while fetching exhibits!");
      },
    }
  );

  const [
    createCulturalHeritageStation,
    { loading: loadingCreateCulturalHeritageStation },
  ] = useMutation<
    IConnectCulturalHeritageStationData,
    IConnectCulturalHeritageStationVars
  >(CONNECT_CULTURAL_HERITAGE_STATION, {
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Add new connection!", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    update: (cache, { data }) => {
      // console.log("Cache", cache);
      const existingListData: ICulturalHeritageStationsData | null =
        cache.readQuery({
          query: ALL_CULTURAL_HERITAGE_STATION,
          variables: {
            filter: {
              where: {
                culturalHeritageId: +culturalHeritageId,
                station: {
                  expired: null,
                },
              },
            },
          },
        });
      if (data?.connectCulturalHeritageStation) {
        const newListData: ICulturalHeritageStation = {
          ...data?.connectCulturalHeritageStation,
        };
        cache.writeQuery({
          query: ALL_CULTURAL_HERITAGE_STATION,
          variables: {
            filter: {
              where: {
                culturalHeritageId: +culturalHeritageId,
                station: {
                  expired: null,
                },
              },
            },
          },
          data: {
            culturalHeritageStations: existingListData?.culturalHeritageStations
              ? [newListData, ...existingListData.culturalHeritageStations]
              : [newListData],
          },
        });
      }
    },
  });

  const handleConnect = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields
    );
    if (result.formValid) {
      createCulturalHeritageStation({
        variables: {
          data: {
            culturalHeritage: { connect: { id: +culturalHeritageId } },
            station: {
              connect: { id: +inputFields.station.value.unlocalised.value },
            },
          },
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar(
        "Not all required fields are set. Check other languages!",
        {
          variant: "error",
        }
      );
      setInputFields(result.outputData);
    }
  };

  return (
    <Fragment>
      <DialogTitle>Connect to existing exhibit</DialogTitle>
      {loading || !formReady ? (
        <LoadingComponent small />
      ) : error ? (
        <ErrorComponent small error={error} />
      ) : (
        <DialogContent>
          <TextField
            className={styles.inputField}
            {...inputProps("station")}
            fullWidth
            variant="outlined"
            select
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArtTrackIcon />
                </InputAdornment>
              ),
            }}
          >
            {data?.stations.length ? (
              data.stations.map((item) => {
                if (item.locale?.length) {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.locale[0].title}
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={item.id} value="" disabled>
                      Something went wrong!
                    </MenuItem>
                  );
                }
              })
            ) : (
              <MenuItem disabled value="">
                No stations found!
              </MenuItem>
            )}
          </TextField>

          <DialogActions>
            <Button variant="text" color="primary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleConnect}>
              Connect
            </Button>
          </DialogActions>
        </DialogContent>
      )}
      <LoadingBackdrop loading={loadingCreateCulturalHeritageStation} />
    </Fragment>
  );
};
