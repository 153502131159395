let accessToken: string = "";

export const setAccessToken = (value: string) => {
  accessToken = value;
  // console.log("SET: ", { accessToken });
};

export const getAccessToken = (): string => {
  // console.log("GET: ", { accessToken });
  return accessToken;
};
