import {
  Button,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import {
  Category as CategoryIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { FC } from "react";
import { IStationList } from "../../../../apollo/queries";
import { useStyles } from "./StationListItem.styles";
import { format } from "date-fns";

interface IProps {
  item: IStationList;
  onClick: () => void;
  onDelete: () => void;
}

export const StationListItem: FC<IProps> = (props) => {
  const { item, onClick, onDelete } = props;
  const styles = useStyles();

  return (
    <TableRow key={item.id}>
      <TableCell component="th" scope="row">
        {item.locale[0].title}
      </TableCell>
      <TableCell>{item.category?.locale[0].name}</TableCell>
      <TableCell>
        {format(new Date(item.created), "dd.MM.yyyy. HH:mm")}h
      </TableCell>
      <TableCell>
        {item.gallery?.[0]?.path ? (
          <img
            src={`${process.env.REACT_APP_API_BUCKET}${item.gallery[0].path}`}
            alt=""
            className={`${styles.img} ${
              item.published ? "" : styles.unpublished
            }`}
          />
        ) : (
          <div className={styles.errorDiv}>
            <ErrorIcon color="error" />
            <Typography>No image found!</Typography>
          </div>
        )}
      </TableCell>
      <TableCell>
        <IconButton onClick={onClick}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>

    // <div className={styles.root}>
    //   {item.gallery?.[0]?.path ? (
    //     <img
    //       src={`${process.env.REACT_APP_API_BUCKET}${item.gallery[0].path}`}
    //       alt=""
    //       className={`${styles.img} ${
    //         item.published ? "" : styles.unpublished
    //       }`}
    //     />
    //   ) : (
    //     <div className={styles.errorDiv}>
    //       <ErrorIcon color="error" />
    //       <Typography>No image found!</Typography>
    //     </div>
    //   )}
    //   <Typography variant="h3" className={styles.title}>
    //     {item.locale[0].title}
    //   </Typography>
    //   <Button
    //     onClick={onClick}
    //     variant="contained"
    //     className={styles.expandButton}
    //   >
    //     <Typography variant="h5">Expand</Typography>
    //   </Button>
    //   <IconButton
    //     color="primary"
    //     onClick={onDelete}
    //     className={styles.deleteButton}
    //   >
    //     <DeleteIcon color="primary" />
    //   </IconButton>

    //   {item.category?.locale?.[0]?.name ? (
    //     <Typography className={styles.category}>
    //       <CategoryIcon className={styles.icon} />
    //       {item.category.locale[0].name}
    //     </Typography>
    //   ) : null}
    // </div>
  );
};
