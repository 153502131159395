import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  aboutUsGallery: {
    value: {
      unlocalised: { value: "", media: [] },
    },
    label: "Media Gallery",
    required: true,
    rules: "mediaUpload",
  },
});
