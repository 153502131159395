import {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useStyles } from "./CulturalHeritageDetailsScreen.styles";
import {
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
  PageLayout,
} from "../../components";
import {
  Button,
  Dialog,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { initialInputData } from "./CulturalHeritageDetailsScreen.input";
import {
  ContextProvider,
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
  withLinks,
} from "../../utils";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  ALL_CULTURAL_HERITAGE_CATEGOREIS,
  ALL_CULTURAL_HERITAGE_LIST,
  ALL_CULTURAL_HERITAGE_STATION,
  ICulturalHeritageCategoryData,
  ICulturalHeritageCategoryVars,
  ICulturalHeritageData,
  ICulturalHeritageList,
  ICulturalHeritageListData,
  ICulturalHeritageStationsData,
  ICulturalHeritageStationsVars,
  ICulturalHeritageVars,
  ONE_CULTURAL_HERITAGE,
} from "../../apollo/queries";
import { useHistory, useParams } from "react-router";
import { RichTextEditor } from "../../components/RichTextEditor";
import { withReact } from "slate-react";
import { createEditor, Editor } from "slate";
import {
  Category as CategoryIcon,
  Description as DescriptionIcon,
  Timer as TimerIcon,
  Title as TitleIcon,
} from "@mui/icons-material";
import { MultipleMediaUpload } from "../../components/MultipleMediaUpload/MultipleMediaUpload";
import {
  CREATE_CULTURAL_HERITAGE,
  ICreateCulturalHeritageData,
  ICreateCulturalHeritageVars,
  IUpdateCulturalHeritageData,
  IUpdateCulturalHeritageVars,
  UPDATE_CULTURAL_HERITAGE,
} from "../../apollo/mutations";
import {
  UpsertStationDialog,
  ConnectStationDialog,
  StationButton,
  DisconnectStationDialog,
  DeleteStationDialog,
} from "./components";
import { withHistory } from "slate-history";

interface IParams {
  culturalHeritageId: string;
}

interface IDialog {
  type: "Connect" | "Delete" | "Upsert" | "Disconnect" | undefined;
  id: string | undefined;
}

export const CulturalHeritageDetailsScreen: FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { localeFlags } = useContext(ContextProvider);
  const history = useHistory();

  const { culturalHeritageId } = useParams<IParams>();
  const [dialog, setDialog] = useState<IDialog>({
    type: undefined,
    id: undefined,
  });

  const [localeSelected, setLocaleSelected] = useState("");

  const handleCloseDialog = () => {
    setDialog({ type: undefined, id: undefined });
  };

  const handleOpenConnectDialog = () => {
    setDialog({ type: "Connect", id: undefined });
  };

  const handleOpenAddDialog = () => {
    setDialog({ type: "Upsert", id: undefined });
  };

  const { loading, error, data } = useQuery<
    ICulturalHeritageCategoryData,
    ICulturalHeritageCategoryVars
  >(ALL_CULTURAL_HERITAGE_CATEGOREIS, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
      },
    },
  });

  const [
    culturalHeritageQuery,
    {
      loading: loadingCulturalHeritage,
      error: errorCulturalHeritage,
      data: dataCulturalHeritage,
      called: calledCulturalHeritage,
    },
  ] = useLazyQuery<ICulturalHeritageData, ICulturalHeritageVars>(
    ONE_CULTURAL_HERITAGE
  );

  const [
    culturalHeritageStationQuery,
    {
      loading: loadingCulturalHeritageStation,
      error: errorCulturalHeritageStation,
      data: dataCulturalHeritageStation,
      // called: calledCulturalHeritageStation,
    },
  ] = useLazyQuery<
    ICulturalHeritageStationsData,
    ICulturalHeritageStationsVars
  >(ALL_CULTURAL_HERITAGE_STATION);

  const editorRef = useRef<Editor>(null);
  if (!editorRef.current)
    //@ts-ignore
    editorRef.current = withLinks(withHistory(withReact(createEditor())));
  const editor = editorRef.current;

  const localeSelectProps = {
    selected: localeSelected,
    setSelected: setLocaleSelected,
  };

  const { inputFields, setInputFields, inputProps, formReady, setInputField } =
    useForm<keyof typeof initialInputData>(initialInputData, localeSelected);

  const [createCulturalHeritage, { loading: loadingCreateCulturalHeritage }] =
    useMutation<ICreateCulturalHeritageData, ICreateCulturalHeritageVars>(
      CREATE_CULTURAL_HERITAGE,
      {
        onCompleted: (data) => {
          // console.log("Create data: ", data);
          enqueueSnackbar("Sucessfully created new cultural heritage!", {
            variant: "success",
          });
          history.replace(
            `/cultural-heritage/${data.createCulturalHeritage.id}`
          );
        },
        onError: (err) => {
          // console.error({ err });
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        },
        update: (cache, { data }) => {
          // console.log("Cache", cache);
          const existingListData: ICulturalHeritageListData | null =
            cache.readQuery({
              query: ALL_CULTURAL_HERITAGE_LIST,
              variables: {
                filter: {
                  where: {
                    expired: null,
                  },
                  orderBy: {
                    created: "desc",
                  },
                },
              },
            });
          if (data?.createCulturalHeritage) {
            const getFlagId = data?.createCulturalHeritage.locale.findIndex(
              (x) => x.localeFlag.isoLanguageCode === "en-GB"
            );
            const newListData: ICulturalHeritageList = {
              created: data.createCulturalHeritage.created,
              category: data.createCulturalHeritage.category,
              published: data.createCulturalHeritage.published,
              media: data.createCulturalHeritage.media,
              id: data.createCulturalHeritage.id,
              duration: data.createCulturalHeritage.duration,
              locale: [data?.createCulturalHeritage.locale[getFlagId]],
            };
            cache.writeQuery({
              query: ALL_CULTURAL_HERITAGE_LIST,
              variables: {
                filter: {
                  where: {
                    expired: null,
                  },
                  orderBy: {
                    created: "desc",
                  },
                },
              },
              data: {
                culturalHeritages: existingListData?.culturalHeritages
                  ? [newListData, ...existingListData.culturalHeritages]
                  : [newListData],
              },
            });
          }
        },
      }
    );

  const [updateCulturalHeritage, { loading: loadingUpdateCulturalHeritage }] =
    useMutation<IUpdateCulturalHeritageData, IUpdateCulturalHeritageVars>(
      UPDATE_CULTURAL_HERITAGE,
      {
        onCompleted: (data) => {
          // console.log("Create data: ", data);
          enqueueSnackbar("Sucessfully updated cultural heritage!", {
            variant: "success",
          });
        },
        onError: (err) => {
          // console.error({ err });
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        },
        refetchQueries: [
          {
            query: ALL_CULTURAL_HERITAGE_LIST,
            variables: {
              filter: {
                where: {
                  expired: null,
                },
                orderBy: {
                  created: "desc",
                },
              },
            },
          },
          {
            query: ALL_CULTURAL_HERITAGE_LIST,
            variables: {
              filter: {
                where: {
                  expired: null,
                },
                orderBy: {
                  created: "desc",
                },
              },
            },
          },
        ],
      }
    );

  useEffect(() => {
    if (localeSelected && !calledCulturalHeritage && culturalHeritageId) {
      culturalHeritageQuery({
        variables: {
          id: +culturalHeritageId,
        },
      });
      culturalHeritageStationQuery({
        variables: {
          filter: {
            where: {
              culturalHeritageId: +culturalHeritageId,
              station: {
                expired: null,
              },
            },
          },
        },
      });
    }
  }, [
    localeSelected,
    culturalHeritageId,
    calledCulturalHeritage,
    culturalHeritageQuery,
    culturalHeritageStationQuery,
  ]);

  useEffect(() => {
    if (
      dataCulturalHeritage?.culturalHeritage?.locale &&
      calledCulturalHeritage &&
      formReady
    ) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "duration",
          toFormProperty: "duration",
        },
        {
          fromDataProperty: "locale.title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "locale.audioText",
          toFormProperty: "audioText",
        },
        {
          fromDataProperty: "category.id",
          toFormProperty: "category",
        },
        {
          fromDataProperty: "locale.description",
          toFormProperty: "description",
        },
        {
          fromDataProperty: "media",
          toFormProperty: "headerImage",
        },
        {
          fromDataProperty: "locale.audio",
          toFormProperty: "audioGuide",
        },
        {
          fromDataProperty: "gallery",
          toFormProperty: "gallery",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(dataCulturalHeritage.culturalHeritage, valueToFormOptions, inputFields);

      if (dataCulturalHeritage.culturalHeritage?.published === false) {
        getUpdatedInputs.published.value.unlocalised.value = "false";
      }
      // console.log("Values to update: ", getUpdatedInputs);
      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCulturalHeritage, calledCulturalHeritage, formReady]);

  // const handleUpdateAll = () => {
  //   const fields = Object.keys(initialInputData);
  //   const result = validateForm<keyof typeof initialInputData>(
  //     fields,
  //     inputFields,
  //     "allLocales",
  //     localeSelected
  //   );
  //   if (result.formValid && dataCulturalHeritage) {
  //     localeFlags.forEach((item) => {
  //       const getLocaleId = dataCulturalHeritage.culturalHeritage.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       const getFlagId = dataCulturalHeritage.culturalHeritage.locale.find(
  //         (x) => x.localeFlag.isoLanguageCode === item.isoLanguageCode
  //       )?.id;

  //       if (getLocaleId && getFlagId) {
  //         updateCulturalHeritage({
  //           variables: {
  //             id: +culturalHeritageId,
  //             localeId: +getLocaleId,
  //             data: {
  //               duration: +inputFields.duration.value.unlocalised.value,
  //               published: true,
  //               media: {
  //                 connect: {
  //                   path: inputFields.gallery.value.unlocalised.media![0].path,
  //                 },
  //               },
  //               gallery: inputFields.gallery.value.unlocalised.value.split(","),
  //               category: {
  //                 connect: {
  //                   id: +inputFields.category.value.unlocalised.value,
  //                 },
  //               },
  //             },
  //             localeData: {
  //               audio: inputFields.audioGuide.value[item.isoLanguageCode].media!
  //                 .length
  //                 ? {
  //                     connect: {
  //                       path: inputFields.audioGuide.value[item.isoLanguageCode]
  //                         .media![0].path,
  //                     },
  //                   }
  //                 : {
  //                     disconnect: true,
  //                   },
  //               audioText:
  //                 inputFields.audioText.value[item.isoLanguageCode].value,
  //               title: inputFields.title.value[item.isoLanguageCode].value,
  //               description:
  //                 inputFields.description.value[item.isoLanguageCode].value,
  //               localeFlag: {
  //                 connect: {
  //                   id: +getFlagId,
  //                 },
  //               },
  //             },
  //           },
  //         });
  //       } else {
  //         enqueueSnackbar("Something went wrong, try again later!", {
  //           variant: "error",
  //         });
  //       }
  //     });
  //   } else {
  //     console.log("Form is invalid: ", result);
  //     enqueueSnackbar(
  //       "Not all required fields are set. Check other languages!",
  //       {
  //         variant: "error",
  //       }
  //     );
  //     setInputFields(result.outputData);
  //   }
  // };

  const handleUpdate = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      localeSelected
    );
    if (result.formValid && dataCulturalHeritage) {
      const getLocaleId = dataCulturalHeritage.culturalHeritage.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      const getFlagId = dataCulturalHeritage.culturalHeritage.locale.find(
        (x) => x.localeFlag.isoLanguageCode === localeSelected
      )?.id;

      if (getLocaleId && getFlagId) {
        updateCulturalHeritage({
          variables: {
            id: +culturalHeritageId,
            localeId: +getLocaleId,
            data: {
              duration: +inputFields.duration.value.unlocalised.value,
              published:
                inputFields.published.value.unlocalised.value === "true",
              media: {
                connect: {
                  path: inputFields.headerImage.value.unlocalised.media![0]
                    .path,
                },
              },
              gallery: inputFields.gallery.value.unlocalised.value.split(","),
              category: {
                connect: { id: +inputFields.category.value.unlocalised.value },
              },
            },
            localeData: {
              audio: inputFields.audioGuide.value[localeSelected].media!.length
                ? {
                    connect: {
                      path: inputFields.audioGuide.value[localeSelected]
                        .media![0].path,
                    },
                  }
                : {
                    disconnect: true,
                  },
              audioText: inputFields.audioText.value[localeSelected].value,
              title: inputFields.title.value[localeSelected].value,
              description: inputFields.description.value[localeSelected].value,
              localeFlag: {
                connect: {
                  id: +getFlagId,
                },
              },
            },
          },
        });
      } else {
        enqueueSnackbar("Something went wrong, try again later!", {
          variant: "error",
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  const handleSetCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField(
      "published",
      event.target.value === "true" ? "false" : "true"
    );
  };

  const handleCreateNew = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields,
      "allLocales",
      localeSelected
    );
    if (result.formValid) {
      createCulturalHeritage({
        variables: {
          data: {
            duration: +inputFields.duration.value.unlocalised.value,
            published: inputFields.published.value.unlocalised.value === "true",
            media: {
              connect: {
                path: inputFields.headerImage.value.unlocalised.media![0].path,
              },
            },
            gallery: inputFields.gallery.value.unlocalised.value.split(","),
            category: {
              connect: { id: +inputFields.category.value.unlocalised.value },
            },
          },
          localeData: localeFlags.map((item) => {
            return {
              audio: inputFields.audioGuide.value[item.isoLanguageCode].media!
                .length
                ? {
                    connect: {
                      path: inputFields.audioGuide.value[item.isoLanguageCode]
                        .media![0].path,
                    },
                  }
                : undefined,
              audioText:
                inputFields.audioText.value[item.isoLanguageCode].value,
              title: inputFields.title.value[item.isoLanguageCode].value,
              description:
                inputFields.description.value[item.isoLanguageCode].value,
              localeFlag: { connect: { id: +item.id } },
            };
          }),
        },
      });
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar(
        "Not all required fields are set. Check other languages!",
        {
          variant: "error",
        }
      );
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    // console.log("IF: ", inputFields);
  }, [inputFields]);

  return (
    <Fragment>
      <PageLayout
        title="Cultural Heritage Details"
        localeSelect={localeSelectProps}
        subRoute={[
          culturalHeritageId
            ? dataCulturalHeritage?.culturalHeritage?.locale?.find(
                (x) => x.localeFlag?.isoLanguageCode === localeSelected
              )!.title
              ? dataCulturalHeritage?.culturalHeritage?.locale?.find(
                  (x) => x.localeFlag?.isoLanguageCode === localeSelected
                )!.title
              : "Loading"
            : "New",
        ]}
      >
        {loading || !formReady || loadingCulturalHeritage ? (
          <LoadingComponent />
        ) : error || errorCulturalHeritage ? (
          <ErrorComponent error={error || errorCulturalHeritage} />
        ) : (
          <Fragment>
            {/* <FileUpload /> */}
            <Paper className={styles.paper}>
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Header
              </Typography>
              <MultipleMediaUpload
                validTypes={["image/jpeg", "image/jpg"]}
                vanishWhenMax
                max={1}
                {...inputProps("headerImage")}
              />
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Information
              </Typography>
              <div className={styles.inputDiv}>
                <TextField
                  className={styles.inputField}
                  variant="outlined"
                  {...inputProps("title")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TitleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className={styles.inputField}
                  variant="outlined"
                  {...inputProps("duration")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TimerIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className={styles.inputField}
                  variant="outlined"
                  select
                  {...inputProps("category")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CategoryIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value="">-- Select category --</MenuItem>
                  {data?.culturalHeritageCategories.length ? (
                    data.culturalHeritageCategories.map((item) => {
                      // console.log("CATEGORY", item);
                      if (item.locale?.length) {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.locale[0].name}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem key={item.id} value="" disabled>
                            Something went wrong!
                          </MenuItem>
                        );
                      }
                    })
                  ) : (
                    <MenuItem disabled value="">
                      No categories found!
                    </MenuItem>
                  )}
                </TextField>
              </div>

              <RichTextEditor
                className={styles.rteInputField}
                editor={editor}
                // readOnly={!edit}
                {...inputProps("description", editor)}
              />

              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Gallery
              </Typography>
              <MultipleMediaUpload
                {...inputProps("gallery")}
                validTypes={["image/jpeg", "image/jpg"]}
                styleType="gallery"
                max={8}
              />
              <Typography
                className={styles.subtitle}
                color="primary"
                variant="h6"
              >
                Audio Guide
              </Typography>
              <MultipleMediaUpload
                {...inputProps("audioGuide")}
                validTypes={["audio/flac", "audio/mpeg"]}
                styleType="audio"
                max={1}
                vanishWhenMax
                className={styles.subtitle}
              />
              <TextField
                variant="outlined"
                {...inputProps("audioText")}
                minRows={4}
                multiline
                maxRows={8}
                className={styles.subtitle}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        inputFields.published.value.unlocalised.value === "true"
                      }
                      onChange={handleSetCheckbox}
                      value={
                        inputFields.published.value.unlocalised.value === "true"
                      }
                    />
                  }
                  label={inputFields.published.label}
                />
              </FormGroup>

              {culturalHeritageId ? (
                <Fragment>
                  <Button
                    onClick={handleUpdate}
                    color="primary"
                    variant="contained"
                  >
                    Update {localeSelected}
                  </Button>
                  {/* <Button
                    onClick={handleUpdateAll}
                    color="primary"
                    variant="contained"
                  >
                    Update All
                  </Button> */}
                </Fragment>
              ) : (
                <Button
                  onClick={handleCreateNew}
                  color="primary"
                  variant="contained"
                >
                  Add new
                </Button>
              )}
            </Paper>
            {culturalHeritageId ? (
              <Paper className={styles.paperStation}>
                <Typography
                  className={styles.subtitle}
                  color="primary"
                  variant="h6"
                >
                  Station List
                </Typography>

                {loadingCulturalHeritageStation ? (
                  <LoadingComponent small />
                ) : errorCulturalHeritageStation ? (
                  <ErrorComponent error={errorCulturalHeritageStation} />
                ) : dataCulturalHeritageStation?.culturalHeritageStations
                    ?.length ? (
                  dataCulturalHeritageStation?.culturalHeritageStations.map(
                    (item) => {
                      // console.log("Item ", item);

                      const handleOpenDisconnectDialog = () => {
                        setDialog({ type: "Disconnect", id: item.id });
                      };

                      const handleOpenEditDialog = () => {
                        setDialog({ type: "Upsert", id: item.station.id });
                      };

                      return (
                        <StationButton
                          key={item.id}
                          stationId={item.station.id}
                          title={item.station.locale[0]?.title}
                          onClickConnect={handleOpenConnectDialog}
                          onClickAdd={handleOpenAddDialog}
                          onClickDisconnect={handleOpenDisconnectDialog}
                          onClickEdit={handleOpenEditDialog}
                        />
                      );
                    }
                  )
                ) : null}

                <StationButton
                  onClickConnect={handleOpenConnectDialog}
                  onClickAdd={handleOpenAddDialog}
                />
              </Paper>
            ) : null}
          </Fragment>
        )}
      </PageLayout>
      <Dialog
        onClose={handleCloseDialog}
        maxWidth="xs"
        fullWidth
        open={dialog.type === "Disconnect"}
      >
        <DisconnectStationDialog onClose={handleCloseDialog} id={dialog?.id} />
      </Dialog>
      <Dialog
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        open={dialog.type === "Connect"}
      >
        <ConnectStationDialog
          onClose={handleCloseDialog}
          culturalHeritageId={culturalHeritageId}
        />
      </Dialog>
      <Dialog
        open={dialog.type === "Upsert"}
        maxWidth="md"
        fullWidth
        onClose={handleCloseDialog}
      >
        <UpsertStationDialog
          id={dialog.id}
          culturalHeritageId={culturalHeritageId}
          onClose={handleCloseDialog}
          prevLocaleSelected={localeSelected}
        />
      </Dialog>
      <Dialog
        open={dialog.type === "Delete"}
        maxWidth="md"
        fullWidth
        onClose={handleCloseDialog}
      >
        <DeleteStationDialog id={dialog.id} onClose={handleCloseDialog} />
      </Dialog>
      <LoadingBackdrop
        loading={loadingCreateCulturalHeritage || loadingUpdateCulturalHeritage}
      />
    </Fragment>
  );
};
