import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";
declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#A2694D",
      },
      secondary: {
        main: "#BB8D7C",
      },
      text: {
        primary: "#555",
        //secondary: "#fff",
      },
      background: {
        default: "#f7f7f7",
        paper: "#ffffff",
      },
    },
    typography: {
      fontFamily: ["Atkinson", "Roboto"].join(","),
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 32,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 32,
          },
        },
      },
    },
  })
);
