import { gql } from "@apollo/client";

export interface ITermsOfService {
  id: string;
  type: string;
  locale: {
    id: string;
    content: string;
    title: number;
    localeFlag: {
      isoLanguageCode: string;
    };
  }[];
  created: Date;
}

export interface ITermsOfServiceData {
  termsOfService: ITermsOfService;
}

export interface ITermsOfServiceVars {
  lang?: string;
  type: string;
}

export const ONE_TERMS_OF_SERVICE = gql`
  query termsOfService($type: String!, $lang: String) {
    termsOfService(type: $type) {
      id
      type
      locale(lang: $lang) {
        id
        content
        title
        localeFlag {
          isoLanguageCode
        }
      }
    }
  }
`;
