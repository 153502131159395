import { ILocaleFlag } from "../apollo/queries";
import dcopy from "deep-copy";
import { isSlateEmpty, parseRteValue } from ".";
import { Descendant } from "slate";
import { IUploadedFile } from "../components";

export interface DynamicFormObject {
  value: ILocalisedValue;
  label: string;
  readonly rules?: "email" | "phone" | "slateRte" | "mediaUpload";
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  errorText?: string;
  readonly type?: "number" | "text" | "password" | "email" | "hidden";
  readonly localise?: boolean;
}

export interface DynamicForm {
  [key: string]: DynamicFormObject;
}

export interface ILocalisedValue {
  unlocalised: {
    value: string;
    rteValue?: Descendant[] | undefined;
    media?: IUploadedFile[] | undefined;
  };
  [key: string]: {
    value: string;
    rteValue?: Descendant[] | undefined;
    media?: IUploadedFile[] | undefined;
  };
}

export type TValueToFormOptions = {
  fromDataProperty: string;
  toFormProperty: string;
}[];

export const getFormValuesFromFetchedData = <
  T extends string | number | symbol
>(
  data: { [key: string]: any },
  valueToFormOptions: TValueToFormOptions,
  inputFields: DynamicForm
) => {
  const inputFieldsCopy = dcopy(inputFields);

  // console.log("getFormValuesFromFetchedData");

  valueToFormOptions.forEach((item) => {
    const deepValue = item.fromDataProperty
      .split(".")
      .reduce((obj, property, i) => {
        // console.log(
        //   "Get [",
        //   i,
        //   "] by property: -",
        //   property,
        //   "-. Current Object: ",
        //   obj
        // );

        if (Array.isArray(obj) && obj.length) {
          // console.log("-InArray object: ", obj);

          const arrayObject = obj.map((element: { [key: string]: any }) => {
            // console.log("--InArray value element: ", element);

            if (element[property]) {
              // console.log("--InArray value: ", element[property]);

              const flag = element.localeFlag?.isoLanguageCode;
              if (flag) {
                // console.log("---Found locale flag: ", flag);
                // console.log("---SET FLAG VALUE: ", element[property]);
              }

              inputFieldsCopy[item.toFormProperty].value[
                flag && inputFieldsCopy[item.toFormProperty].localise
                  ? flag
                  : "unlocalised"
              ] = {
                rteValue:
                  inputFieldsCopy[item.toFormProperty].rules === "slateRte"
                    ? parseRteValue(element[property])
                    : undefined,
                value:
                  inputFieldsCopy[item.toFormProperty].rules ===
                    "mediaUpload" &&
                  Array.isArray(element[property]) &&
                  element[property].length
                    ? element[property]
                        .map((item: IUploadedFile) => item.path)
                        .join(",")
                    : element[property],
                media:
                  inputFieldsCopy[item.toFormProperty].rules === "mediaUpload"
                    ? Array.isArray(element[property])
                      ? element[property].map((item: any) => {
                          return {
                            extension: item.extension,
                            type: item.type,
                            name: item.name,
                            size: item.size,
                            id: item.id,
                            path: item.path,
                          };
                        })
                      : [
                          {
                            extension: element[property].extension,
                            type: element[property].type,
                            name: element[property].name,
                            size: element[property].size,
                            id: element[property].id,
                            path: element[property].path,
                          },
                        ]
                    : undefined,
              };
              return element;
              // } else {
              //   console.log("NOT LOCALISED ", element[property]);
              //   inputFieldsCopy[item.toFormProperty].value = item.isRte
              //     ? parseRteValue((element as any)[property])
              //     : (element as any)[property];
              //   return (element as any)[property];
            }
            return element;
          });

          if (arrayObject.every((i) => typeof i === "string")) {
            // console.log("Merge array into one string!", arrayObject.toString());
            inputFieldsCopy[item.toFormProperty].value.unlocalised.value =
              arrayObject.toString();
            return arrayObject.toString();
          } else return arrayObject;
        } else {
          // console.log("-InObj Object: ", obj);
          // console.log("--InObj value: ", obj?.[property], item.toFormProperty);
          inputFieldsCopy[item.toFormProperty].value.unlocalised = {
            rteValue:
              inputFieldsCopy[item.toFormProperty].rules === "slateRte"
                ? obj?.[property]
                  ? parseRteValue(obj[property])
                  : undefined
                : undefined,
            value: obj?.[property]
              ? obj[property] === typeof "number"
                ? obj[property].toString()
                : inputFieldsCopy[item.toFormProperty].rules ===
                    "mediaUpload" &&
                  Array.isArray(obj[property]) &&
                  obj[property].length
                ? obj[property]
                    .map((item: IUploadedFile) => item.path)
                    .join(",")
                : obj[property]
              : "",
            media:
              inputFieldsCopy[item.toFormProperty].rules === "mediaUpload" &&
              obj?.[property]
                ? Array.isArray(obj[property])
                  ? obj[property].map((item: any) => {
                      return {
                        extension: item.extension,
                        type: item.type,
                        name: item.name,
                        size: item.size,
                        id: item.id,
                        path: item.path,
                      };
                    })
                  : [
                      {
                        extension: obj[property].extension,
                        type: obj[property].type,
                        name: obj[property].name,
                        size: obj[property].size,
                        id: obj[property].id,
                        path: obj[property].path,
                      },
                    ]
                : [],
          };
          // console.log("IFC: ", inputFieldsCopy);
          return obj?.[property];
        }

        // return obj as any;
      }, data);
    // console.log("DV: ", deepValue);
  });
  return inputFieldsCopy as Record<T, DynamicFormObject>;
};

export const localizeInputValues = <T extends string | number | symbol>(
  inputFields: DynamicForm,
  flagsData: ILocaleFlag[]
): Record<T, DynamicFormObject> | null => {
  const inputFieldsCopy = dcopy(inputFields);
  // console.log("Localise");
  if (flagsData.length) {
    Object.entries(inputFieldsCopy).forEach(([key, property]) => {
      if (property.localise) {
        const localeInputField = flagsData.reduce(
          (obj: ILocalisedValue, item: ILocaleFlag) => (
            // eslint-disable-next-line no-sequences
            (obj[item.isoLanguageCode] = { ...property.value.unlocalised }), obj
          ),
          { unlocalised: { ...property.value.unlocalised } } as ILocalisedValue
        );
        inputFieldsCopy[key].value = {
          ...localeInputField,
        };
      }
    });
    return inputFieldsCopy as Record<T, DynamicFormObject>;
  } else return null;
};

export type validType = {
  inputData: object;
  isValid: boolean;
};

export function createTypedInputs<
  T extends { [name: string]: DynamicFormObject }
>(input: T): Record<keyof T, DynamicFormObject> {
  return input;
}

export const validateForm = <T extends string | number | symbol>(
  fields: Array<string>,
  values: DynamicForm,
  locale: string | "unlocalised" | "allLocales" = "unlocalised",
  mainLocale: string | "unlocalised" = "unlocalised"
) => {
  const outputData: DynamicForm = {};
  let formValid = true;

  if (fields && fields.length && values && Object.keys(values).length) {
    fields.forEach((field: string) => {
      // console.log("Checking: ", field);
      let fieldInvalid = false;
      let errorText = "";
      let objectLocale = values[field].localise ? locale : "unlocalised";

      const makeInvalid = (message?: string) => {
        // console.log("Required check failed");
        fieldInvalid = true;
        formValid = false;
        errorText = message || "";
      };

      const checkIfInvalid = (message?: string) => {
        // console.log("Required check: ", objectLocale);
        if (values[field].required) {
          if (
            values[field].rules === "slateRte" &&
            isSlateEmpty(values[field].value[objectLocale].rteValue)
          ) {
            makeInvalid(message);
          } else if (
            values[field].rules === "mediaUpload" &&
            !values[field].value[objectLocale].media?.length
          ) {
            makeInvalid(message);
          } else if (
            values[field].rules !== "mediaUpload" &&
            values[field].rules !== "slateRte" &&
            !values[field].value[objectLocale].value
          ) {
            makeInvalid(message);
          }
        }
      };

      if (locale === "allLocales") {
        // console.log("Checlking all locales!");
        Object.keys(values[field].value).forEach((valueLocale) => {
          objectLocale = values[field].localise ? valueLocale : "unlocalised";

          if (valueLocale === "unlocalised" && values[field].localise) {
            return;
          }
          // console.log("Checlking all locales - Localised: ", objectLocale);
          checkIfInvalid(
            `${
              valueLocale !== "unlocalised"
                ? "One or more languages for this field are not set. Please check them all!"
                : "Required field is not set!"
            }`
          );
        });
      } else {
        // console.log("Checlking one locale! ", locale);

        checkIfInvalid("Required field is not set!");
      }

      if (values[field].value.unlocalised.value) {
        if (values[field].rules === "email") {
          const pattern =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
          const checkPattern = pattern.test(values[field].value[locale].value);
          if (!checkPattern) {
            makeInvalid("Invalid email format");
          }
        }
        // Check if field is a valid phone number
        if (values[field].rules === "phone") {
          const pattern = /[5,9][1,2,5,7,8,9].{6,7}/;
          const checkPattern = pattern.test(values[field].value[locale].value);
          if (!checkPattern) {
            makeInvalid("Invalid phone format");
          }
        }
      }
      outputData[field] = {
        ...values[field],
        error: fieldInvalid,
        errorText: errorText ? errorText : values[field].errorText,
      };
      // console.log("Output Data: ", outputData);
    });
    return { formValid, outputData } as {
      formValid: boolean;
      outputData: Record<T, DynamicFormObject>;
    };
  }
  return { formValid: false, outputData } as {
    formValid: boolean;
    outputData: Record<T, DynamicFormObject>;
  };
};
