import {
  ButtonBase,
  Dialog,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";
import { useStyles } from "./MultipleMediaUpload.styles";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Publish as PublishIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import bytes from "bytes";
import { UploadedMediaFile } from "./UploadedMediaFile/UploadedMediaFile";
import { animated, useTransition } from "@react-spring/web";
import { v4 as uuidv4 } from "uuid";
import { MediaPreviewDialog } from "./MediaPreviewDialog/MediaPreviewDialog";
import { Reference, useMutation } from "@apollo/client";
import {
  CREATE_MEDIA,
  DELETE_MEDIA,
  ICreateMediaData,
  ICreateMediaVars,
  IDeleteMediaData,
  IDeleteMediaVars,
} from "../../apollo/mutations";
import { ALL_MEDIAS, IMediasData } from "../../apollo/queries";
import { LoadingComponent, LoadingBackdrop } from "..";
import { DeleteMediaDialog } from "./DeleteMediaDialog/DeleteMediaDialog";

export interface IUploadedFile {
  id?: string;
  blob?: string;
  name: string;
  type: string;
  extension: string;
  size: number;
  path: string;
}
interface IProps {
  validTypes: Array<
    | "image/jpeg"
    | "image/jpg"
    | "image/png"
    | "video/mp4"
    | "application/pdf"
    | "audio/flac"
    | "audio/mpeg"
  >;
  value: IUploadedFile[];
  onChange: (value: any) => void;
  className?: string;
  styleType?: "gallery" | "audio" | undefined;
  max?: number;
  required?: boolean;
  label: string;
  vanishWhenMax?: boolean;
  error?: boolean;
  helperText?: string;
}

interface IDialog {
  item: IUploadedFile | undefined;
  type: "Delete" | "Preview" | undefined;
}

export const MultipleMediaUpload: FC<IProps> = (props) => {
  const {
    validTypes,
    onChange,
    value,
    className,
    styleType,
    max,
    required,
    label,
    vanishWhenMax,
    error,
    helperText,
  } = props;

  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const AnimatedUploadedMediaFile = animated(UploadedMediaFile);

  const [onDragEnterToggle, setOnDragEnterToggle] = useState(false);
  const [onDragToggle, setOnDragToggle] = useState(false);
  const [dialog, setDialog] = useState<IDialog>({
    item: undefined,
    type: undefined,
  });

  const [createMediaMutation, { loading: loadingCreateMedia }] = useMutation<
    ICreateMediaData,
    ICreateMediaVars
  >(CREATE_MEDIA, {
    onCompleted: (data) => {
      enqueueSnackbar(`Uspješno učitavanje: ${data.createMedia.path}`, {
        variant: "success",
      });
      // console.log("MEDIA COMPLETE DATA: ", data);
      const newData = { ...data.createMedia };
      delete newData.__typename;
      onChange([...value, newData]);
    },
    onError: (err) => {
      console.warn("Error: ", { err });
      enqueueSnackbar(`Neuspješno učitavanje: ${err.message}`, {
        variant: "error",
      });
    },
    update: (cache, data) => {
      const existingMedias: IMediasData | null = cache.readQuery({
        query: ALL_MEDIAS,
      });
      const newMedia = data.data?.createMedia;
      cache.writeQuery({
        query: ALL_MEDIAS,
        variables: {
          filter: {
            where: {
              expired: null,
            },
          },
        },
        data: {
          medias: existingMedias?.medias
            ? [newMedia, ...existingMedias.medias]
            : [newMedia],
        },
      });
    },
  });

  const [deleteMediaMutation, { loading }] = useMutation<
    IDeleteMediaData,
    IDeleteMediaVars
  >(DELETE_MEDIA, {
    onCompleted: (data) => {
      // console.log(data);
      enqueueSnackbar(`Medij je uspješno obrisan: ${data.deleteMedia.name}!`, {
        variant: "success",
      });
      const filesNotDeleted = value.filter(
        (file) => data.deleteMedia.id !== file.id
      );
      onChange(filesNotDeleted);
      handleCloseDialog();
    },
    onError: (err) => {
      // console.error(err);
      enqueueSnackbar("Greška kod brisanja medija!", {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          openingHours(existingData: Array<Reference>, { readField }) {
            if (data) {
              return existingData.filter(
                (taskRef) => data.deleteMedia.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const handleOpenPreviewDialog = (item: IUploadedFile | undefined) => {
    setDialog({ item, type: "Preview" });
  };
  const handleOpenDeleteDialog = (item: IUploadedFile | undefined) => {
    // console.log(item);
    setDialog({ item, type: "Delete" });
  };

  const handleCloseDialog = () => {
    setDialog({ item: undefined, type: undefined });
  };

  const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const validateFiles = (file: File) => {
    if ((validTypes as string[]).indexOf(file.type) === -1) {
      enqueueSnackbar(
        `Wrong media format: ${
          file.type || "Unknown format"
        }. Required format: ${validTypes.join(", ")}`,
        {
          variant: "error",
        }
      );
      return false;
    } else if (
      file.type !== "audio/flac" &&
      file.type !== "audio/mpeg" &&
      file.size > 512000 * 10
    ) {
      enqueueSnackbar(
        `Media too big: ${bytes(file.size)}! File must be at most ${bytes(
          512000 * 10
        )}`,
        {
          variant: "error",
        }
      );
      return false;
    } else if (max && value.length >= max) {
      enqueueSnackbar(`The max amount of media for this field is ${max}`, {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleUpload = async (file: File) => {
    const blob = await toBase64(file).catch((e) => Error(e));
    if (blob instanceof Error) {
      enqueueSnackbar("Error while processing media!", {
        variant: "error",
      });
      return;
    }
    const extension = file.type.split("/")[1];
    createMediaMutation({
      variables: {
        data: {
          name: file.name,
          blob: blob as string,
          type: file.type,
          extension: extension,
          size: file.size,
          path: `${uuidv4()}.${extension}`,
        },
      },
    });
  };

  const handleManualInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.length) {
      const files = event.target.files;
      if (max && value.length + files.length > max) {
        enqueueSnackbar(`Max number of media allowed is ${max}`, {
          variant: "error",
        });
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (validateFiles(files[i])) {
          handleUpload(files[i]);
        } else {
        }
      }
    }
    event.target.value = "";
  };

  const handleFileDrop = (files: FileList) => {
    if (max && value.length + files.length > max) {
      enqueueSnackbar(`Max number of media allowed is ${max}`, {
        variant: "error",
      });
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (validateFiles(files[i])) {
        handleUpload(files[i]);
      } else {
      }
    }
  };

  const handleOnDragEnter = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(false);
  };

  const handleOnDrag = (event: React.DragEvent<HTMLButtonElement>) => {
    setOnDragToggle(true);
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
    setOnDragToggle(false);
  };

  const handleOnDragDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFileDrop(files);
    }
    setOnDragEnterToggle(false);
  };

  const handleOnClick = () => {
    if (inputRef) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  const transitions = useTransition(value, {
    from: {
      opacity: 0,
      width: styleType === "gallery" ? 0 : "0%",
      height: 0,
      marginBottom: 0,
      marginRight: 0,
    },
    enter: {
      opacity: 1,
      width: styleType === "gallery" ? 164 : "100%",
      height: styleType === "gallery" ? 164 : styleType === "audio" ? 128 : 328,
      marginBottom: 8,
      marginRight: 8,
    },
    leave: {
      opacity: 0,
      width: styleType === "gallery" ? 0 : "0%",
      height: 0,
      marginBottom: 0,
      marginRight: 0,
    },
    keys: (item) => item.path,
    expires: true,
  });

  useEffect(() => {
    // console.log("MMUV: ", value);
  }, [value]);

  return (
    <Fragment>
      <div className={styles.container}>
        {transitions((style, item) => (
          <AnimatedUploadedMediaFile
            data={item}
            style={style}
            onRemove={handleOpenDeleteDialog}
            onPreview={handleOpenPreviewDialog}
            download={false}
          />
        ))}
        {vanishWhenMax && max && value.length >= max ? null : (
          <ButtonBase
            className={`${
              styleType === "gallery"
                ? styles.galleryButton
                : styles.uploadButton
            } ${
              onDragEnterToggle
                ? styles.uploadButtonActive
                : onDragToggle
                ? styles.uploadButtonStartActive
                : ""
            } ${className ? className : ""} ${error ? styles.error : ""}`}
            color={onDragEnterToggle ? "secondary" : "inherit"}
            onDragOver={handleOnDragOver}
            onDragStart={handleOnDrag}
            onDragEnter={handleOnDragEnter}
            onDragLeave={handleOnDragLeave}
            onDrop={handleOnDragDrop}
            focusRipple
            onClick={handleOnClick}
            disabled={Boolean(max && value.length >= max)}
          >
            <InputLabel
              variant="outlined"
              shrink
              required={required}
              className={styles.inputLabel}
              error={error}
            >
              {label}
            </InputLabel>
            {loadingCreateMedia ? (
              <LoadingComponent small />
            ) : (
              <Fragment>
                <Typography color="textSecondary">
                  {max && value.length >= max
                    ? "Max files reached"
                    : "Click or drag files here"}
                </Typography>
                <PublishIcon className={styles.uploadAreaIcon} />
              </Fragment>
            )}
            <input
              ref={inputRef}
              multiple={Boolean(max && max !== 1)}
              accept={validTypes.join(",")}
              type="file"
              className={styles.hidden}
              tabIndex={-1}
              onChange={handleManualInput}
            />
          </ButtonBase>
        )}
        <FormHelperText variant="outlined" required={required} error={error}>
          {helperText}
        </FormHelperText>
      </div>
      <Dialog open={dialog.type === "Preview"} onClose={handleCloseDialog}>
        <MediaPreviewDialog item={dialog.item} onClose={handleCloseDialog} />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={dialog.type === "Delete"}
        onClose={handleCloseDialog}
      >
        <DeleteMediaDialog
          id={dialog.item?.path}
          onClose={handleCloseDialog}
          onDelete={deleteMediaMutation}
        />
      </Dialog>
      <LoadingBackdrop loading={loading || loadingCreateMedia} />
    </Fragment>
  );
};
